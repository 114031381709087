<template>
  <v-row class="fill-height" id="agd-calendar">
    <v-col>
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn
            class="mr-4 py-5 px-5" 
            outlined 
            :color="brandColor" 
            :disabled="jobsCalendarLoader || listLoading"
            @click="setToday"
            style="border: 1px solid #e6e6e6" 
          >
            <span class="text-capitalize">Today</span>
          </v-btn>
          <v-btn
            @click="prev"
            class="py-2 px-2"
            color="grey darken-2" 
            fab text small 
            :disabled="jobsCalendarLoader || listLoading"
            style="border: 1px solid #e6e6e6"
            >
            <v-icon small>mdi-chevron-left </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar" 
            class="mx-3" 
            style="font-size: 1em;font-weight: 500;">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-btn
            @click="next"
            class="py-2 px-2"
            fab text small 
            :disabled="jobsCalendarLoader || listLoading"
            style="border: 1px solid #e6e6e6"
            color="grey darken-2" 
          >
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn 
            @click="openUnscheduledJobsView"
            outlined 
            :color="brandColor"
            :disabled="jobsCalendarLoader || listLoading"
          >
            <span class="text-none">Unscheduled jobs</span>
          </v-btn>
        </v-toolbar>
      </v-sheet>
      <v-sheet class="calendar-wrapper" :class="{'overflow-clip': (jobsCalendarLoader || listLoading)}">
        <v-calendar
          ref="calendar"
          v-model="focusMonth"
          :events="calendarViewJobEvents"
          :event-color="getEventColorLight"
          :event-overlap-mode="'stack'"
          :event-more="false"
          :type="type"
          :event-height="54"
          :show-month-on-first="false"
          @click:event="(jobEvent, $pointerEvent) => showJobInfo($pointerEvent, jobEvent)"
          @click:more="viewDay"
          @click:date="addEvent"
          @change="updateRange"
        >
          <template v-slot:day="{ date }">
            <CalendarDay
              :id="date"
              :is-drop-zone-active="isDropZoneActive"
              @update-job="(jobId) => updateJobScheduleDate(date, jobId)"
              @add-job="openAddJobFlyout(date)"
            />
          </template>
          <template v-slot:event="{ event }">
            <CalendarJob
              v-bind="event"
              :number="event.moreInformation.number"
              :customer="event.moreInformation.customer.businessName"
              :contractor="event.moreInformation.contractor.businessName"
              :loading="(updatingScheduleForJobId || '') === event.id"
              @show-job-menu="(nativeEvent) => showJobMenu({ nativeEvent, event })"
              @dragenter.prevent="activateDropZone"
              @dragend="deactivateDropZone"
            />
          </template>
        </v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-y
          nudge-bottom="20"
          nudge-left="180"
        >
          <v-card class="px-0 py-0" color="white" width="200" flat>
            <div class="event-menu-item" 
                 @click="navigateToJobDetail">
              <v-icon size="18" color="black">mdi-briefcase</v-icon>
              {{ $stringConstants("menuViewJob") }}
            </div>
            <div class="event-menu-item" 
                @click="openEditJob(selectedEvent)">
              <v-icon size="18" color="black">mdi-pencil</v-icon>
              {{ $stringConstants("menuEditJob") }}
            </div>
            <div class="event-menu-item" @click="duplicateTeamJob()">
              <v-icon size="18" color="black">mdi-content-copy</v-icon>
              {{ $stringConstants("Duplicate") }}
            </div>
            <div
              v-if="selectedEvent.status === TeamJobStatusType.Completed"
              class="event-menu-item danger--text"
              @click="handleJobDelete"
            >
              <v-icon size="18" color="danger">mdi-delete</v-icon>
              {{ $stringConstants("deleteJob") }}
            </div>
            <div
              v-else-if="![TeamJobStatusType.AwaitingCompletion, TeamJobStatusType.InProgress, TeamJobStatusType.PartCompleted].includes(selectedEvent.status)"
              class="event-menu-item danger--text"
              @click="handleJobCancel"
            >
              <v-icon size="18" color="danger">mdi-delete</v-icon>
              {{ $stringConstants("menuCancelJob") }}
            </div>
          </v-card>
        </v-menu>
        <div v-if="(jobsCalendarLoader || listLoading)" class="calendar-loader-overlay">
          <div class="grid-container">
            <div v-for="j in 35" :key="j+'two'" class="grid-item">
              <div v-for="k in 2" :key="k+'three'" class="d-flex py-3 pl-3 pr-4 justify-space-between calendar-job mb-2 calendar-loader-card-bg">
                <div class="">
                  <v-skeleton-loader type="text, text" width="100" height="14" />
                  <v-skeleton-loader type="text, text" width="50" height="14" class="mt-1" />
                </div>
                <v-skeleton-loader type="avatar" width="28" height="28" />
              </div>
            </div>
          </div>
        </div>
      </v-sheet>
    </v-col>
    <CalendarInfoWindow v-if="showCalendarInfoWindow"
      ref="calendarInfoWindow"
      :height="calendarInfoWindowDimensions.height"
      :width="calendarInfoWindowDimensions.width"
      :selected-event="selectedEvent"
      :job-info-window-position="jobInfoWindowPosition"
      @show-job-details="showJobDetails(selectedEvent)"
      @close-job-info-window="showJobInfo"
    />
    <UnscheduledJobs v-model="showUnscheduledJobs" @submit="cleanupLoadersAndGetTeamJobsList" />
    <AddJobFlyout v-model="showAddJobForm" @submit="cleanupLoadersAndGetTeamJobsList" />
    <ConfirmationDialogBox
      v-model="askDeleteConfirmation"
      :reason-required="isReasonRequiredForCancellation"
      :loading="isJobCancellationInProgress"
      :deletable="isDeletable"
      @cancel="askDeleteConfirmation = false"
      @confirm="deleteJob"
    />
  </v-row>
</template>
<script>
import moment from 'moment';
import { TeamJobStatusType } from '@/enum/teamJobStatusType';
import CalendarJob from '@/components/calendar/calendar-job.vue';
import TeamJobsCommon from '@/components/team-jobs/team-jobs-details/team-jobs-details-common';
import CalendarJobsComputed from '@/components/calendar/calendar-jobs-computed';
import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import { brandColor } from '@/utils/uiconstants';
import { CALENDAR_INFO_WINDOW_HEIGHT, CALENDAR_INFO_WINDOW_WIDTH } from '@/utils/uiconstants';
import { routeTeamJobsDetails, routeTeamJobDuplicate } from '@/utils/endpoints';
import ConfirmationDialogBox from './confirmation-dialog-box.vue';
import AddJobFlyout from './add-job-flyout.vue';
import UnscheduledJobs from './unscheduled-jobs-flyout.vue';
import CalendarInfoWindow from './calendar-info-window.vue';
import { getUTCDateFormat, notify } from '@/utils/helpers';
import { jobUpdateSuccess } from '@/utils/strings';
import { getLoggedInUserRole } from '@/utils/helpers/user-role-helpers';
import CalendarDay from './calendar-day.vue';
import StringConstants from "@/plugins/stringConstants";
import router from "@/router/index";

export default {
  mixins: [CalendarJobsComputed, TeamJobsCommon, TeamJobsComputed],
  components: {
    CalendarJob,
    UnscheduledJobs,
    CalendarInfoWindow,
    AddJobFlyout,
    CalendarDay,
    ConfirmationDialogBox,
  },
  data: () => ({
    brandColor,
    start: null,
    end: null,
    type: 'month',
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    TeamJobStatusType,
    showCalendarInfoWindow: false,
    jobInfoWindowPosition: { top: 0, left: 0 },
    showAddJobWindow: false,
    showUnscheduledJobs: false,
    showAddJobForm: false,
    askDeleteConfirmation: false,
    updatingScheduleForJobId: null,
    isJobCancellationInProgress: false,
    isDropZoneActive: false,
    shouldScrollToCurrentDate: true,
    isDeletable: false,
  }),
  computed: {
    calendarInfoWindowDimensions() {
      return {
        width: CALENDAR_INFO_WINDOW_WIDTH,
        height: CALENDAR_INFO_WINDOW_HEIGHT
      }
    },
    isReasonRequiredForCancellation() {
      if (!this.selectedEvent.id) return false;

      const { moreInformation: job } = this.selectedEvent;
      const isJobInternal = job.isInternal;
      const isSelfJob = job.customer.id === job.contractor.id;
      
      if (isJobInternal || isSelfJob) return false;

      const jobCreatedByCustomerAssignedToContractor = job.customer.id === job.creatorId;
      const jobCreatedByContractorAssignedToCustomer = job.contractor.id === job.creatorId;

      if (
        (getLoggedInUserRole().isUserOwnerFarmerLoggedIn && jobCreatedByContractorAssignedToCustomer) ||
        (getLoggedInUserRole().isUserOwnerContractorLoggedIn && jobCreatedByCustomerAssignedToContractor)
      ) return true;
      return false;
    }
  },
  watch: {
    calendarViewJobEvents(value) {
      if (value.length) {
        this.scrollToCurrentDate();
      }
    },
    selectedOpen(value) {
      if (!value && !this.askDeleteConfirmation) {
        this.selectedEvent = {}
      }
    }
  },
  methods: {
    viewDay({ date }) {
      this.focusMonth = date
      this.type = 'day'
    },
    getEventColorLight(event) {
      return event.color.light;
    },
    setToday() {
      this.shouldScrollToCurrentDate = true;
      this.focusMonth = '';
    },
    prev() {
      this.shouldScrollToCurrentDate = true;
      this.$refs.calendar.prev();
    },
    next() {
      this.shouldScrollToCurrentDate = true;
      this.$refs.calendar.next()
    },
    
    updateRange({ start, end }) {
      this.calendarViewJobEvents = [];
      this.updateEvents(start, end);
    },

    async updateEvents(start, end) {
      if (start && end) {
        const startDate = getUTCDateFormat(new Date(start.date).toISOString());
        const endDate = getUTCDateFormat(moment(end.date).endOf('day'));
        this.calendarStartDate = startDate;
        this.calendarEndDate = endDate;
      }

      await this.$store.dispatch('getTeamJobsList');
    },
    addEvent(date, event) {
      this.showAddJobWindow = true;
    },
    getShortenedTitle(title, event) {
      if (title.length > 20) {
        return title.substring(0, 26) + '...';
      }

      return title;
    },
    openUnscheduledJobsView() {
      this.showUnscheduledJobs = true;
    },
    showJobMenu({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        )
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation();
    },
    showJobInfo(pointerEvent, jobEvent) {
      if (!jobEvent || (jobEvent.event.id === this.selectedEvent.event?.id)) {
        this.selectedEvent = {};
        this.showCalendarInfoWindow = false;
        this.jobInfoWindowPosition = { top: 0 };
      } else {
        this.selectedEvent = jobEvent;
        this.showCalendarInfoWindow = true;
        
        const jobElement = pointerEvent.currentTarget;
        const { top, left, bottom, right } = jobElement.getBoundingClientRect();
        const distanceFromRightEdge = window.innerWidth - left;
        
        if (bottom + CALENDAR_INFO_WINDOW_HEIGHT > window.innerHeight) {
          this.jobInfoWindowPosition.top = top - CALENDAR_INFO_WINDOW_HEIGHT;
        } else {
          this.jobInfoWindowPosition = { top };
        }

        if (distanceFromRightEdge < CALENDAR_INFO_WINDOW_WIDTH) {
          this.jobInfoWindowPosition.left = right - CALENDAR_INFO_WINDOW_WIDTH;
        } else {
          this.jobInfoWindowPosition.left = left;
        }
      }
    },
    showJobDetails(selectedElement) {
      const jobDetailsURL = `${routeTeamJobsDetails}${selectedElement.event.id}`;
      window.open(jobDetailsURL, '_blank');
    },
    async openAddJobFlyout(date) {
      this.selectedOpen = false;
      this.showAddJobForm = true;
      this.$store.commit('toggleIsJobBeingAdded');
      if (date) {
        this.teamJobScheduledDate = moment.utc(date).format();
      } else if (this.selectedEvent.id) {
        const { moreInformation: job } = this.selectedEvent;

        this.teamJobDetails = job;
        this.teamJobsLoader = true;
        await this.getTeamJobUserEditable(job);
        await this.getTeamJobMinimalDetails(job);
        this.teamJobsLoader = false;
      }
    },
    async openEditJob(selectedEvent) {
      if (!selectedEvent.id) return;

      if (selectedEvent.status === TeamJobStatusType.Completed) {
        const jobDetailsURL = `${routeTeamJobsDetails}${selectedEvent.id}`;
        window.open(jobDetailsURL, '_blank');      
      } else {
        this.selectedOpen = false;
        this.showAddJobForm = true;
        const { moreInformation: job } = selectedEvent;

        this.teamJobDetails = job;
        this.teamJobsLoader = true;
        await this.getTeamJobUserEditable(job);
        await this.getTeamJobMinimalDetails(job);
        this.teamJobsLoader = false;
      }
    },
    cleanupLoadersAndGetTeamJobsList() {
      this.showAddJobForm = false;
      this.showUnscheduledJobs = false;
      this.teamJobsLoader = false;
      this.isJobCancellationInProgress = false;
      this.askDeleteConfirmation = false;
      this.updateEvents();
    },
    navigateToJobDetail() {
      if (!this.selectedEvent.id) return;
      const jobDetailsURL = `${routeTeamJobsDetails}${this.selectedEvent.id}`;
      window.open(jobDetailsURL, '_blank');
    },
    handleJobDelete() {
      this.isDeletable = true;
      this.askDeleteConfirmation = true;
    },
    handleJobCancel() {
      this.isDeletable = false;
      this.askDeleteConfirmation = true;
    },
    async deleteJob(reason) {
      const jobToCancel = {
        resourceId: this.selectedEvent.id,
        reason: { reason }
      }
      this.isJobCancellationInProgress = true;
      let response

      if (this.isDeletable)
        response = await this.$store.dispatch('deleteTeamJob', jobToCancel.resourceId);
      else
        response = await this.$store.dispatch('cancelTeamJobByContractor', jobToCancel);

      if (response) this.cleanupLoadersAndGetTeamJobsList();
    },
    async updateJobScheduleDate(date, jobId) {
      this.deactivateDropZone();
      this.showCalendarInfoWindow = false;

      this.shouldScrollToCurrentDate = false;

      const targetJob = this.calendarViewJobs.find(job => job.id === jobId);
      const existingScheduledDate = moment.utc(targetJob.scheduledAt);
      const droppedAtDate = moment.utc(date);

      this.updatingScheduleForJobId = targetJob.id;

      const droppedAtStartOfDay = droppedAtDate.startOf('day');
      const existingScheduledDateStartOfDay = existingScheduledDate.startOf('day');
      const existingScheduledDateTimeElapsed = existingScheduledDate.diff(existingScheduledDateStartOfDay);
      const newScheduledDate = droppedAtStartOfDay.clone().add(existingScheduledDateTimeElapsed, 'milliseconds');

      const updatedJob = {
        ...targetJob,
        scheduledAt: newScheduledDate.toISOString()
      };

      const response = await this.updateTeamJob(updatedJob);
      this.updatingScheduleForJobId = null;

      if (response) {
        notify(jobUpdateSuccess, 'success');
        const updatedJobEvent = await this.$store.dispatch('createJobEvent', response);
        const index = this.calendarViewJobEvents.findIndex((event) => event.id === updatedJobEvent.id);
        const updatedCalendarJobs = this.calendarViewJobEvents;
        updatedCalendarJobs[index] = updatedJobEvent;
        this.calendarViewJobEvents = [];
        this.$nextTick(() => {
          this.calendarViewJobEvents = updatedCalendarJobs;
        });
      }
    },
    activateDropZone() {
      this.isDropZoneActive = true;
    },
    deactivateDropZone() {
      this.isDropZoneActive = false;
    },
    scrollToCurrentDate() {
      const targetElement = document.querySelector('.v-calendar-weekly__day.v-present');
      const calendarContainer = this.$refs.calendar.$el.parentElement;

      if (!targetElement || !this.shouldScrollToCurrentDate) return;
      this.$nextTick(() => {
        const distanceFromTop = targetElement.offsetTop;
        calendarContainer.scrollBy({ top: distanceFromTop, behavior: 'smooth' });
      });
    },
    duplicateTeamJob() {
      notify(StringConstants.jobDuplicationInProgress, 'info');
      router.push(routeTeamJobDuplicate+this.selectedEvent.id);
    }
  },
}
</script>

<style lang="scss">
@import "./../../styles//pages/jobs-calendar";
</style>
