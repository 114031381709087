import { ChangeStatusType } from '@/enum/changeStatus';
import { TabFilterType } from '@/enum/tabFilterType';
import { UserRoleType } from '@/enum/userRoleType';
import ConstantValues from '@/plugins/constantValues';
import StringConstants from '@/plugins/stringConstants';
import fieldsCacheService from '@/services/fields-cache-service';
import { apiAddresses, apiFarmers, apiFields, apiUsers } from '@/utils/endpoints';
import { deleteObjectFromList, getConvertedAreaUnit, getPolygonCoords, getUTCDateFormat, notify } from '@/utils/helpers';
import {
    boundMapOnCordinates,
    focusMapOnPolygon,
    formatFieldObjectForLabel
} from '@/utils/helpers/gmaps-helpers';
import { FeatureLayerHelper } from '@/utils/helpers/index';
import requests from '@/utils/requests';
import buildUrl from 'build-url';
import $ from 'jquery';
import loadGoogleMapsApi from 'load-google-maps-api';
import { state } from './mutations';

const FieldsCacheService = new fieldsCacheService();

export const actions = {
    async getFieldsListFiltered({ state, commit, rootState, dispatch }) {
        if(state.fieldListOwnerId == null) return;
        state.fieldsTotalCount = 0;
        rootState.listLoading = true;
        dispatch("jsonToQueryString", rootState.filterOptions);
        let path = null;

        if (state.fieldListOwnerId == "All") {
            path = apiFields
        }
        else {
            path = state.fieldListOwnerId + apiFields
        }
        const url = buildUrl(rootState.baseUrl, {
            path: path + (rootState.tempUrl ? rootState.tempUrl : '?') + ConstantValues.preferenceQuery + state.fieldStatus
        });
        const result = await requests.getData(url, true, true);

        if (result != null) {
            rootState.totalRowCount = result.data.size;
            rootState.offset = result.data.value.length;
            rootState.listCount = result.data.value.length;
            state.fieldsTotalCount = rootState.totalRowCount;
            if (result.data.value.length > 0) {
                commit('setFieldFilteredList', result.data.value);
            }
            if (rootState.offset == 0) {
                commit('setNoDataStatus', result.data.value);
            }
            rootState.listLoading = false;
        }
    },

    async setFieldFilter({ state, commit }, data) {
        if (state.fieldSearchText?.length > 0) {
            commit('setFilterOptions', [ConstantValues.searchTextQuery, state.fieldSearchText]);
        }
        commit('setFilterOptions', [ConstantValues.offsetQuery, data[0]]);
        commit('setFilterOptions', [ConstantValues.limitQuery, data[1]]);
    },

    async createFieldInfos({ state }, field) {
        return {
            fieldId: field?.id,
            fieldColor: field?.colour,
            fieldName: field?.title,
            fieldArea: field?.areaInSquareMeter,
            fieldCrop: field?.crop || {name: '', year: ''},
            fieldCustomer: (field?.owner?.businessProfile ? field?.owner?.businessProfile?.title : ((field?.owner?.firstName ? field?.owner?.firstName : '') + ' ' + (field?.owner?.lastName ? field?.owner?.lastName : ''))),
            fieldEditable: field?.owner?.isInternal || field?.owner?.id === localStorage.getItem(ConstantValues.ownerId),
            fieldAddress: field?.address ? field?.address?.addressLine1 + ", " + field?.address?.addressLine2 : ''
        }
    },

    async addNewFieldPolygonToMapAndList ({ state, dispatch, commit, rootState }, newField) {
        if (state.fieldStatus == 1) {
            state.fieldStatus = 0;
        }

        if (state.fieldListOwnerId === newField.owner.id || (state.fieldListOwnerId === 'All')) {
            commit('appendFieldToStartOfFieldFilteredList', newField);
            state.fieldsTotalCount = state.fieldsTotalCount + 1;
            rootState.totalRowCount = rootState.totalRowCount + 1;
            rootState.noData = false;
        }
        state.fieldsListMapData.push(newField);
        state.formattedFields.push(formatFieldObjectForLabel(newField))

        FeatureLayerHelper.removeAllClickListenersFromDataLayerFeatures()
        const fieldInfo = await dispatch('createFieldInfos', newField)
        FeatureLayerHelper.addGeoJsonFeatureOnMap(newField.polygon, state.fieldsListMap.data, fieldInfo)

        dispatch('attachClickListenersToFields')
        const infoWindow = new google.maps.InfoWindow()
        FeatureLayerHelper.attachInfoWindowToDataLayerFeatures(state.fieldsListMap.data, state.fieldsListMap, infoWindow)
    },

    async addNewField({ state, rootState, commit, dispatch }, data) {
        state.addFieldLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: data[1] + apiFields
        });
        const result = await requests.postData(url, data[0]);
        if (result[0] != null) {
            if(result[0]?.id) {
                state.polygon?.setEditable(false);
                state.polygon?.setMap(null);
                state.tempFieldPolygon?.setEditable(false);
                state.tempFieldPolygon?.setMap(null);
                state.fieldsListMapOffset = state.fieldsListMapOffset + 1;
                state.fieldsListMapLoaded = state.fieldsListMapLoaded + 1;
                state.fieldsListMapTotal = state.fieldsListMapTotal + 1;
                state.totalFieldsCount = state.totalFieldsCount + 1;

                commit('setClearFilterOptions');
                commit('clearSnackbarDetails');
                await dispatch('addNewFieldPolygonToMapAndList', (result[0]))
                dispatch('checkAndHandleUnsyncedFields');
            }
        }
        else {
            if (result[1].response.data.title != null) {
                notify(result[1].response.data.title, 'fail');
            }
            else {
                notify(StringConstants.fieldNotCreated, 'fail');
            }
        }
        state.addFieldLoader = false;
    },

    async getFieldDetailsById({ state, rootState, commit, dispatch }, ids) {
        const ownerId = ids[1]
        const fieldId = ids[0]
        if (ownerId && fieldId) {
            state.fieldLoader = true;
            const url = buildUrl(rootState.baseUrl, {
                path: ownerId + apiFields + "/" + fieldId
            });
            const result = await requests.getData(url, true, true);
            if (result != null && 'data' in result) {
                if (result.data != null) {
                    state.fieldLoader = false;
                    dispatch('getUserFarmList', result.data.owner.id);
                    commit('setCurrentOwnerToOwnerList', result.data.owner);
                    commit('setFieldDetails', result.data);

                    if (Number(state.fieldDetails.areaInSquareMeter) == 0 || Number.isNaN(Number(state.fieldDetails.areaInSquareMeter))) {
                        setTimeout(() => {
                            dispatch('calculateAndSetPolygonArea', state.polygon)
                        }, ConstantValues.defaultMapTimeoutBuffer*3)
                    }

                    return result.data;
                }
            }
        }
    },

    async getYearList({ commit }) {
        const start = 1990;
        const end = new Date().getFullYear() + 1;
        for (let i = end; i >= start; i--) {
            const year = {
                year: i
            }
            commit('setYearList', year);
        }
    },

    async updateFieldDetails({ state, rootState, commit, dispatch }, data) {

        const addUpdatedFieldPolygonToMapAndList = async (updatedField) => {
            if (state.fieldStatus == 1) {
                state.fieldStatus = 0;
            }

            const fieldIndex = state.fieldsListMapData.findIndex(field => field.id === updatedField.id);
            state.fieldsListMapData[fieldIndex] = updatedField;

            const formattedFieldIndex = state.formattedFields.findIndex(field => field.fieldId === updatedField.id);
            state.formattedFields[formattedFieldIndex] = formatFieldObjectForLabel(updatedField);
            FeatureLayerHelper.removeAllClickListenersFromDataLayerFeatures()
            const fieldInfo = await dispatch('createFieldInfos', updatedField)
            FeatureLayerHelper.addGeoJsonFeatureOnMap(updatedField.polygon, state.fieldsListMap.data, fieldInfo)
            
            const infoWindow = new google.maps.InfoWindow()
            FeatureLayerHelper.attachInfoWindowToDataLayerFeatures(state.fieldsListMap.data, state.fieldsListMap, infoWindow)
            dispatch('attachClickListenersToFields')
        }

        if (typeof data[0].polygon === 'string')
            data[0].polygon = JSON.parse(data[0].polygon)

        if (typeof data[0].polygon === 'object' && !Array.isArray(data[0].polygon))
            data[0].polygon = FeatureLayerHelper.getCoordinatesFromFeatureProperty(data[0].polygon)

        state.addFieldLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: state.oldOwnerId + apiFields + "/" + data[1].id
        });

        const result = await requests.putData(url, data[0], true);

        if (result[0] != null) {
            state.polygon?.setEditable(false);
            state.tempFieldPolygon?.setEditable(false);
            state.polygon?.setMap(null);
            state.tempFieldPolygon?.setMap(null);
            state.editingAreaOnMap = null;
            state.addFieldLoader = false;
            commit('updateFieldInFieldFilteredList', result[0]);
            await addUpdatedFieldPolygonToMapAndList(result[0]);
            dispatch('checkAndHandleUnsyncedFields');
            return result[0];
        }
        else {
            if (result[1].response.data.title != null) {
                notify(result[1].response.data.title, 'fail');
            }
            else {
                notify(StringConstants.fieldNotUpdated, 'fail');
            }
        }
        state.addFieldLoader = false;
    },

    async deleteField({ rootState, state, dispatch }, {fieldId, fieldOwnerId}) {
        const url = buildUrl(rootState.baseUrl, {
            path: fieldOwnerId + apiFields + "/" + fieldId + "?Preference=" + state.fieldStatus
        });
        const result = await requests.deleteData(url, true);
        if (result != null && 'data' in result) {
            notify(StringConstants.fieldDeleted, 'success')
            state.tempFieldPolygon?.setMap(null)
            state.polygon?.setMap(null)
            dispatch('removeFieldsAndUpdateMapBounds', [fieldId])
            dispatch('removeFieldFromList', fieldId)
        } else {
            if (result[1].response.data.title != null) {
                notify(result[1].response.data.title, 'fail');
            }
            else {
                notify(StringConstants.fieldNotDeleted, 'fail');
            }
        }
    },

    async setSearchFarmersFilter({ state, dispatch }, {offset, limit, moduleNamespace}) {
        await dispatch(`${moduleNamespace}/setSearchFilters`, {
            offset,
            limit, 
            searchText: state.fieldFarmerSearchText, 
            keepSearchText: state.fieldFarmerSearchText != 'All' && state.fieldFarmerSearchText != null
        });
    },

    async setSearchInternalFarmersFilter({ state, dispatch },  {offset, limit, moduleNamespace}) {
        await dispatch(`${moduleNamespace}/setSearchFilters`, {
            offset,
            limit, 
            searchText: state.fieldFarmerSearchTextInternal || '', 
            keepSearchText: (state.fieldDetails.owner === null || state.fieldDetails.owner === '')
        });
    },

    async getOwnerList({ state, commit, rootState }, { queryString }) {
        if (rootState.loginUserRole != UserRoleType.Farmer) {

            const url = buildUrl(rootState.baseUrl, {
                path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + apiFarmers + (queryString || '')
            });

            state.fieldsCustomersFilterDropdownLoader = true;
            const result = await requests.getData(url, true);
            state.fieldsCustomersFilterDropdownLoader = false;
            
            if (result != null && 'data' in result) {
                if (result.data != null) {
                    if (result.data.value.length <= 0) {
                        rootState.searchNotify = StringConstants.noSearchResultFoundText;
                    }
                    commit('setOwnerList', result.data.value);
                    commit('setOwnerListSize', result.data.size);
                }
                
                return result;
            }
        }
    },

    async getFieldsOwnerListInternal({ state, commit, rootState }, {queryString}) {
        if (rootState.loginUserRole != UserRoleType.Farmer) {
            const url = buildUrl(rootState.baseUrl, {
                path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + apiFarmers + (queryString || '') + (queryString ? '&' : '?') + 'IsInternal=true'
            });
            
            state.fieldsCustomersFilterDropdownLoader = true;
            const result = await requests.getData(url);
            state.fieldsCustomersFilterDropdownLoader = false;

            if (result != null && 'data' in result) {
                
                if (result.data != null) {
                    if (result.data.value.length <= 0) {
                        rootState.searchNotify = StringConstants.noSearchResultFoundText;
                    }
                    commit('setOwnerList', result.data.value);
                    commit('setOwnerListSize', result.data.size);
                }

                return result;
            }
        }
    },

    async getFieldsOwnerListInternalBulkUpload({ commit, rootState }, {queryString}) {
        if (rootState.loginUserRole != UserRoleType.Farmer) {
           
            const url = buildUrl(rootState.baseUrl, {
                path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + apiFarmers + (queryString || '') + (queryString ? '&' : '?') + 'IsInternal=true'
            });

            const result = await requests.getData(url);

            if (result != null && 'data' in result) {
                if (result.data != null) {
                    if (result.data.value.length <= 0) {
                        rootState.searchNotify = StringConstants.noSearchResultFoundText;
                    }
                    commit('setOwnerListBulkUpload', result.data.value);
                }

                return result;
            }
        }
    },

    async getUserFarmList({ state, commit, rootState }, id) {
        if(id) {
            state.fieldFarmList = [];
            const url = buildUrl(rootState.baseUrl, {
                path: apiUsers + '/' + id + apiAddresses + "?type=0"
            });
            const result = await requests.getData(url, true);
            if (result != null && 'data' in result) {
                if (result.data != null) {
                    if (result.data.value.length > 0) {
                        commit('setFieldFarmList', result.data.value);
                    }
                }
            }
        }
    },

    async calculateAndSetPolygonArea({ state }, polygon) {
        if (polygon == null) return

        function getPolygonArea() {
            const computedArea = google.maps.geometry.spherical.computeArea(polygon.getPath())
            return parseFloat(computedArea.toFixed(2))
        }
        function setPolygonArea(polygonArea) {
            state.fieldDetails.areaInSquareMeter = polygonArea
            state.areaInSquareMeter = polygonArea
            state.convertedArea = parseFloat(getConvertedAreaUnit(polygonArea))
        }

        const computedArea = getPolygonArea()
        if (computedArea > 0)
            state.areaInvalid = false
        else
            state.areaInvalid = true
        
        setPolygonArea(computedArea)
    },

    async setFieldCoordinates({commit}, polygon) {
        const path = polygon.getPath();
        for (let i = 0; i < path.length; i++) {

            const data = {
                latitude: path.getAt(i).lat(),
                longitude: path.getAt(i).lng()
            }

            if (data != null) {
                if (i == 0) {
                    commit("setAddFieldCord", null);
                }
                commit("setAddFieldCord", data);
            }
        }
    },

    async addEventListenerToPolygon({dispatch}, polygon) {
        const pathChangeEvents = ['set_at', 'insert_at', 'remove_at'];
        pathChangeEvents.forEach(eventName => polygon.getPath().addListener(eventName, () => {
            updatePolygon(polygon);
        }))

        function updatePolygon(polygon) {
            dispatch('calculateAndSetPolygonArea', polygon);
            dispatch('setFieldCoordinates', polygon);
        }
    },

    async initializeDrawingManager({state, dispatch}, polygonOptions = null) {
        state.drawingManager?.setMap(null);
        const drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.POLYGON,
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [google.maps.drawing.OverlayType.POLYGON]
            },
            polygonOptions: polygonOptions || ConstantValues.defaultDrawingManagerPolygonOptions
        });

        if (!state.fieldsInfoWindowContent) {
            state.fieldsInfoWindowContent = new window.google.maps.InfoWindow();
        }

        drawingManager.addListener('polygoncomplete', (polygon: any) => {
            const lastVertex = polygon.getPath().getAt(polygon.getPath().length - 1);
            drawingManager.setOptions({
                drawingControl: false,
            });
            drawingManager.setDrawingMode(null);
            state.tempFieldPolygon = polygon;
            state.fieldsInfoWindowContent.setContent(`<div style="padding: 10px">${StringConstants.fieldInfoWindowMessage}</div>`);
            state.fieldsInfoWindowContent.setPosition({lat: lastVertex.lat(), lng: lastVertex.lng()});
            state.fieldsInfoWindowContent.open(state.map);
            setTimeout(function() {
                state.fieldsInfoWindowContent.close();
            }, ConstantValues.defaultInfoWindowTimeout)
            // TODO: set polygon coordinates
            dispatch('setFieldCoordinates', polygon);
            dispatch('calculateAndSetPolygonArea', polygon)
            .then(() => dispatch('addEventListenerToPolygon', polygon));
        });

        drawingManager.setMap(state.fieldsListMap || state.map);
        state.drawingManager = drawingManager;
    },

    async getGoogleMapToAddField({ state, commit, dispatch }, divId) {
        state.polygonShape = null;
        state.polyPath = null;
        state.map = null;
        $('#' + divId).html('');
        loadGoogleMapsApi({
            key: ConstantValues.gMapsApiKey,
            libraries: ['places', 'drawing', 'geometry']
        }).then(async () => {
            const latitude = state.fieldsListMap?.getCenter().lat() || ConstantValues.defaultBusinessLatitude;
            const longitude = state.fieldsListMap?.getCenter().lng() || ConstantValues.defaultBusinessLongitude;
            const mapZoom = state.fieldsListMap?.getZoom() || ConstantValues.defaultMapZoom;
            const { google } = window;
            const mapOptions = {
                zoom: mapZoom,
                mapTypeId: google.maps.MapTypeId.HYBRID,
                center: new google.maps.LatLng({ "lat": parseFloat(latitude), "lng": parseFloat(longitude) }),
                mapTypeControl: true,
                maxZoom: ConstantValues.defaultMaxMapZoom,
                minZoom: ConstantValues.defaultMinMapZoom,
                streetViewControl: false,
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.BOTTOM_LEFT,
                    mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID]
                }
            };
            state.map = new google.maps.Map(document.getElementById(divId), mapOptions);

            await dispatch('initializeDrawingManager')
                
            let tempFields = [];

            function localClearTempFields() {
                tempFields = []
            }
            if (state.fieldsListMapData.length > 0) {
                state.fieldsListMapData.map(field => {
                    const fieldInfo = {
                        field: field,
                        customer: (field.owner.businessProfile ? field.owner.businessProfile.title : ((field.owner.firstName ? field.owner.firstName : '') + ' ' + (field.owner.lastName ? field.owner.lastName : ''))),
                        ownerId: field.owner.id ? field.owner.id : null,
                        fieldId: field.id,
                        fieldColor: '#ffffff'
                    }
                    tempFields.push(fieldInfo)
                })

                if (tempFields && tempFields.length > 0 && state.fieldsListMapData && state.fieldsListMapData.length > 0) {
                    if(state.totalFieldsCount < ConstantValues.defaultPageLimitForJobsFields) {
                        await dispatch('getFieldsListOnMapReadOnly', tempFields);
                    }
                    commit('setFieldsListForSearchReadOnly', tempFields);
                } else {
                    console.error('No fields found')
                }
                localClearTempFields()
            }
        });
    },

    async editAreaOnMap({ state, dispatch }) {
        if (state.polygon) {
            state.polygon.setEditable(true);
            dispatch('addEventListenerToPolygon', state.polygon);
        }
    },

    async resetAreaToInitialState({state, dispatch}) {
        state.polygonShape && state.polygon && state.polygon?.setPath(state.polygonShape?.paths);
        if(state.highlightedFieldsListMapData && state.highlightedFieldsListMapData.length) {
            const fieldIndex = state.highlightedFieldsListMapData.findIndex(field => field.id === state.fieldDetails.id);
            state.highlightedFields[fieldIndex]?.setMap(state.fieldsListMap);
        }
        
        if (state.polygon) {
            await dispatch('setFieldCoordinates', state.polygon)
            .then(async () => {
                await dispatch('calculateAndSetPolygonArea', state.polygon)
                .then(() => {
                    state.polygon?.setEditable(false);
                    state.polygon?.setMap(state.fieldsListMap || state.map);
                    state.drawingManager?.setDrawingMode(null);
                    state.drawingManager?.setOptions({
                        drawingControl: false
                    });
                    state.tempFieldPolygon?.setMap(null);
                })
            })
        }
    },

    async resetDrawingManagerAndTempStates({state, dispatch, commit}) {
        commit('clearFieldsPolygons');
        if (!state.drawingManager) dispatch('initializeDrawingManager');
        else state.drawingManager.setOptions({
            drawingMode: google.maps.drawing.OverlayType.POLYGON, 
            drawingControl: true
        });
    },

    async getGoogleMapToEditField({ state }, data) {

        const polygonPath = data[0];
        if (state.colorUpdateOnMap != true) {
            $('#' + data[1]).html("");
        }
        loadGoogleMapsApi({
            key: ConstantValues.gMapsApiKey,
            libraries: ['places', 'drawing', 'geometry']
        }).then(() => {
            const colorCode = state.fieldDetails.colour;
            const polygonData = getPolygonCoords(polygonPath);
            const x: any = polygonData[0];
            const y: any = polygonData[1];
            const z: any = polygonData[2];
            const fieldCoords: any = polygonData[3];
            const latitude = Math.atan2(z, Math.sqrt(x * x + y * y)) * 180 / Math.PI;
            const longitude = Math.atan2(y, x) * 180 / Math.PI;
            const { google } = window;
            const mapZoom = ConstantValues.defaultMapZoom;
            const mapOptions = {
                center: new google.maps.LatLng(latitude, longitude),
                zoom: mapZoom,
                maxZoom: ConstantValues.defaultMaxMapZoom,
                minZoom: ConstantValues.defaultMinMapZoom,
                streetViewControl: false,
                mapTypeId: google.maps.MapTypeId.HYBRID,
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.BOTTOM_LEFT,
                    mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID]
                }
            };
            if (state.colorUpdateOnMap != true) {
                state.map = new google.maps.Map(document.getElementById(data[1]), mapOptions);
            }
            const lineSymbol = {
                path: 'M 0,-1 0,1',
                strokeOpacity: 1,
                strokeWeight: 3,
                scale: 1,
            };

            const polygonOptions = {
                paths: fieldCoords,
                strokeColor: colorCode,
                strokeOpacity: 0.7,
                fillColor: colorCode,
                fillOpacity: 0.4,
                strokeWeight: 2
            };

            state.polygon = new google.maps.Polygon(polygonOptions);
            state.polygonShape = polygonOptions;

            const computedArea = (google.maps.geometry.spherical.computeArea(state.polygon.getPath()));
            if (computedArea > 0) {
                state.areaInvalid = false;
            }
            else {
                state.areaInvalid = true;
            }

            if (state.mountedGMaps) {
                state.areaInSquareMeter = parseFloat(computedArea.toFixed(2));
                if(parseFloat(state.fieldDetails.areaInSquareMeter) > 0) {
                    state.fieldDetails.areaInSquareMeter = parseFloat(state.fieldDetails.areaInSquareMeter)
                } else {
                    state.fieldDetails.areaInSquareMeter = state.areaInSquareMeter;
                }
                state.convertedArea = parseFloat(getConvertedAreaUnit(state.fieldDetails.areaInSquareMeter));
            } else {
                if (computedArea != 0 && state.fieldDetails.areaInSquareMeter == 0) {
                    state.areaInSquareMeter = parseFloat(computedArea.toFixed(2));
                    state.fieldDetails.areaInSquareMeter = state.areaInSquareMeter > 0 ? state.areaInSquareMeter : 0;
                    state.convertedArea = parseFloat(getConvertedAreaUnit(state.fieldDetails.areaInSquareMeter));
                }
                state.areaInSquareMeter = parseFloat(state.fieldDetails.areaInSquareMeter);
                state.convertedArea = parseFloat(getConvertedAreaUnit(state.areaInSquareMeter));
            }

            state.fieldCordList = [];
            state.fieldCordList = polygonPath;

            state.polygon.setMap(state.map);

            const bounds = new google.maps.LatLngBounds();

            for (let i = 0; i < fieldCoords.length; i++) {
                const LatLng = new google.maps.LatLng(fieldCoords[i].lat, fieldCoords[i].lng);
                bounds.extend(LatLng);
            }

            if (fieldCoords.length != 0) {
                state.map.fitBounds(bounds);
            }

            const listener = google.maps.event.addListener(state.map, "idle", function () {
                state.map.fitBounds(bounds, ConstantValues.defaultMapPadding);
                google.maps.event.removeListener(listener);
            });
        });
    },

    clearPolygonOnMap({ state }) {
        if (state.polygonShape)
            state.polygonShape.setMap(null)
        if (state.polyPath)
            state.polyPath.setMap(null)
    },

    handleBulkUploadFieldsResponse({ rootState }, response) {
        if(response.data.failed) {
            rootState.bulkActionResponse = response.data
            rootState.dialogBulkActionResponseTitle = StringConstants.bulkActionResponseTitleFiledsImport
            rootState.dialogBulkActionResponse = true
        } else {
            notify(StringConstants.fieldsBulkUploadSuccess, 'success')
            setTimeout(async () => {
                await FieldsCacheService.deleteFieldsCacheDatabase()
                location.reload()
            }, 2000)
        }
        return response
    },

    async bulkUploadFields({ state, rootState, dispatch }, data) {
        state.bulkUploadInProgress = true
        rootState.bulkActionResponse = null

        const url = buildUrl(rootState.baseUrl, {
            path: localStorage.getItem(ConstantValues.ownerId) + '/fields/import'
        })
        const result = await requests.postBulkFields(url, data)
        
        state.bulkUploadInProgress = false
        if (result[0] != null && result[0].status == 200) {
            return await dispatch('handleBulkUploadFieldsResponse', result[0])
        }
    },

    async bulkUploadFieldsContractor({ state, rootState, dispatch }, data) {
        state.bulkUploadInProgress = true
        rootState.bulkActionResponse = null

        const url = buildUrl(rootState.baseUrl, {
            path: state.bulkUploadOwner + '/fields/import'
        });
        const result = await requests.postBulkFields(url, data)
        
        state.bulkUploadInProgress = false
        if (result[0] != null && result[0].status == 200) {
            return await dispatch('handleBulkUploadFieldsResponse', result[0])
        }
    },

    async drawAllFieldsOnMap({ state, dispatch }) {
        if (!state.fieldsListMap) {
            await dispatch('getGoogleMapForFieldsListMap', 'field-list-map');
        } else if (state.fieldsListFieldShapes.length === 0) {
            await dispatch('attachExistingGoogleMapsObjectToDom', 'field-list-map');
        }
        await dispatch('drawFieldsOnMap');
        await dispatch('highlightFieldsOnMap');
        await dispatch("setMapBoundsForFieldsInFields");
    },

    async generateFieldsUrlPaths({ state, rootState }) {
        const callStackSize = ConstantValues.defaultPageLimitForJobsFields;

        const url = buildUrl(rootState.baseUrl, {
            path: '/fields?offset=0&limit=1' + '&Preference=' + state.fieldStatus
        });
        const fieldCountResponse = await requests.getData(url);
        state.totalFieldsCount = fieldCountResponse?.data?.size;

        const fieldsCallCounter = Math.ceil(state.totalFieldsCount / callStackSize);
        const urlPaths = [];

        for (let i = 0; i < fieldsCallCounter; i++) {
            let path = rootState.baseUrl;
            path += apiFields + '?' + 'Preference=' + state.fieldStatus + '&' + ConstantValues.limitQuery + '=' + callStackSize + '&offset=' + i * callStackSize;

            urlPaths.push(path);
        }

        return urlPaths;
    },

    async handleUnsyncedFields({ state }, fields) {
        const newlyAddedFields = fields.filter(field => field.changeStatus === ChangeStatusType.Added);
        const updatedFields = fields.filter(field => field.changeStatus === ChangeStatusType.Updated);
        const deletedFields = fields.filter(field => field.changeStatus === ChangeStatusType.Deleted);

        if (newlyAddedFields?.length > 0)
            await FieldsCacheService.addNewFieldsToCache(newlyAddedFields);

        if (updatedFields?.length > 0)
            await FieldsCacheService.updateFieldsInCacheByFieldIds(updatedFields);

        if (deletedFields?.length > 0)
            await FieldsCacheService.deleteFieldsFromCacheByFieldIds(deletedFields);

        await FieldsCacheService.setLastCachedAt();
    },

    async getFieldsWithChangesSinceLastSync({ rootState }) {
        const lastSyncedTime = await FieldsCacheService.getLastCachedAt();
        const formattedDate = getUTCDateFormat(lastSyncedTime);
    
        const fetchFields = async (offset, limit, accumulatedResults = []) => {
            const url = buildUrl(rootState.baseUrl, {
                path: apiFields + `?ChangesSince=${formattedDate}&Offset=${offset}&Limit=${limit}`
            });
    
            const response = await requests.getData(url, true, true);
            if (!response || !response.data) {
                throw new Error("Failed to fetch data from the API.");
            }
    
            const combinedResults = [...accumulatedResults, ...response.data.value];
    
            if (response.data.size > offset + limit) {                
                return await fetchFields(offset + limit, limit, combinedResults);
            }
    
            return combinedResults;
        };
    
        try {
            const allResults = await fetchFields(0, ConstantValues.defaultPageLimitForJobsFields);
            return allResults;
        } catch (error) {
            console.error("Error fetching fields:", error);
            throw error;
        }
    },

    async checkAndHandleUnsyncedFields({ dispatch }) {
        const result = await dispatch('getFieldsWithChangesSinceLastSync');
        if (result.length > 0) 
            await dispatch('handleUnsyncedFields', result);
    
        return;
    },

    async getAndRenderInactiveFields({ state, rootState, dispatch, commit }) {
        state.fieldsListMapLoader = true;
        const fields = [];

        const urlPaths = await dispatch('generateFieldsUrlPaths')
        await Promise.all(urlPaths.map(async (urlPath) => {
            const result = await requests.getData(urlPath, true, true);
            if (result?.data?.value) {
                fields.push(...result.data.value);

                commit('setFieldsListMapData', result.data.value);
                commit('setFieldsLoadedCounter', null);
            }
        }));

        await dispatch('drawAllFieldsOnMap');
        state.fieldsListMapLoader = false;
    },

    async getAllFieldsForMap({ state, commit, dispatch }) {
        commit('clearFieldsListMapData');
        commit('clearFieldsListFieldShapes');

        if (state.fieldStatus == TabFilterType.Inactive) {
            await dispatch('getAndRenderInactiveFields') 
            return;
        }

        state.fieldsListMapLoader = true;
        let allFields = [];

        const hasCachedFields = await FieldsCacheService.hasCachedFields();

        if (hasCachedFields) {
            await dispatch('checkAndHandleUnsyncedFields');

            allFields = await FieldsCacheService.getAllCachedFields();
            state.totalFieldsCount = allFields.length;

            commit('setFieldsListMapData', allFields);
            await dispatch('drawAllFieldsOnMap');
            state.fieldsListMapLoader = false;
            return;
        }
        
        const urlPaths = await dispatch('generateFieldsUrlPaths')
        await Promise.all(urlPaths.map(async (urlPath) => {
            const result = await requests.getData(urlPath, true, true);
            if (result?.data?.value) {
                allFields.push(...result.data.value);

                commit('setFieldsListMapData', result.data.value);
                commit('setFieldsLoadedCounter', null);
            }
        }));

        await dispatch('drawAllFieldsOnMap');
        if (allFields?.length > 0)
            await FieldsCacheService.cacheAllFields(allFields);

        state.fieldsListMapLoader = false;
    },

    async resetFieldsDbCache({ dispatch }) {
        await FieldsCacheService.deleteFieldsCacheDatabase();
        await dispatch('getAllFieldsForMap');
    },

    async getGoogleMapForFieldsListMap({ state }, mapDiv) {
        state.fieldsListMapLoader = true;
        await loadGoogleMapsApi({
            key: ConstantValues.gMapsApiKey,
            libraries: ['places', 'drawing', 'geometry']
        }).then(() => {
            const mapZoom = ConstantValues.defaultMapZoom;
            const { google } = window;

            const mapLat = localStorage.getItem('defaultStartingLat') ? parseFloat(localStorage.getItem('defaultStartingLat')) : ConstantValues.defaultBusinessLatitude;
            const mapLng = localStorage.getItem('defaultStartingLng') ? parseFloat(localStorage.getItem('defaultStartingLng')) : ConstantValues.defaultBusinessLongitude;

            const mapOptions = {
                zoom: mapZoom,
                mapTypeId: google.maps.MapTypeId.HYBRID,
                center: { lat: mapLat, lng: mapLng },
                mapTypeControl: true,
                streetViewControl: false,
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.BOTTOM_LEFT,
                    mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID]
                },
                options: {
                    gestureHandling: 'cooperative'
                }
            }

            state.fieldsListMap = new google.maps.Map(document.getElementById(mapDiv), mapOptions)

            state.fieldsInfoWindowContent = new google.maps.InfoWindow()
        });
    },

    async triggerFieldEditView({ rootState, commit, dispatch }, fieldData) {
        if(!rootState.drawerOpen) {
            commit('setMapTriggeredEditFieldData', fieldData)
        }

        dispatch('revertHighlightedFieldsOnMap')
    },

    async clearFieldListsLabelsAndFields({ state }) {
        if (state.fieldsListMap?.data) {
            FeatureLayerHelper.removeAllClickListenersFromDataLayerFeatures()
            FeatureLayerHelper.removeAllFeaturesFromDataLayer(state.fieldsListMap.data)
            FeatureLayerHelper.removeAllInfoWindowsFromDataLayerFeatures()
        }

        state.fieldsListFieldShapes = []
        state.fieldsListMapLabels = []
        state.fieldsListMapData = []
        state.fieldsListMapLoaded = null
        state.fieldsListMapTotal = null
        state.fieldsListMapOffset = null
    },

    async addFieldCrop({ rootState }, data) {
        const { ownerId, resourceId, cropObj } = data

        const url = buildUrl(rootState.baseUrl, {
            path: ownerId + apiFields + "/" + resourceId + '/crops'
        })
        const result = await requests.postData(url, cropObj, true)
        if (result && result[0]) {
            return result[0]
        }
    },

    async getJobsByField({ rootState, commit }, data) {
        const { ownerId, resourceId } = data
        if (ownerId && resourceId) {
            const url = buildUrl(rootState.baseUrl, {
                path: ownerId + apiFields + "/" + resourceId + '/jobs'
            })
            const result = await requests.getData(url);

            if (result && result.data) {
                commit('setJobsByField', result.data.value)
                return result.data.value
            }
        }
    },

    async mountGoogleMapForAreaCalculation({ state }, divId) {
        state.polygonShape = null
        state.polyPath = null
        state.map = null
        $('#' + divId).html('')
        loadGoogleMapsApi({
            key: ConstantValues.gMapsApiKey,
            libraries: ['places', 'drawing', 'geometry']
        }).then(() => {
            const defaultStartingLat = localStorage.getItem('defaultStartingLat')
            const defaultStartingLng = localStorage.getItem('defaultStartingLng')
            const latitude = defaultStartingLat ? defaultStartingLat : ConstantValues.defaultLatitude
            const longitude = defaultStartingLng ? defaultStartingLng : ConstantValues.defaultLongitude
            const mapZoom = ConstantValues.defaultMapZoom
            const { google } = window
            const mapOptions = {
                zoom: mapZoom,
                mapTypeId: google.maps.MapTypeId.HYBRID,
                center: new google.maps.LatLng({ "lat": parseFloat(latitude), "lng": parseFloat(longitude) }),
                mapTypeControl: true,
                maxZoom: ConstantValues.defaultMaxMapZoom,
                minZoom: ConstantValues.defaultMinMapZoom,
                streetViewControl: false,
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.BOTTOM_LEFT
                }
            };
            state.map = new google.maps.Map(document.getElementById(divId), mapOptions)
        })
    },

    async getFieldsListOnMapReadOnly({ state }, data) {
        try {
            const fields = data.map(fieldInfo => fieldInfo.field);

            for (let i = 0; i < fields.length; i++) {
                if (fields[i].polygon?.length > 0) {
                    const polygon = FeatureLayerHelper.getCoordinatesFromFeatureProperty(fields[i].polygon);

                    const polygonLatLng = [];
                    let cords = null;
                    let cordColor = null;
                    cords = polygon;

                    for (let i = 0; i < cords.length; i++) {
                        const x = {
                            lat: cords[i].latitude,
                            lng: cords[i].longitude
                        }
                        polygonLatLng.push(x);
                    }

                    cordColor = '#ffffff'

                    const PolygonShape = new google.maps.Polygon({
                        paths: polygonLatLng,
                        strokeColor: cordColor,
                        map: state.map,
                        strokeWeight: 3,
                        strokeOpacity: 0.6,
                        fillColor: cordColor,
                        fillOpacity: 0.2,
                    });

                    state.fieldsListFieldShapesReadOny.push(PolygonShape);
                }

                if (state.fieldsListFieldShapesReadOny?.length > 0) 
                    state.fieldsListFieldShapesReadOny.forEach(fld => fld.setMap(state.map))
            }
        } catch (e) {
            console.error(e.toString())
        }
    },

    async setMapBoundsForFieldsListReadOnly({ state }, data) {
        const fields = data.map(fieldInfo => fieldInfo.field);
        const parsedFields = []
        fields.map(f => {
            const field = {
                ...f,
                polygon: Array.isArray(f.polygon) ? f.polygon : JSON.stringify(f.polygon) 
            }
            parsedFields.push(field)
        })
        boundMapOnCordinates(state.map, parsedFields);
    },

    async getFeildsByOwnerReadOnly({ state, dispatch, commit }, ownerId) {
        let tempFieldsReadonly = []

        if (ownerId) {
            if (state.fieldsListFieldShapesReadOny.length > 0) {
                for (let i = 0; i < state.fieldsListFieldShapesReadOny.length; i++) {
                    state.fieldsListFieldShapesReadOny[i].setMap(null)
                }
            }

            if (state.fieldsListMapData.length > 0) {
                state.fieldsListMapData.map(field => {
                    if (field.owner && field.owner.id && ownerId === field.owner.id) {
                        const fieldInfo = {
                            field: field,
                            customer: (field.owner.businessProfile ? field.owner.businessProfile.title : ((field.owner.firstName ? field.owner.firstName : '') + ' ' + (field.owner.lastName ? field.owner.lastName : ''))),
                            ownerId: field.owner.id ? field.owner.id : null,
                            fieldId: field.id,
                            fieldColor: '#ffffff'
                        }
                        tempFieldsReadonly.push(fieldInfo)
                    }
                })

                if (tempFieldsReadonly && tempFieldsReadonly.length > 0 && state.fieldsListMapData && state.fieldsListMapData.length > 0) {
                    await dispatch('getFieldsListOnMapReadOnly', tempFieldsReadonly)
                    if (!state.tempFieldPolygon) {
                        await dispatch('setMapBoundsForFieldsListReadOnly', tempFieldsReadonly)
                    }
                    commit('setFieldsListForSearchReadOnly', tempFieldsReadonly)
                } else {
                    if (state.fieldsListMapLabelsReadOnly.length > 0 && state.map) {
                        for (let i = 0; i < state.fieldsListMapLabelsReadOnly.length; i++) {
                            state.fieldsListMapLabelsReadOnly[i].setMap(null);
                        }
                        state.fieldsListMapLabelsReadOnly = []
                    }
                    console.error('No fields found for this customer!')
                }
                tempFieldsReadonly = []
            }
        }
    },

    async navigateToField({ state }, fieldId) {
        if (state.fieldsListForSearchReadOnly && state.fieldsListForSearchReadOnly.length > 0) {
            const geoJsonFeature = state.fieldsListForSearchReadOnly.filter(field => field.field.id == fieldId)[0].field.polygon
            const bounds = FeatureLayerHelper.getBoundsByFeatureIds(state.fieldsListMap.data, [geoJsonFeature.id])

            if (state.fieldsListMap || state.map) {
                state.fieldsListMap?.fitBounds(bounds);
                state.fieldsListMap?.setZoom(16)
                state.map?.fitBounds(bounds)
                state.map?.setZoom(16)
            }

            state.fieldSearchResultsReadOnly = []
            state.fieldSearchTextReadOnly = null
        }
    },

    async revertHighlightedFieldsOnMap({ state }) {
        if (state.highlightedFieldsListMapData.length > 0) {
            FeatureLayerHelper.revertFeatureStylesByIds(state.fieldsListMap.data, state.highlightedFieldsListMapData.map(f => f.id))
        }
    },

    async highlightFieldsOnMap({ state, commit, dispatch }) {
        await dispatch('revertHighlightedFieldsOnMap')

        let fieldsToHighlight = []
        state.highlightedFields = []
        state.highlightedFieldsListMapData = []

        let filteredFields = [];

        if (state.fieldListOwnerId == 'All' && state.fieldSearchText) {
            filteredFields = state.fieldsListMapData
        } else  {
            filteredFields = state.fieldsListMapData.filter(field => field.owner.id == state.fieldListOwnerId)
        }

        if (state.fieldSearchText) {
            filteredFields = filteredFields.filter(field => field.title.toLowerCase().includes(state.fieldSearchText.toLowerCase()));
        }
        if (filteredFields.length > 0) {
            fieldsToHighlight = filteredFields.map(field => field.id)

            const bounds = FeatureLayerHelper.getBoundsByFeatureIds(state.fieldsListMap.data, fieldsToHighlight)
            FeatureLayerHelper.highlightFeaturesByIds(state.fieldsListMap.data, fieldsToHighlight)
            state.fieldsListMap.fitBounds(bounds)
            commit("setHighlightedFieldsListMapData", filteredFields)
        }
    },

    async locateFieldReadOnly({ state }) {
        let fieldIndex = 0
        state.fieldsListForSearchReadOnly.map((fld, index) => {
            if (fld.title && fld.title.toLowerCase().includes(state.fieldSearchTextReadOnly.toLowerCase())) {
                fieldIndex = index
                state.hotspotSearchText = fld.title
            }
        })
        const polygonPoints = state.fieldsListForSearchReadOnly[fieldIndex].field.polygon

        const bounds = new google.maps.LatLngBounds()

        polygonPoints.map(polygon => {
            const LatLng = new google.maps.LatLng(polygon.latitude, polygon.longitude)
            bounds.extend(LatLng)
        })

        if (state.map) {
            state.map.fitBounds(bounds)
            state.map.setZoom(16)
        }

        state.fieldSearchResultsReadOnly = []
        state.fieldSearchTextReadOnly = null
    },

    removeFieldFromList({ state, rootState }, fieldId) {
        if (state.fieldFilterList?.length > 0) {
            state.fieldFilterList = state.fieldFilterList.filter(field => field.id !== fieldId)
            state.fieldsTotalCount -= 1
            rootState.totalRowCount -= 1
            rootState.noData = state.fieldFilterList.length === 0
        }
    },

    async archiveField({ rootState, dispatch}, {fieldId, fieldOwnerId}) {
        const url = buildUrl(rootState.baseUrl, {
            path: fieldOwnerId + apiFields + "/" + fieldId + "/archive" + "?Preference=" + state.fieldStatus
        });
        const result = await requests.postData(url, {});
        if (result[0] == '') {
            dispatch('removeFieldsAndUpdateMapBounds', [fieldId]);
            notify(StringConstants.fieldArchiveSuccessMessage, 'success');
            dispatch('removeFieldFromList', fieldId);
            dispatch('checkAndHandleUnsyncedFields');
        }
    },

    async restoreField({ rootState, dispatch}, {fieldId, fieldOwnerId}) {
        const url = buildUrl(rootState.baseUrl, {
            path: fieldOwnerId + apiFields + "/" + fieldId + "/restore" + "?Preference=" + state.fieldStatus
        });
        const result = await requests.postData(url, {});
        if (result[0] == '') {
            dispatch('removeFieldsAndUpdateMapBounds', [fieldId]);
            notify(StringConstants.fieldRestoreSuccessMessage, 'success');
            dispatch('checkAndHandleUnsyncedFields');
        }
    },

    async removeFieldsAndUpdateMapBounds({ state }, removedFieldIds) {
        removedFieldIds.forEach(fieldId => {
            FeatureLayerHelper.removeFeatureByIdFromDataLayer(state.fieldsListMap.data, fieldId)

            if(state.fieldsListMapData) {
                deleteObjectFromList(state.fieldsListMapData, fieldId, 'id')
            }
            if(state.fieldFilterList) {
                deleteObjectFromList(state.fieldFilterList, fieldId, 'id')
            }
        });
    },

    async removeFieldsFromMapByIds({ state }, [removableFieldIds, removeAllFields]) {
        removableFieldIds.forEach((fd) => {
            FeatureLayerHelper.removeFeatureByIdFromDataLayer(state.fieldsListMap.data, fd)
            deleteObjectFromList(state.fieldsListMapData, fd, 'id')
        })
    },

    async setMapBoundsForFieldsInFields({ state }) {
        if (state.fieldFilterList && state.fieldListOwnerId == 'All') {
            FeatureLayerHelper.boundMapByAllFeatures(state.fieldsListMap.data, state.fieldsListMap)
        }
    },

    async drawFieldsOnMap({state, dispatch, commit}) {
        if (state.fieldsListMapData?.length > 0) {
            state.formattedFields = [];
            state.fieldsListMapData.map(field => {
                state.formattedFields.push(formatFieldObjectForLabel(field))
            });
            if (state.formattedFields?.length > 0) {
                commit('clearFieldsListFieldShapes');
                await dispatch('getFieldsListOnMap');
            }
        }
    },

    async attachExistingGoogleMapsObjectToDom({state}, mapDiv) {
        document.getElementById(mapDiv).replaceWith(state.fieldsListMap.getDiv());
    },

    async updateMapToAddField({state, dispatch, commit}) {                
        if (!state.fieldDetails.owner) {
            const selectOwnerAlertListener = state.fieldsListMap.addListener('mouseover', function() {
                commit('setMapAlertMessage', StringConstants.fieldAddSelectCustomerAlertMessage);
            });
            commit('setMapAlertListener', selectOwnerAlertListener);
            commit('turnoffDrawingManager');
        } else {
            dispatch('initializeDrawingManager', ConstantValues.defaultDrawingManagerPolygonOptions);
        }
        let tempFields = [];

        function localClearTempFields() {
            tempFields = []
        }
        if (state.fieldsListMapData.length > 0) {
            state.fieldsListMapData.map(field => {
                const fieldInfo = {
                    field: field,
                    customer: (field.owner.businessProfile ? field.owner.businessProfile.title : ((field.owner.firstName ? field.owner.firstName : '') + ' ' + (field.owner.lastName ? field.owner.lastName : ''))),
                    ownerId: field.owner.id ? field.owner.id : null,
                    fieldId: field.id,
                    fieldColor: '#ffffff'
                }
                tempFields.push(fieldInfo)
            })

            if (tempFields && tempFields.length > 0 && state.fieldsListMapData && state.fieldsListMapData.length > 0) {
                if(state.totalFieldsCount < ConstantValues.defaultPageLimitForJobsFields) {
                    await dispatch('getFieldsListOnMapReadOnly', tempFields);
                }
                commit('setFieldsListForSearchReadOnly', tempFields);
            } else {
                console.error('No fields found')
            }
            localClearTempFields()
        }
    },

    async cleanUpMapAndFieldForm({state, commit, dispatch}) {
        commit('turnoffDrawingManager');
        if (state.fieldDetails?.id) {
            state.editingAreaOnMap = null;
            await dispatch('resetAreaToInitialState');
        } else {
            commit('clearFieldsPolygons');
        }
        commit('clearMapAlertMessage');
        commit('clearAlertMessageListener');
        commit('resetFieldDetails');
        commit('setJobsByField', []);
        commit('clearFieldFarmList', []);
        commit('setMapTriggeredEditFieldData', null);
    },

    async requestGooglePlacesSearchOnFields({ state, dispatch }, focusOnFirstResult) {
        if (!state.placesSearchQuery || state.placesSearchQuery?.length <= 2) return;

        const country = localStorage.getItem('country');

        const request = {
            query: country ? `${state.placesSearchQuery},${country}` : state.placesSearchQuery,
            fields: ['name', 'geometry'],
        }

        const service = new google.maps.places.PlacesService(state.map);

        service.findPlaceFromQuery(request, (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                state.placesSearchResults = results;

                if (focusOnFirstResult && state.placesSearchResults.length > 0)
                    dispatch('focusOnSearchResultByIndex', 0);
            }
        });
    },

    async focusOnSearchResultByIndex({ state }, index) {
        if (state.placesSearchResults.length > 0) {
            state.fieldsListMap.setCenter(state.placesSearchResults[index].geometry.location);
            state.fieldsListMap.setZoom(18);
        }
    },

    async attachClickListenersToFields({ state, dispatch }) {
        const fields = state.formattedFields.map(fieldInfo => fieldInfo.field)

        FeatureLayerHelper.attachClickListnerToDataLayerFeatures(state.fieldsListMap.data, (id: string, mapEventListerner: google.maps.MapsEventListener) => { 
            const field = fields.find(f => f.id === id)
            dispatch('triggerFieldEditView', field)
        })
    },

    async getFieldsListOnMap({ state, dispatch }) {
        try {
            FeatureLayerHelper.removeAllInfoWindowsFromDataLayerFeatures()
            const fields = state.formattedFields.map(fieldInfo => fieldInfo.field);
            state.fieldsListMap.data
            for (let i = 0; i < fields.length; i++) {
                try {
                   const fieldInfo = await dispatch('createFieldInfos', fields[i])
                   FeatureLayerHelper.addGeoJsonFeatureOnMap(fields[i].polygon, state.fieldsListMap.data, fieldInfo)
                } catch (e) {
                   console.error(e)
                }
            }

            const infoWindow = new google.maps.InfoWindow()

            FeatureLayerHelper.attachInfoWindowToDataLayerFeatures(state.fieldsListMap.data, state.fieldsListMap, infoWindow)
            dispatch('attachClickListenersToFields')
             
        } catch (e) {
            console.error(e.toString())
        }
    },

    async createPolygonFromFeature({ state }, { featureId }) {
        const feature = state.fieldsListMap.data.getFeatureById(featureId);
        state.tempFeature = feature;
        const polygon = feature.getGeometry();
        const polygonColor = feature.getProperty('colour');
        const polygonCoords = polygon.getAt(0).getArray().map(coord => {
            return new google.maps.LatLng({ "lat": parseFloat(coord.lat()), "lng": parseFloat(coord.lng()) })
        });

        state.polygon = new google.maps.Polygon({
            paths: polygonCoords,
            strokeColor: polygonColor,
            strokeWeight: 3,
            strokeOpacity: 1,
            fillColor: polygonColor,
            fillOpacity: 0.5,
            editable: false,
            zIndex: 99999
        });

        state.polygon.setMap(state.fieldsListMap);
        
        state.fieldsListMap.data.remove(feature);
        focusMapOnPolygon(state.fieldsListMap, state.polygon);
    },

    async reviveTempFeature({ state }) {
        if (state.tempFeature) {
            state.fieldsListMap.data.add(state.tempFeature)
            state.tempFeature = null
        }
    },

    async revertTempPolygon({ state, dispatch }) {
        if (state.polygon) {
            state.polygon.setMap(null)
            state.polygon = null
        } 

        await dispatch('reviveTempFeature')
    },
}
