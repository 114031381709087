















































































































































































































































import { mapMutations, mapActions } from "vuex";
import fieldsComputedMixin from "./fields-computed";
import CustomerAvatar from "@/components/shared/avatars/avatar.vue";
import { BaseButton as Button } from "@/components/shared/buttons/index";
import LoadMoreItem from '../shared/load-more-item.vue';
import {
    closeOverlay,
    checkFieldsValidity,
    getConvertedAreaUnit,
    getListLengthAbsolute,
    getAreaUnit,
    getConvertedArea,
    convertToSquareMeters
} from "@/utils/helpers";
import ConstantValues from '@/plugins/constantValues';
import { formFieldPb } from "@/utils/uiconstants";
import PaginationModule from "@/store/modules/pagination";
import { ownerColor } from '@/utils/constants';
import { routeFields } from '@/utils/endpoints';

export default {
    name: "FieldForm",
    mixins: [fieldsComputedMixin],
    components: {
        CustomerAvatar,
        Button,
        LoadMoreItem,
    },
    data() {
        return {
            formFieldPb,
            files: null,
            isOwnerShip: false,
            searchTimerCustomers: null,
            areaUnit: getAreaUnit(),
            recalculateButtonVisible: false
        }
    },
    watch: {
        fieldDetails: {
            handler(val) {
                if (this.fieldDetails.name != null) {
                    if (this.fieldDetails.name.length == 0) {
                        this.fieldDetails.name = null;
                    }
                }
                if (this.fieldDetails.areaInSquareMeter != null && !isNaN(this.fieldDetails.areaInSquareMeter)) {
                    this.getConvertedArea();
                }
            },
            deep: true,
        },
        convertedArea(newVal) {
            if (newVal != "") {
                this.getReverseConvertedArea(newVal);
            }
        },
    },
    computed: {
        isDisabledBtn() {
            if (
                checkFieldsValidity([
                this.fieldDetails.title,
                this.fieldDetails.areaInSquareMeter,
                ]) && this.ownershipIsSet && this.isCropSelected() && !this.fieldReadonly
            ) {
                return false;
            } else {
                return true;
            }
        },
        drawerIsOpen() {
            return this.$store.state.drawerOpen
        },
        drawerName() {
            return this.fieldDetails?.title || this.$stringConstants('fieldAddNewText');
        },
        fieldDrawerAction() {
            if (this.userIsEditingField) return this.updateField
            return this.addField
        },
        fieldDrawerActionString() {
            return this.userIsEditingField ? this.$stringConstants("updateField") : this.$stringConstants('addNewField')
        },
        userIsEditingField() {
            return Boolean(this.fieldDetails.id)
        },
        ownershipIsSet() {
            return this.fieldDetails.ownership !== null || this.isOwnerShip
        }
    },
    created() {
        if(!this.$store.hasModule(this.$stringConstants('fieldsAddFilterCustomersPaginationModule'))) {
        this.$store.registerModule(this.$stringConstants('fieldsAddFilterCustomersPaginationModule'), PaginationModule);
      }
    },
    mounted() {
        this.setDefaultOwnerList(this.loginUserBusinessName);
        this.getUserFarmListAsync(this.fieldDetails.owner);
        this.$store.commit("setDashboardTitle", this.drawerName);
    },
    methods: {
        ...mapMutations({
            clearFieldFilterOption: "setClearFilterOptions",
            resetPaging: "resetPaging",
            setDefaultOwnerList: "setDefaultOwnerList",
            setDefaultOwnerFilterList: "setDefaultOwnerFilterList",
            setDrawerOpen: "setDrawerOpen",
            setMapTriggeredEditFieldData: 'setMapTriggeredEditFieldData',
            resetFieldDetails: 'resetFieldDetails',
        }),
        ...mapActions({
            setFieldFilterAsync: "setFieldFilter",
            getFieldsListFilteredAsync: "getFieldsListFiltered",
            getYearListAsync: "getYearList",
            getInteralOwnerListAction: "getOwnerList",
            getFieldsInteralOwnersListAction: "getFieldsOwnerListInternal",
            addNewFieldAsync: "addNewField",
            getGoogleMapToAddFieldAsync: "getGoogleMapToAddField",
            getUserFarmListAsync: "getUserFarmList",
            updateFieldDetailsAsync: "updateFieldDetails",
            initializeDrawingManager: "initializeDrawingManager",
            updateMapToAddField: "updateMapToAddField"
        }),
        appendMoreCustomers() { 
            if(this.fieldDetails.owner && this.fieldFarmerSearchTextInternal) {
                this.fieldFarmerSearchTextInternal = '';
                this.fieldDetails.owner = null;
            }

            this.$store.dispatch('setSearchInternalFarmersFilter', {
                offset: getListLengthAbsolute().getListLengthWithOwner(this.fieldOwnerList),
                limit: ConstantValues.defaultPageLimitSize,
                moduleNamespace: this.$stringConstants('fieldsAddFilterCustomersPaginationModule')
            }).then(() => {
                this.getOwnerListAsync();
            });
        },
        recalculateAreaOnMap() {
            this.recalculateButtonVisible = false;
            this.$store.dispatch('calculateAndSetPolygonArea', this.fieldDetails?.id ? this.polygon : this.tempFieldPolygon)
        },
        getConvertedArea() {
            if (
                this.fieldDetails.areaInSquareMeter != null &&
                this.fieldDetails.areaInSquareMeter > 0
            ) {
                this.convertedArea = parseFloat(
                    this.getAreaConverted(this.fieldDetails.areaInSquareMeter)
                );
            } else {
                this.convertedArea = parseFloat(
                    this.getAreaConverted(this.areaInSquareMeter)
                );
            }
        },
        getReverseConvertedArea(convertedArea: any) {
            const area = convertToSquareMeters(convertedArea);
            this.areaInSquareMeter = area;
            this.fieldDetails.areaInSquareMeter = area;
        },
        setManualArea() {
            this.fieldDetails.areaInSquareMeter = convertToSquareMeters(this.convertedArea);
        },
        async resetPagingAndGetOwnerList() {
            this.$store.commit(`${this.$stringConstants('fieldsAddFilterCustomersPaginationModule')}/resetPaging`)

            await this.$store.dispatch('setSearchInternalFarmersFilter', {
                offset: ConstantValues.defaultPageOffsetSize,
                limit: ConstantValues.defaultPageLimitSize,
                moduleNamespace: this.$stringConstants('fieldsAddFilterCustomersPaginationModule')
            })
            
            this.fieldOwnerList = [];
            this.getOwnerListAsync();
        },
        async getOwnerListAsync() {
            const queryString = await this.$store.dispatch(`${this.$stringConstants('fieldsAddFilterCustomersPaginationModule')}/generateQueryString`)

            this.$store.commit('setDefaultOwnerList');
            this.getFieldsInteralOwnersListAction({ queryString }).then(response => {
                this.$store.dispatch(`${this.$stringConstants('fieldsAddFilterCustomersPaginationModule')}/updateStates`, response)
            });
        },
        applySearchFilters() {
            this.fieldOwnerList = [];
            this.$store.commit("setDefaultOwnerList", this.loginUserBusinessName);

            this.$store.dispatch('setSearchInternalFarmersFilter', {
                offset: ConstantValues.defaultPageOffsetSize,
                limit: ConstantValues.defaultPageLimitSize,
                moduleNamespace: this.$stringConstants('fieldsAddFilterCustomersPaginationModule')
            }).then(() => {
                this.getOwnerListAsync();
            });
        },
        applyCustomersFilterSearchTimer() {
            if (this.searchTimerCustomers) {
                clearTimeout(this.searchTimerCustomers);
                this.searchTimerCustomers = null;
            }
            this.searchTimerCustomers = setTimeout(() => {
                this.applySearchFilters();
            }, ConstantValues.defaultDropdownSearchTimeout); 
        },
        getAreaConverted(area: any) {
            return getConvertedAreaUnit(area);
        },
        isCropSelected() {
            if (
                (this.fieldDetails.name != null &&
                this.fieldDetails.fieldYear == null) ||
                (this.fieldDetails.name == "" && this.fieldDetails.fieldYear != null) ||
                (this.fieldDetails.name == null && this.fieldDetails.fieldYear != null)
            ) {
                return false;
            } else {
                return true;
            }
        },
        checkOwnerShip(data: any) {
            if (data != null) {
                this.isOwnerShip = true;
            }
        },
        updateAddressList(id: any) {
            this.fieldDetails.addressId = null;
            this.getUserFarmListAsync(id);
        },
        handleOwnerChange(ownerId) {
            const polygonOptions = ConstantValues.defaultDrawingManagerPolygonOptions;
            const owner = this.fieldOwnerList.find(owner => owner.id === ownerId);
            if (!this.drawingManager && !this.fieldDetails?.id) {
                this.initializeDrawingManager({...polygonOptions, strokeColor: owner.color, fillColor: owner.color});
            } else if (ownerId){
                this.tempFieldPolygon?.setOptions({...polygonOptions, strokeColor: owner.color, fillColor: owner.color})
            } else {
                this.updateMapToAddField();
            }
            this.updateAddressList(ownerId);
            this.$store.commit('clearMapAlertMessage');
            this.$store.commit('clearAlertMessageListener');
        },
        addField() {
            const fieldDto = {
                title: this.fieldDetails.title,
                crop:
                this.fieldDetails.name != null && this.fieldDetails.fieldYear != null
                    ? {
                        name: this.fieldDetails.name,
                        year: this.fieldDetails.fieldYear,
                    }
                    : null,
                addressId: this.fieldDetails.addressId,
                ownership: this.fieldDetails.ownership,
                areaInSquareMeter: this.fieldDetails.areaInSquareMeter,
                polygon: this.fieldCordList,
                ownerId: this.fieldDetails.owner,
                note: this.fieldDetails.note,
            };
            this.addNewFieldAsync([fieldDto, this.fieldDetails.owner])
            .then(() => {
                this.cleanUpMapAndFieldFormAfterAction();
            });
        },
        updateField() {
            const fieldDto = {
                title: this.fieldDetails.title,
                crop:
                this.fieldDetails.name != null && this.fieldDetails.fieldYear != null
                    ? {
                        name: this.fieldDetails.name,
                        year: this.fieldDetails.fieldYear,
                    }
                    : null,
                addressId: this.fieldDetails.addressId,
                ownership: this.fieldDetails.ownership,
                areaInSquareMeter: this.areaInSquareMeter || this.fieldDetails.areaInSquareMeter,
                polygon: this.fieldCordList,
                ownerId: this.fieldDetails.owner,
                note: this.fieldDetails.note,
            };

            this.updateFieldDetailsAsync([fieldDto, this.fieldDetails]).then((res) => {
                if (res !== undefined) {
                    this.files = null;
                    this.cleanUpMapAndFieldFormAfterAction();
                }
            });
        },
        toggleFieldsEditable() {
            if (!this.fieldReadonly) {
                this.editingAreaOnMap = !this.showEditableFields;
            }
            this.showEditableFields = !this.showEditableFields;
        },
        cancelDrawerAction() {
            this.$store.dispatch('revertTempPolygon');
            history.back();
        }
    }
}
