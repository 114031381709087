import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import { MinimalUser } from '@/data/models/MinimalUser';
import { TeamJobAddress } from '@/data/models/TeamJobAddress';
import { TeamJobComponentConfiguration } from '@/data/models/TeamJobComponentConfiguration';
import { TeamJobMetaData } from '@/data/models/TeamJobMetaData';
import { TeamJobOperation } from '@/data/models/TeamJobOperation';
import { TeamJobOperationBillingQuantity } from '@/data/models/TeamJobOperationBillingQuantity';
import { TeamJobProduct } from '@/data/models/TeamJobProduct';
import { TeamJobTimeDetails } from '@/data/models/TeamJobTimeDetails';
import { TeamJobViewPreference } from '@/data/models/TeamJobViewPreference';
import { ApprovalStatusType } from '@/enum/approvalStatusType';
import { JobAttachmentType } from '@/enum/jobAttachmentType';
import { MachineType } from '@/enum/machineType';
import { TeamJobOperatorChecklistResourceType } from '@/enum/teamJobOperatorChecklistResourceType';
import { TeamJobStatusType } from '@/enum/teamJobStatusType';
import { UserRoleType } from '@/enum/userRoleType';
import breadcrumbsMixin from '@/mixins/breadcrumbs-mixin';
import ConstantValues from '@/plugins/constantValues';
import signalR from '@/services/signalr';
import { apiOperations, apiUsers } from '@/utils/endpoints';
import { listContainsItem, textToHtml } from '@/utils/helpers';
import { createJobOperatorsForOperatorsView, getResourcesForAssignedOperators } from '@/utils/helpers/jobs-helpers';
import { getLoggedInUserRole, getOwnerId } from '@/utils/helpers/user-role-helpers';
import requests from '@/utils/requests';
import buildUrl from 'build-url';
import moment from 'moment';
import { mapMutations } from 'vuex';

export default {
    name: 'TeamJobsDetailsCommon',
    mixins: [TeamJobsComputed, breadcrumbsMixin],
    methods: {
        ...mapMutations({
            clearFilterOptions: "setClearFilterOptions",
        }),
        resetTeamJobs() {
            this.$store.commit('resetTeamJobs');
        },
        async mountTeamJobsDetails() {
            await this.$store.dispatch('clearOperatorMarkerOnMap');
            this.clearFilterOptions();
            this.resetTeamJobs();
            this.teamJobsLoader = true;
            this.hideLocationMap();
            this.hideTimeDetails();
            this.editableTeamUser();
            await this.$store.dispatch('initializeTeamJobsMap');
            await this.getTeamJobDetails();
        },
        enableTeamJobDiscard() {
            this.hasTeamJobChangesOnDetails = true;
        },
        disableTeamJobDiscard() {
            this.hasTeamJobChangesOnDetails = false;
        },
        shouldHideOperators(jobResponse) {
            if(jobResponse) {
                return getLoggedInUserRole().isUserOwnerFarmerLoggedIn && jobResponse.customer.id !== jobResponse.contractor.id && !jobResponse.contractor.isInternal;
            }
        },
        hideOperators() {
            const newViewConfiguration = new TeamJobComponentConfiguration(ConstantValues.setNotVisible, ConstantValues.setNotEditable, ConstantValues.setNotDetailed);
            this.teamJobViewPreference.operators = new TeamJobViewPreference().setOperatorsPreference(newViewConfiguration);
        },
        hideResources() {
            const newViewConfiguration = new TeamJobComponentConfiguration(ConstantValues.setNotVisible, ConstantValues.setNotEditable, ConstantValues.setNotDetailed);
            this.teamJobViewPreference.operators = new TeamJobViewPreference().setOperatorsPreference(newViewConfiguration);
            this.teamJobViewPreference.products = new TeamJobViewPreference().setProductsPreference(newViewConfiguration);
        },
        showResources() {
            const newViewConfiguration = new TeamJobComponentConfiguration(ConstantValues.setVisible, ConstantValues.setNotEditable, ConstantValues.setNotDetailed);
            this.teamJobViewPreference.operators = new TeamJobViewPreference().setOperatorsPreference(newViewConfiguration);

            const newProductsViewConfiguration = new TeamJobComponentConfiguration(ConstantValues.setVisible, ConstantValues.setEditable, ConstantValues.setNotDetailed);
            this.teamJobViewPreference.products = new TeamJobViewPreference().setProductsPreference(newProductsViewConfiguration);
        },
        showResourcesWithDetails() {
            const newResourcesViewConfiguration = new TeamJobComponentConfiguration(ConstantValues.setVisible, ConstantValues.setEditable, ConstantValues.setDetailed);
            this.teamJobViewPreference.operators = new TeamJobViewPreference().setOperatorsPreference(newResourcesViewConfiguration);
            this.teamJobViewPreference.products = new TeamJobViewPreference().setProductsPreference(newResourcesViewConfiguration);
        },
        editableTeamUser() {
            const newCustomerConfiguration = new TeamJobComponentConfiguration(ConstantValues.setVisible, ConstantValues.setEditable, ConstantValues.setNotDetailed);
            this.teamJobViewPreference.customer = new TeamJobViewPreference().setCustomerPreference(newCustomerConfiguration);
        },
        notEditableTeamUser() {
            const newCustomerConfiguration = new TeamJobComponentConfiguration(ConstantValues.setVisible, ConstantValues.setNotEditable, ConstantValues.setNotDetailed);
            this.teamJobViewPreference.customer = new TeamJobViewPreference().setCustomerPreference(newCustomerConfiguration);
        },
        hideLocationMap() {
            const newViewShowConfiguration = new TeamJobComponentConfiguration(ConstantValues.setNotVisible, ConstantValues.setNotEditable, ConstantValues.setNotDetailed);
            this.teamJobViewPreference.locationHistoryMap = new TeamJobViewPreference().setLocationHistoryMapPreference(newViewShowConfiguration);
        },
        hideTimeDetails() {
            const newViewShowTimeDetailsConfiguration = new TeamJobComponentConfiguration(ConstantValues.setNotVisible, ConstantValues.setNotEditable, ConstantValues.setNotDetailed);
            this.teamJobViewPreference.timeDetails = new TeamJobViewPreference().setTimeDetailsPreference(newViewShowTimeDetailsConfiguration);
        },
        showLocationMap() {
            const newViewConfiguration = new TeamJobComponentConfiguration(ConstantValues.setVisible, ConstantValues.setEditable, ConstantValues.setNotDetailed);
            this.teamJobViewPreference.locationHistoryMap = new TeamJobViewPreference().setLocationHistoryMapPreference(newViewConfiguration);
        },
        showTimeDetails() {
            const newViewTimeDetailsConfiguration = new TeamJobComponentConfiguration(ConstantValues.setVisible, ConstantValues.setEditable, ConstantValues.setNotDetailed);
            this.teamJobViewPreference.timeDetails = new TeamJobViewPreference().setTimeDetailsPreference(newViewTimeDetailsConfiguration);
        },
        showBillingQuantities() {
            const newJobBillingQuantityViewConfiguration = new TeamJobComponentConfiguration(ConstantValues.setVisible, ConstantValues.setEditable, ConstantValues.setNotDetailed);
            this.teamJobViewPreference.billingInformation = new TeamJobViewPreference().setBillingInformationPreference(newJobBillingQuantityViewConfiguration);
        },
        hideBillingQuantities() {
            const newJobBillingQuantityViewConfiguration = new TeamJobComponentConfiguration(ConstantValues.setNotVisible, ConstantValues.setNotEditable, ConstantValues.setNotDetailed);
            this.teamJobViewPreference.billingInformation = new TeamJobViewPreference().setBillingInformationPreference(newJobBillingQuantityViewConfiguration);
        },
        disableAllResources() {
            this.teamJobDisabledAllResources = true;
            const newJobDisabledViewConfiguration = new TeamJobViewPreference().getTeamJobViewPreferenceDisabled();
            this.teamJobViewPreference = newJobDisabledViewConfiguration;
        },
        setTeamJobsViewPreference(jobStatus) {
            //set status preference
            const newViewConfiguration = new TeamJobComponentConfiguration(ConstantValues.setVisible, ConstantValues.setEditable, ConstantValues.setNotDetailed);
            this.teamJobViewPreference.status = new TeamJobViewPreference().setStatusPreference(newViewConfiguration);
            //set operators preference

            //set job completion preference
            if (jobStatus >= TeamJobStatusType.AwaitingCompletion) {
                const newJobCompletionViewConfiguration = new TeamJobComponentConfiguration(ConstantValues.setVisible, ConstantValues.setEditable, ConstantValues.setNotDetailed);
                this.teamJobViewPreference.completionInformation = new TeamJobViewPreference().setCompletionInformationPreference(newJobCompletionViewConfiguration);
            } else {
                const newJobCompletionViewConfiguration = new TeamJobComponentConfiguration(ConstantValues.setNotVisible, ConstantValues.setNotEditable, ConstantValues.setNotDetailed);
                this.teamJobViewPreference.completionInformation = new TeamJobViewPreference().setCompletionInformationPreference(newJobCompletionViewConfiguration);
            }

            //set job billing quantities preference
            if (jobStatus >= TeamJobStatusType.InProgress) {
                this.showBillingQuantities();
            } else {
                this.hideBillingQuantities()
            }

        },

        unsubscribeOperators() {
            if (this.teamJobOperators.length != 0) {
                for (let i = 0; i < this.teamJobOperators.length; i++) {
                    signalR.fnUnSubscripbe(this.teamJobOperators[i].operatorDetails.id);
                }
            }
        },

        async getTeamJobUserEditable(jobResponse) {
            const url = buildUrl(this.$store.state.baseUrl, {
                path: apiUsers + '/' + jobResponse?.creatorId
            });
            const creatorIdOwnerResponse = await requests.getData(url);

            if(creatorIdOwnerResponse && creatorIdOwnerResponse.data) {
                const response = creatorIdOwnerResponse.data;
                const creatorOwnerId = response.ownerId;

                if(jobResponse.customer.isInternal || jobResponse.contractor.id === jobResponse.customer.id) {
                    this.editableTeamUser();
                }

                if ((creatorOwnerId !== this.getSenderId) || this.isFarmerLogInAndSelfJobAndJobStarted) {
                    this.notEditableTeamUser();
                }

                if (getLoggedInUserRole().isUserOwnerContractorLoggedIn && jobResponse.status == TeamJobStatusType.Pending){
                    this.disableAllResources();
                    this.hideLocationMap();
                    this.hideTimeDetails();
                }

                if(getLoggedInUserRole().isUserOwnerFarmerLoggedIn && jobResponse.customer.id !== jobResponse.contractor.id && !jobResponse.contractor.isInternal && jobResponse.status >= TeamJobStatusType.InProgress) {
                    this.disableAllResources();
                    if(jobResponse.status > TeamJobStatusType.InProgress){
                        this.showTimeDetails();
                       }
                    else{
                        this.hideTimeDetails();
                    }
                }
             }
        },

        async handleTeamJobOperationBinding(operationId, jobResponseOperation) {
            let operationResponse = null;

            const url = buildUrl(this.$store.state.baseUrl, {
                path: this.teamJobOperationOwnerId + '/' + apiOperations + '/' + operationId
            });
            operationResponse = await requests.getData(url);
            
            this.teamJobOperation = new TeamJobOperation().getTeamJobOperation(operationResponse?.data || jobResponseOperation);

            if (jobResponseOperation?.billingQuantities?.length) {
                this.teamJobOperation.billingQuantities = jobResponseOperation.billingQuantities.map(
                    billingQuantity => new TeamJobOperationBillingQuantity().getTeamJobOperationBillingQuantity(billingQuantity)
                )
            } else {
                this.teamJobOperation.billingQuantities = new Array(new TeamJobOperationBillingQuantity());
            }

            if(operationResponse?.data) {
                this.$store.commit('setTeamJobsOperations', [operationResponse.data]);

                if(operationResponse.data.vehicles.length) {
                    this.$store.commit('setTeamJobOperatorMachinesDropdown', {
                        machineType: MachineType.Vehicle, 
                        machinesList: operationResponse?.data?.vehicles || []
                    })
                }
    
                if(operationResponse.data.implements.length) {
                    this.$store.commit('setTeamJobOperatorMachinesDropdown', {
                        machineType: MachineType.Implement, 
                        machinesList: operationResponse?.data?.implements || []
                    })
                }
            } else {
                this.$store.commit('setTeamJobsOperations', [jobResponseOperation]);
            }
        },

        async getTeamJobDetailsRealtime() {
            if(!this.teamJobDetailsRealtime) return;
            
            const pageId = Math.random().toString(36).substr(2, 9);
            if (this.fieldRequesterId == null) {
                this.fieldRequesterId = pageId;
            }
            
            const jobResponse = this.teamJobDetailsRealtime
            const currentPageId = window.location.href.split('/').pop()
            const jobId = jobResponse.id

            if (currentPageId !== jobId) return;

            this.teamJobDetails.status = jobResponse.status;

            await this.getTeamJobUserEditable(jobResponse);

            //get owners
            if (jobResponse.status > TeamJobStatusType.Ready) {
                this.showLocationMap();
            }
            if (jobResponse.status > TeamJobStatusType.InProgress) {
                this.showTimeDetails();
            }
            if (jobResponse.status == TeamJobStatusType.InProgress) {
                this.$store.dispatch('subscribeJobOperators', jobResponse.operators);
            }
            if (!(getLoggedInUserRole().isUserOwnerFarmerLoggedIn && getOwnerId() != jobResponse.contractor.id) && (this.teamJobDetails.contractorApprovalStatus != ApprovalStatusType.Pending)) {
                this.showResourcesWithDetails();
            } else {
                this.hideResources();
            }

            //contractor logged in
            if (getLoggedInUserRole().isContractorLoggedIn || getLoggedInUserRole().isContractorsManagerLoggedIn) {
                this.teamJobOwnerType = UserRoleType.Farmer;
                this.teamJobOperationOwnerId = getOwnerId();
            }

            if (getLoggedInUserRole().isFarmerLoggedIn || getLoggedInUserRole().isFarmersManagerLoggedIn) {
                this.teamJobOwnerType = UserRoleType.Contractor;
                this.teamJobOperationOwnerId = jobResponse.contractor.id;
            }
            
            //team job owners list
            let user = null;
            if (getLoggedInUserRole().isContractorLoggedIn || getLoggedInUserRole().isContractorsManagerLoggedIn) {
                if(jobResponse.customer && jobResponse.customer.id) {
                    user = this.teamJobUsersList.find(customer => customer.id == jobResponse.customer.id);
                }
                if (user) {
                    this.teamJobUser = new MinimalUser().getMinimalUser(user)
                }
                else {
                    if (!listContainsItem(this.teamJobUsersList, ['id'], jobResponse.customer.id)) {
                        this.teamJobUsersList.push(new MinimalUser().getMinimalUser(jobResponse.customer));
                    }
                    this.teamJobUser = new MinimalUser().getMinimalUser(jobResponse.customer);
                }
            }
            if (getLoggedInUserRole().isFarmerLoggedIn || getLoggedInUserRole().isFarmersManagerLoggedIn) {
                user = this.teamJobUsersList.find(contractor => contractor.id == jobResponse.contractor.id);
                if (user) {
                    this.teamJobUser = new MinimalUser().getMinimalUser(user)
                }
                else {
                    if (!listContainsItem(this.teamJobUsersList, ['id'], jobResponse.contractor.id)) {
                        this.teamJobUsersList.push(new MinimalUser().getMinimalUser(jobResponse.contractor));
                    }
                    this.teamJobUser = new MinimalUser().getMinimalUser(jobResponse.contractor);
                }
            }

            //Scheduled date
            if (jobResponse.scheduledAt) {
                this.teamJobScheduledDate = moment.utc(jobResponse.scheduledAt).local().format();
            }

            //operations
            await this.$store.dispatch('getTeamJobOperations').then( async () => {
                await this.handleTeamJobOperationBinding(jobResponse.operation.id, jobResponse.operation);
            });
            
            //Add instructions
            if (jobResponse.instructions) {
                this.teamJobInstructions = textToHtml(jobResponse.instructions);
                this.tempTeamJobInstructions = textToHtml(jobResponse.instructions);
            }
            //attachements
            if (jobResponse.attachments) {
                this.teamJobAttachments = [];
                jobResponse.attachments.map(a => (this.teamJobAttachments.push(a)));
            }

            // Meta info
            if (jobResponse.metaData) {
                this.teamJobMetaDataList = [];
                jobResponse.metaData.map(m => (this.teamJobMetaDataList.push(new TeamJobMetaData().getTeamJobMetaData(m))));
            }

            // update operators
            const jobResponseOperators = jobResponse.operators;

            //set operators
            this.teamJobOperators = createJobOperatorsForOperatorsView(
                this.teamJobOperators,
                jobResponseOperators
            )

            //set resources for assigned operators
            getResourcesForAssignedOperators(
                jobResponseOperators,
                this.teamJobVehiclesListForOperators,
                TeamJobOperatorChecklistResourceType.Vehicle
            );

            getResourcesForAssignedOperators(
                jobResponseOperators,
                this.teamJobImplementsListForOperators,
                TeamJobOperatorChecklistResourceType.Implement
            );

            //time records
            if (jobResponse.timeRecords) {
                this.teamJobTimeDetailsList = [];
                this.teamJobTempTimeDetailsList = [];
                jobResponse.timeRecords.map(t => (this.teamJobTimeDetailsList.push(new TeamJobTimeDetails().getTeamJobTimeDetails(t))));
                jobResponse.timeRecords.map(t => (this.teamJobTempTimeDetailsList.push(new TeamJobTimeDetails().getTeamJobTimeDetails(t))));
            }

            //products
            this.$store.commit('setClearFilterOptions');
            this.teamJobProducts = jobResponse.products.map((p) => new TeamJobProduct().getTeamJobProduct(p))

            //addresses
            this.teamJobAddresses = [];
            const responseAddresses = jobResponse.addresses;

            if (responseAddresses && responseAddresses) {
                responseAddresses.forEach((a) => {
                    this.teamJobAddresses.push(new TeamJobAddress().getTeamJobAddress(a))
                })
            }

            //team job notes and photos
            this.teamJobNotes = textToHtml(jobResponse.notes);
            this.tempTeamJobNotes = textToHtml(jobResponse.notes);

            this.setTeamJobsViewPreference(jobResponse.status);

            this.teamJobInvoiced = jobResponse.isInvoiced || jobResponse.isApprovedForInvoicing;

            if (this.teamJobInvoiced) {
                this.disableAllResources();
            }

            if(this.shouldHideOperators(jobResponse)) {
                this.hideOperators();
            }
                  
        },

        async getTeamJobDetails() {
            this.dashboardTitle = this.$stringConstants('jobsTitle');

            const pageId = Math.random().toString(36).substr(2, 9);
            if (this.fieldRequesterId == null) {
                this.fieldRequesterId = pageId;
            }
            await this.$store.dispatch('getTeamJobDetails', this.jobResourceId).then(async (jobResponse) => {
                if (jobResponse) {
                    if (jobResponse.number) {
                        this.setBreadcrumbs('TeamJobsDetails', jobResponse.number);
                    }
                    else {
                        this.setBreadcrumbs('TeamJobsDetails', 'Jobs');
                    }

                    await this.getTeamJobUserEditable(jobResponse);

                    //get owners
                    if (jobResponse.status > TeamJobStatusType.Ready) {
                        this.showLocationMap();
                    }
                    if (jobResponse.status > TeamJobStatusType.InProgress) {
                        this.showTimeDetails();
                    }
                    if (jobResponse.status == TeamJobStatusType.InProgress) {
                        this.$store.dispatch('subscribeJobOperators', jobResponse.operators);
                    }
                    if (!(getLoggedInUserRole().isUserOwnerFarmerLoggedIn && getOwnerId() != jobResponse.contractor.id) && (this.teamJobDetails.contractorApprovalStatus != ApprovalStatusType.Pending)) {
                        this.showResourcesWithDetails();

                    } else {
                        this.hideResources();
                    }

                    //Add Instructions
                    if (jobResponse.instructions) {
                        this.teamJobInstructions = textToHtml(jobResponse.instructions);
                        this.tempTeamJobInstructions = textToHtml(jobResponse.instructions);
                    }
                    //Attachements
                    if (jobResponse.attachments) {
                        jobResponse.attachments.map(x => (this.teamJobAttachments.push(x)));
                    }

                    //draw Job fields
                    let callbackFn

                    if (jobResponse.fields.length != 0) {
                        callbackFn = async () => {
                            await jobResponse.fields.map(async (f) => {
                                await this.$store.dispatch('storeSelectedFieldOnTeamJobsMap', f)
                            })
                            await this.$store.dispatch('drawFieldsGeoJsonOnLocationMap', jobResponse.fields)
                            await this.$store.dispatch('highlightFieldsAndSetMapBound', jobResponse.fields.map(field => field.id))
                            await this.$store.dispatch('drawFieldsOnLocationMap', jobResponse.fields)
                        }
                    }

                    await this.$store.dispatch('getTeamJobFields', callbackFn);

                    this.getTeamJobMinimalDetails(jobResponse).then(async () => {
                        if (jobResponse.operators.length != 0) {
                            this.teamJobLocationSelectedOperatorId = jobResponse.operators[0].id;
                            await this.$store.dispatch('clearOperatorPolylines');
                            await this.$store.dispatch('getOperatorLocationHistory', [this.jobResourceId, this.teamJobLocationSelectedOperatorId]).then(async (res) => {
                                await this.$store.dispatch('drawSelectedOperatorPolylines');
                            });
                        }
                    });

                    // Meta Info
                    if (jobResponse.metaData) {
                        jobResponse.metaData.map(x => (this.teamJobMetaDataList.push(new TeamJobMetaData().getTeamJobMetaData(x))));
                    }

                    //time Details
                    if (jobResponse.timeRecords) {
                        jobResponse.timeRecords.map(x => (this.teamJobTimeDetailsList.push(new TeamJobTimeDetails().getTeamJobTimeDetails(x))));
                        jobResponse.timeRecords.map(x => (this.teamJobTempTimeDetailsList.push(new TeamJobTimeDetails().getTeamJobTimeDetails(x))));
                    }

                    //products
                    this.$store.commit('setClearFilterOptions');
                    await this.$store.dispatch('getTeamJobProducts').then(() => {
                        this.teamJobProducts = jobResponse.products.map((product) => new TeamJobProduct().getTeamJobProduct(product))
                    });

                    //addresses
                    await this.$store.dispatch('getTeamJobAddresses').then(() => {
                        this.teamJobAddresses = [];
                        const responseAddresses = jobResponse.addresses;

                        if (responseAddresses && responseAddresses) {
                            responseAddresses.forEach((addr) => {
                                this.teamJobAddresses.push(new TeamJobAddress().getTeamJobAddress(addr))
                            })
                        }
                    });

                    //team job notes and photos
                    this.teamJobNotes = textToHtml(jobResponse.notes);
                    this.tempTeamJobNotes = textToHtml(jobResponse.notes);

                    this.setTeamJobsViewPreference(jobResponse.status);

                    this.teamJobInvoiced = jobResponse.isInvoiced || jobResponse.isApprovedForInvoicing;

                    if (this.teamJobInvoiced) {
                        this.disableAllResources();
                    }
                }
            });
            this.teamJobsLoader = false;
        },

        async getTeamJobMinimalDetails(jobResponse) {
            //contractor logged in
            if (getLoggedInUserRole().isContractorLoggedIn || getLoggedInUserRole().isContractorsManagerLoggedIn) {
                this.teamJobOwnerType = UserRoleType.Farmer;
                this.teamJobOperationOwnerId = getOwnerId();
            }

            if (getLoggedInUserRole().isFarmerLoggedIn || getLoggedInUserRole().isFarmersManagerLoggedIn) {
                this.teamJobOwnerType = UserRoleType.Contractor;
                this.teamJobOperationOwnerId = jobResponse.contractor.id;
            }
            //team job owners list
            await this.$store.dispatch('getTeamJobsOwners').then((res) => {
                let user = null;
                if (getLoggedInUserRole().isContractorLoggedIn || getLoggedInUserRole().isContractorsManagerLoggedIn) {
                    user = this.teamJobUsersList.find(customer => customer.id == jobResponse.customer.id);
                    if (user) {
                        this.teamJobUser = new MinimalUser().getMinimalUser(user)
                    }
                    else {
                        if (!listContainsItem(this.teamJobUsersList, ['id'], jobResponse.customer.id)) {
                            this.teamJobUsersList.push(new MinimalUser().getMinimalUser(jobResponse.customer));
                        }
                        this.teamJobUser = new MinimalUser().getMinimalUser(jobResponse.customer);
                    }
                }
                if (getLoggedInUserRole().isFarmerLoggedIn || getLoggedInUserRole().isFarmersManagerLoggedIn) {
                    user = this.teamJobUsersList.find(contractor => contractor.id == jobResponse.contractor.id);
                    if (user) {
                        this.teamJobUser = new MinimalUser().getMinimalUser(user)
                    }
                    else {
                        if (!listContainsItem(this.teamJobUsersList, ['id'], jobResponse.contractor.id)) {
                            this.teamJobUsersList.push(new MinimalUser().getMinimalUser(jobResponse.contractor));
                        }
                        this.teamJobUser = new MinimalUser().getMinimalUser(jobResponse.contractor);
                    }
                }
            });

            await this.$store.dispatch('getTeamJobTeamMembers').then(async () => {
                this.$store.commit('setClearFilterOptions');
                await this.$store.dispatch('getTeamJobVehiclesList').then(async () => {
                    this.$store.commit('setClearFilterOptions');
                    await this.$store.dispatch('getTeamJobImplementsList').then(() => {

                        const jobResponseOperators = jobResponse.operators;

                        //set operators
                        this.teamJobOperators = createJobOperatorsForOperatorsView(
                            this.teamJobOperators,
                            jobResponseOperators
                        )

                        //set resources for assigned operators
                        getResourcesForAssignedOperators(
                            jobResponseOperators,
                            this.teamJobVehiclesListForOperators,
                            TeamJobOperatorChecklistResourceType.Vehicle
                        );

                        getResourcesForAssignedOperators(
                            jobResponseOperators,
                            this.teamJobImplementsListForOperators,
                            TeamJobOperatorChecklistResourceType.Implement
                        );

                    });
                });
            });

            //Scheduled Date
            if (jobResponse.scheduledAt) {
                this.teamJobScheduledDate = moment.utc(jobResponse.scheduledAt).local().format();
            }

            //operations
            await this.$store.dispatch('getTeamJobOperations').then( async () => {
                await this.handleTeamJobOperationBinding(jobResponse.operation.id, jobResponse.operation);
            });

            if(this.shouldHideOperators(jobResponse)) {
                this.hideOperators();
            }
        },

        isPdfFile(fileUrl: string) {
            return (fileUrl && fileUrl.includes('.pdf'));
        },

        getAttachmentFileType(file) {
            let fileType = null;

            if (file.type == "application/pdf") {  
                fileType = JobAttachmentType.Pdf
            }

            if (file.type.split('/')[0] === 'image') {
                fileType = JobAttachmentType.Image
            }
            
           return fileType;
        }
    }
}