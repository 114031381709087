
import { Field } from '@/data/models';
import ConstantValues from '@/plugins/constantValues';
import { listContainsItem } from '@/utils/helpers';
export const state = {
    fieldLoader: false,
    fieldFilterList: [],
    fieldSearchText: '',
    fieldFarmerSearchText: '',
    fieldFarmerSearchTextInternal: '',
    fieldCordList: [],
    fieldOwnerList: [],
    fieldOwnerFilterList: [],
    fieldFarmList: [],
    fieldListOwnerId: null,
    yearList: [],
    fieldDetails: {
        id: null,
        title: null,
        name: null,
        address: null,
        addressId: null,
        ownership: null,
        colour: null,
        areaInSquareMeter: null,
        note: null,
        polygon: null,
        fieldYear: null,
        owner: null,
        ownerDetails: null,
        cropHistory: [],
        shapeIndex: null
    },
    map: null,
    areaInvalid: false,
    addFieldLoader: false,
    editFieldLoader: false,
    colorUpdateOnMap: false,
    polygonShape: null,
    polyPath: null,
    fieldReadonly: false,
    bulkUploadInProgress: false,
    fieldOwnerListForBulkUpload: [],
    bulkUploadOwner: null,
    compressedFile: null,
    areaInSquareMeter: null,
    drawingManager: null,
    polygon: null,
    convertedArea: 0,
    mountedGMaps: false,
    oldOwnerId: null,
    fieldsListMapOffset: null,
    fieldsListMapLoaded: null,
    fieldsListMapTotal: null,
    fieldsListMapLoader: false,
    fieldsListMapData: [],
    fieldsListMap: null,
    fieldsInfoWindowContent: null,
    fieldsListFieldShapes: [],
    fieldsListFieldShapesReadOny: [],
    fieldsListMapLabels: [],
    computedAreas: [],
    fieldsListForSearchReadOnly: [],
    fieldSearchTextReadOnly: '',
    fieldSearchResultsReadOnly: [],
    fieldsListMapLabelsReadOnly: [],
    highlightedFields: [],
    showEditableFields: false,
    fieldsTotalCount: 0,
    fieldCropNameToAdd: '',
    fieldCropYearToAdd: null,
    fieldInProgressJobs: [],
    fieldStatus: 0,
    userIsFarmerOrFarmerManager: false,
    highlightedFieldsListMapData: [],
    fieldsCustomersFilterDropdownLoader: false,
    fieldOwnerListSize: 0,
    tempFieldPolygon: null,
    showFieldsList: true,
    totalFieldsCount: 0,
    ownersMapData: new Map(),
    infoWindowData: {
        fieldColor: "",
        fieldName: "",
        fieldArea: "",
        fieldCrop: "",
        fieldCustomer: "",
        fieldId: "",
        ownerId: "",
        fieldEditable: ""
    },
    addingField: false,
    editingField: false,
    editingAreaOnMap: false,
    fieldsMountedOnMap: null,
    mapAlertMessage: null,
    mapAlertListener: null,
    mapTriggeredEditFieldData: null,
    placesSearchResults: [],
    placesSearchQuery: null,
    formattedFields: [],
    tempFeature: null
}

export const getters = {
    fieldLoader: state => state.fieldLoader,
    fieldSearchText: state => state.fieldSearchText,
    fieldFarmerSearchText: state => state.fieldFarmerSearchText,
    fieldFilterList: state => state.fieldFilterList,
    fieldDetails: state => state.fieldDetails,
    fieldCordList: state => state.fieldCordList,
    areaInvalid: state => state.areaInvalid,
    fieldOwnerList: state => state.fieldOwnerList,
    fieldOwnerFilterList: state => state.fieldOwnerFilterList,
    fieldFarmList: state => state.fieldFarmList,
    fieldListOwnerId: state => state.fieldListOwnerId,
    
    yearList: state => state.yearList,
    SearchEnter: state => state.SearchEnter,
    fieldFarmerSearchTextInternal: state => state.fieldFarmerSearchTextInternal,
    addFieldLoader: state => state.addFieldLoader,
    editFieldLoader: state => state.editFieldLoader,
    polygonShape: state => state.polygonShape,
    polyPath: state => state.polyPath,
    fieldReadonly: state => state.fieldReadonly,
    bulkUploadInProgress: state => state.bulkUploadInProgress,
    fieldOwnerListForBulkUpload: state => state.fieldOwnerListForBulkUpload,
    bulkUploadOwner: state => state.bulkUploadOwner,
    compressedFile: state => state.compressedFile,
    areaInSquareMeter: state => state.areaInSquareMeter,
    drawingManager: state => state.drawingManager,
    polygon: state => state.polygon,
    convertedArea: state => state.convertedArea,
    mountedGMaps: state => state.mountedGMaps,
    oldOwnerId: state => state.oldOwnerId,
    fieldsListMapOffset: state => state.fieldsListMapOffset,
    fieldsListMapLoaded: state => state.fieldsListMapLoaded,
    fieldsListMapTotal: state => state.fieldsListMapTotal,
    fieldsListMapLoader: state => state.fieldsListMapLoader,
    fieldsListMapData: state => state.fieldsListMapData,
    fieldsListMap: state => state.fieldsListMap,
    fieldsInfoWindowContent: state => state.fieldsInfoWindowContent,
    fieldsListFieldShapes: state => state.fieldsListFieldShapes,
    fieldsListMapLabels: state => state.fieldsListMapLabels,
    computedAreas: state => state.computedAreas,
    fieldsListFieldShapesReadOny: state => state.fieldsListFieldShapesReadOny,
    fieldsListForSearchReadOnly: state => state.fieldsListForSearchReadOnly,
    fieldSearchTextReadOnly: state => state.fieldSearchTextReadOnly,
    fieldSearchResultsReadOnly: state => state.fieldSearchResultsReadOnly,
    fieldsListMapLabelsReadOnly: state => state.fieldsListMapLabelsReadOnly,
    highlightedFields: state => state.highlightedFields,
    showEditableFields: state => state.showEditableFields,
    fieldsTotalCount: state => state.fieldsTotalCount,
    fieldCropNameToAdd: state => state.fieldCropNameToAdd,
    fieldCropYearToAdd: state => state.fieldCropYearToAdd,
    fieldInProgressJobs: state => state.fieldInProgressJobs,
    fieldStatus: state => state.fieldStatus,
    userIsFarmerOrFarmerManager: state => state.userIsFarmerOrFarmerManager,
    highlightedFieldsListMapData: state => state.highlightedFieldsListMapData,
    fieldsCustomersFilterDropdownLoader: state => state.fieldsCustomersFilterDropdownLoader,
    fieldOwnerListSize: state => state.fieldOwnerListSize,
    tempFieldPolygon: state => state.tempFieldPolygon,
    showFieldsList: state => state.showFieldsList,
    totalFieldsCount: state => state.totalFieldsCount,
    editingAreaOnMap: state => state.editingAreaOnMap,
    fieldsMountedOnMap: state => state.fieldsMountedOnMap,
    mapAlertMessage: state => state.mapAlertMessage,
    mapTriggeredEditFieldData: state => state.mapTriggeredEditFieldData,
    placesSearchResults: state => state.placesSearchResults,
    placesSearchQuery: state => state.placesSearchQuery,
    formattedFields: state => state.formattedFields,
    tempFeature: state => state.tempFeature,
}

export const mutations = {
    setFieldsListMapData(state, data) {
        data.map((data) => {
            const newObj = new Field(data);
            state.fieldsListMapData.push(newObj);
            if (!state.ownersMapData.has(newObj.owner.id)) {
                state.ownersMapData.set(newObj.owner.id, newObj.colour);
            }
         })
    },
    setFieldsLoadedCounter(state, _) {
        state.fieldsListMapLoaded = state.fieldsListMapData.length
        state.fieldsListMapOffset = state.fieldsListMapLoaded
    },
    setFieldFilteredList(state, data: Array<Field>) {
        data.forEach(field => state.fieldFilterList.push(new Field(field)));
    },
    appendFieldToStartOfFieldFilteredList(state, data: Field) {
        const field = new Field(data);
        if (state.fieldFilterList?.length == 0) {
            state.fieldFilterList.push(field)
        } else {
            state.fieldFilterList.unshift(field)
        }
    },
    updateFieldInFieldFilteredList(state, data: Field) {
        const updatedField = new Field(data);
        if (listContainsItem(state.fieldFilterList, [ConstantValues.defaultObjectIdentifier], updatedField.id)) {
            const staleFieldIndex = state.fieldFilterList.findIndex(field => field.id === updatedField.id);
            state.fieldFilterList.splice(staleFieldIndex, 1, updatedField);
        }
    },
    setFieldDetails(state, data) {
        state.fieldDetails['id'] = data.id;
        state.fieldDetails['title'] = data.title;
        state.fieldDetails['name'] = data.crop != null ? data.crop.name : null;
        state.fieldDetails['fieldYear'] = data.crop != null ? data.crop.year : null;
        if (data.address != null) {
            state.fieldDetails['addressId'] = data.address.id;
        }
        state.fieldDetails['ownership'] = data.ownership;
        state.fieldDetails['note'] = data.note;
        state.fieldDetails['owner'] = data.owner.id;
        state.fieldDetails['ownerDetails'] = data.owner;
        state.fieldDetails['colour'] = data.colour;
        state.fieldDetails['areaInSquareMeter'] = data.areaInSquareMeter;
        state.fieldDetails['polygon'] = data.polygon;
        state.fieldDetails['cropHistory'] = data.cropHistory;
        state.fieldDetails['address'] = data.address;
        state.fieldDetails['shapeIndex'] = data?.shapeIndex;
        state.fieldCordList = data.polygon;
        state.oldOwnerId = data.owner.id;
    },
    setAddFieldCord(state, data) {
        if (data == null) {
            state.fieldCordList = [];
        }
        else {
            const Cord = {
                "latitude": data.latitude,
                "longitude": data.longitude
            }
            state.fieldCordList.push(Cord);
        }
    },
    setOwnerList(state, data) {
        data.map((data) => {
            if (data.businessProfile != null) {
                const owner = {
                    firstName: data.businessProfile.title,
                    id: data.id,
                    isInternal: data.isInternal,
                    color: data.colorCode
                }

                if(!listContainsItem(state.fieldOwnerList, [ConstantValues.defaultObjectIdentifier], owner.id)) {
                    state.fieldOwnerList.push(owner);
                }

                if(!listContainsItem(state.fieldOwnerFilterList, [ConstantValues.defaultObjectIdentifier], owner.id)) {
                    state.fieldOwnerFilterList.push(owner);
                }
            }
        });
    },
    setOwnerListSize(state, data) {
        state.fieldOwnerListSize = data;
    },
    setOwnerListBulkUpload(state, data) {
        data.map((data) => {
            if (data.businessProfile != null) {
                const owner = {
                    firstName: data.businessProfile.title,
                    id: data.id
                }
                state.fieldOwnerListForBulkUpload.push(owner);
            }
        });
    },
    setCurrentOwnerToOwnerList(state, data) {
        if (data.businessProfile != null) {
            const owner = {
                firstName: data.businessProfile.title,
                id: data.id,
                isInternal: data.isInternal
            }
            state.fieldOwnerList.push(owner);
            state.fieldOwnerFilterList.push(owner);
        }
    },
    setDefaultOwnerList(state, data) {
        const owner = {
            firstName: (data ? data : ((localStorage.getItem('fullOwnerBusinessName') ? localStorage.getItem('fullOwnerBusinessName') : ''))) + " (You)",
            id: localStorage.getItem(ConstantValues.ownerId),
            color: localStorage.getItem(ConstantValues.ownerColor) !== "null" ? localStorage.getItem(ConstantValues.ownerColor) : ConstantValues.defaultPolygonStrokeColor
        }
        if(!listContainsItem(state.fieldOwnerList,  [ConstantValues.defaultObjectIdentifier], owner.id)) {
            state.fieldOwnerList.push(owner);
        }
    },
    setDefaultOwnerListBulkUpload(state, data) {
        const owner = {
            firstName: (data ? data : ((localStorage.getItem('fullOwnerBusinessName') ? localStorage.getItem('fullOwnerBusinessName') : ''))) + " (You)",
            id: localStorage.getItem(ConstantValues.ownerId)
        }
        
        if(!listContainsItem(state.fieldOwnerListForBulkUpload,  [ConstantValues.defaultObjectIdentifier], owner.id)) {
            state.fieldOwnerListForBulkUpload.push(owner);
        }
    },
    setDefaultOwnerFilterList(state, data) {
        if (data) {
            const defItem = [
                {
                    firstName: "All",
                    id: "All"
                },
                {
                    firstName: data + " (You)",
                    id: localStorage.getItem(ConstantValues.ownerId)
                }
            ]
            state.fieldOwnerFilterList = defItem;
        } else {
            if ((localStorage.getItem('ownerRole') == 'Contractor') &&
                (localStorage.getItem('userRole') == '2')) {
                const defItem = [
                    {
                        firstName: "All",
                        id: "All"
                    },
                    {
                        firstName: localStorage.getItem('fullOwnerBusinessName'),
                        id: localStorage.getItem(ConstantValues.ownerId)
                    }
                ]
                state.fieldOwnerFilterList = defItem;
            }
        }
    },
    setFieldFarmList(state, data) {
        data.map((data) => state.fieldFarmList.push(data));
        const addr = {
            addressLine1: "None",
            id: null
        }
        state.fieldFarmList.push(addr);
    },
    clearFieldFarmList(state) {
        state.fieldFarmList = [];
    },
    setYearList(state, data) {
        state.yearList.push(data);
    },
    setFieldsListForSearchReadOnly(state, data) {
        state.fieldsListForSearchReadOnly = []

        if (data && data.length > 0) {
            data.map(field => {
                if (field.field.polygon) {
                    state.fieldsListForSearchReadOnly.push({
                        title: field.field.title ? field.field.title : '',
                        customer: field.customer ? field.customer : '',
                        field: field.field
                    })
                }
            })
        }
    },
    setJobsByField(state, fieldJobs) {
        state.fieldInProgressJobs = fieldJobs;
    },
    clearFieldsPolygons(state) {
        const fieldIndex = state.highlightedFieldsListMapData.findIndex(field => field.id === state.fieldDetails.id);
        state.polyPath?.setMap(null);
        state.polygon?.setMap(null);
        state.tempFieldPolygon?.setMap(null);
        state.tempFieldPolygon = null;
        state.highlightedFields[fieldIndex]?.setMap(null);
    },
    resetFieldDetails(state) {
        state.tempFieldPolygon = null;
        state.fieldDetails['id'] = null;
        state.fieldDetails['title'] = null;
        state.fieldDetails['name'] = null;
        state.fieldDetails['fieldYear'] = null;
        state.fieldDetails['addressId'] = null;
        state.fieldDetails['address'] = null;
        state.fieldDetails['ownership'] = null;
        state.fieldDetails['colour'] = null;
        state.fieldDetails['areaInSquareMeter'] = null;
        state.fieldDetails['polygon'] = null;
        state.fieldDetails['owner'] = null;
        state.fieldDetails['ownerDetails'] = null;
        state.fieldDetails['note'] = null;
        state.fieldDetails['cropHistory'] = [];
        state.fieldCordList = [];
        state.areaInSquareMeter = null;
        state.areaInvalid = false;
        state.addFieldLoader = false;
        state.editFieldLoader = false;
        state.colorUpdateOnMap = false;
        state.polygonShape = null;
        state.polyPath = null;
        state.bulkUploadInProgress = false;
        state.drawingManager?.setDrawingMode(null);
        state.drawingManager?.setMap(null);
        state.drawingManager = null;
        state.polygon?.setEditable(false);
        state.polygon = null;
        state.convertedArea = 0;
        state.fieldsCustomersFilterDropdownLoader = false;
        state.placesSearchResults = [];
        state.placesSearchQuery = null;
    },
    setHighlightedFieldsListMapData(state, highlightedFields) {
        highlightedFields.map(field => state.highlightedFieldsListMapData.push(field));
    },
    setInfoWindowData(state, field) {
        state.infoWindowData = field
    },
    setShowFieldsList(state, value: boolean) {
        state.showFieldsList = value;
    },
    addingField(state, value) {
        state.addingField = value;
    },
    editingField(state, value) {
        state.editingField = value;
    },
    turnoffDrawingManager(state) {
        state.drawingManager?.setDrawingMode(null);
        state.drawingManager?.setMap(null);
        state.drawingManager = null;
    },
    setTempPolygons(state, fieldPolygon) {
        // state.tempFieldPolygon = fieldPolygon;
        state.polygon = fieldPolygon;
    },
    clearFieldsListMapData() {
        state.fieldsListMapData = []
    },
    clearFieldsListFieldShapes() {
        state.fieldsListFieldShapes.forEach(shape => shape.setMap(null));
        state.fieldsListFieldShapes = []
    },
    clearHighlightedFieldsListMapData() {
        state.highlightedFieldsListMapData = []
    },
    setMapAlertMessage(state, message: string) {
        state.mapAlertMessage = message
    },
    clearMapAlertMessage(state) {
        state.mapAlertMessage = null
    },
    setMapAlertListener(state, listener) {
        state.mapAlertListener = listener;
    },
    clearAlertMessageListener(state) {
        const {google} = window;
        google.maps.event.removeListener(state.mapAlertListener)
    },
    setMapTriggeredEditFieldData(state, field) {
        state.mapTriggeredEditFieldData = field
    }
 }