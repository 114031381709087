import { render, staticRenderFns } from "./team-jobs-time-details.vue?vue&type=template&id=b80f1030&scoped=true"
import script from "./team-jobs-time-details.vue?vue&type=script&lang=ts"
export * from "./team-jobs-time-details.vue?vue&type=script&lang=ts"
import style0 from "./team-jobs-time-details.vue?vue&type=style&index=0&id=b80f1030&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b80f1030",
  null
  
)

export default component.exports