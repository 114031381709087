import Vue from 'vue'
import FieldInfoWindow from '@/components/fields/field-info-window.vue'
const fieldInfoWindowConstructor = Vue.extend(FieldInfoWindow)

const DEFAULT_STROKE_COLOR = '#FFFFFF'
const DEFAULT_STROKE_WEIGHT = 3
const STRONG_STROKE_WEIGHT = 4
const DEFAULT_FILL_OPACITY = 0.5
const SUPPORTED_LINE_STRINGS = ['LineString', 'MultiLineString']

let featureLayerEventListeners = []

export const colorizeFeature = (color: string, feature: google.maps.Data.Feature, featureLayer: google.maps.Data) => {
  featureLayer.overrideStyle(feature, {
    fillColor: color,
    fillOpacity: DEFAULT_FILL_OPACITY,
    strokeColor: color,
    strokeWeight: DEFAULT_STROKE_WEIGHT,
  })
}

export const createFeatureData = (feature: any) => {
  const featureData: any = {
    "type": feature.type,
    "id": feature.id,
    "properties": feature.properties,
    "geometry": feature.geometry
  }

  return featureData
}

export const getFeatureById = (featureLayer: google.maps.Data, id: string) => {
  return featureLayer.getFeatureById(id)
}

export const addGeoJsonFeatureOnMap = (feature: any, featureLayer: google.maps.Data, field?: any) => {
  let features = feature

  if (field) {
    feature.properties = {
      ...feature.properties,
      ...field
    }
  }
  
  const featureData = createFeatureData(feature)
  features = featureLayer.addGeoJson(featureData)
    
  if (features) colorizeFeature(feature.properties.colour, features?.[0], featureLayer)

  return features as google.maps.Data.Feature
}

export const addFunctionToMouseOverFeature = (featureLayer: google.maps.Data, mouseEvent: any, callback: (event: google.maps.Data.MouseEvent) => void) => {
  const eventName = mouseEvent ?? 'click'
  let listener = null
  if (featureLayer) {
    listener = featureLayer.addListener(eventName, (e: google.maps.Data.MouseEvent) => {
      callback(e)
    })
  }
}

export const createInfoWindowHTML = (feature) => {
  const fieldInfo = {
    fieldId: feature.id,
    fieldColor: feature.getProperty('fieldColor'),
    fieldName: feature.getProperty('fieldName'),
    fieldArea: feature.getProperty('fieldArea'),
    fieldCrop: feature.getProperty('fieldCrop'),
    fieldCustomer: feature.getProperty('fieldCustomer'),
    fieldEditable: feature.getProperty('fieldEditable'),
    fieldAddress:  feature.getProperty('fieldAddress')
  }

  const fieldInfoWindowComponent = new fieldInfoWindowConstructor({ propsData: { fieldInfo } })

  fieldInfoWindowComponent.$mount()
  const fieldInfoWindowString = new XMLSerializer().serializeToString(fieldInfoWindowComponent.$el)

  return fieldInfoWindowString
}

export const attachInfoWindowToDataLayerFeatures = (featureLayer: google.maps.Data, mapRef: any, infoWindow: google.maps.InfoWindow) => {  
  let lastFeature: google.maps.Data.Feature | null = null

  const mouseOverListener = featureLayer.addListener('mouseover', function(event) {
    const feature = event.feature

    if (lastFeature !== feature) {
      if (lastFeature) {
        infoWindow.close()
      }

      lastFeature = feature
      const contentString = createInfoWindowHTML(feature)
      
      infoWindow.setContent(contentString)
      infoWindow.setPosition(getCenterOfFeature(feature))
      infoWindow.open(mapRef)
    }
  })

  const mouseOutListener = featureLayer.addListener('mouseout', function(event) {
    infoWindow.close()
    lastFeature = null
  })

  featureLayerEventListeners.push(mouseOverListener)
  featureLayerEventListeners.push(mouseOutListener)
}

export const removeAllInfoWindowsFromDataLayerFeatures = () => {
  featureLayerEventListeners.forEach((listener) => {
    google.maps.event.removeListener(listener)
  })

  featureLayerEventListeners = []
}

export const attachClickListnerToDataLayerFeatures = (featureLayer: google.maps.Data, callback: (id: string, listener: google.maps.MapsEventListener ) => void) => {
  const listener = featureLayer.addListener('click', (event) => {
    const feature = event.feature
    const featureId = feature.getProperty('id')
    callback(featureId, listener)
  })

  listener && featureLayerEventListeners.push(listener)
}

export const removeAllFeaturesFromDataLayer = (featureLayer: google.maps.Data) => {
  featureLayer.forEach((feature) => {
    featureLayer.remove(feature)
  })
}

export const removeFeatureByIdFromDataLayer = (featureLayer: google.maps.Data, id: string) => {
  const feature = featureLayer.getFeatureById(id)
  if (feature) featureLayer.remove(feature)
}

export const removeFeatureByIdsFromDataLayer = (featureLayer: google.maps.Data, ids: string[]) => {
  ids.forEach((id) => {
    removeFeatureByIdFromDataLayer(featureLayer, id)
  })
}

export const removeAllClickListenersFromDataLayerFeatures = () => {
  featureLayerEventListeners.forEach((listener) => {
    google.maps.event.removeListener(listener)
  })

  featureLayerEventListeners = []
}

export const getFeaturesByIds = (featureLayer: google.maps.Data, ids: string[]) => {
  const features = []

  ids.forEach((id) => {
    const feature = featureLayer.getFeatureById(id)
    features.push(feature)
  })

  return features
}


export const getBoundsOfFeature = (feature: google.maps.Data.Feature) => {
  const bounds = new google.maps.LatLngBounds()
  feature.getGeometry().forEachLatLng((latLng) => {
    bounds.extend(latLng)
  })

  return bounds
}

export const getBoundsOfFeatures = (featureLayer: google.maps.Data) => {
  const bounds = new google.maps.LatLngBounds()
  featureLayer.forEach((feature) => {
    feature.getGeometry().forEachLatLng((latLng) => {
      bounds.extend(latLng)
    })
  })

  return bounds
}

export const getCenterOfFeature = (feature: google.maps.Data.Feature) => {
  const bounds = new google.maps.LatLngBounds()
  feature.getGeometry().forEachLatLng((latLng) => {
    bounds.extend(latLng)
  })

  return bounds.getCenter()
}

export const getCenterOfFeatureById = (featureLayer: google.maps.Data, id: string) => {
  const feature = featureLayer.getFeatureById(id)
  return getCenterOfFeature(feature)
}

export const getBoundsByFeatureIds = (featureLayer: google.maps.Data, ids: string[]) => {
  const bounds = new google.maps.LatLngBounds()
  const features = getFeaturesByIds(featureLayer, ids)
  features.forEach((feature) => {
    feature.getGeometry().forEachLatLng((latLng) => {
      bounds.extend(latLng)
    })
  })

  return bounds
}

export const boundMapByAllFeatures = (featureLayer: google.maps.Data, mapRef: google.maps.Map) => {
  const bounds = getBoundsOfFeatures(featureLayer)
  mapRef.fitBounds(bounds)
}

export const convertCoordsToGeoJsonCoords = (coords: any) => {
  const geoJsonCoords = coords.map((coord: any) => {
    return [
      coord.longitude,
      coord.latitude,
    ]
  })

  if (geoJsonCoords.length > 0) {
    geoJsonCoords.push(geoJsonCoords[0])
  }

  return [geoJsonCoords]
}

export const highlightFeaturesByIds = (featureLayer: google.maps.Data, ids: string[]) => {
  const features = getFeaturesByIds(featureLayer, ids)
  features.forEach((feature: google.maps.Data.Feature) => {
    featureLayer.overrideStyle(feature, {
      strokeColor: DEFAULT_STROKE_COLOR,
      strokeWeight: STRONG_STROKE_WEIGHT,
    })
  })
}

export const revertFeatureStylesByIds = (featureLayer: google.maps.Data, ids: string[]) => {
  const features = getFeaturesByIds(featureLayer, ids)
  features.forEach((feature: google.maps.Data.Feature) => {
    const featureColor = feature?.getProperty('colour')
    if (featureColor) {
      featureLayer.overrideStyle(feature, {
        fillColor: featureColor,
        fillOpacity: DEFAULT_FILL_OPACITY,
        strokeColor: featureColor,
        strokeWeight: DEFAULT_STROKE_WEIGHT,
      })
    }
  })
}

export const getCoordinatesFromFeatureProperty = (feature: any) => {
  if (!feature.geometry) return []
  const coordinates = feature.geometry.coordinates[0].map(c => { return { latitude: c[1], longitude: c[0] } })
  return coordinates
}

export const drawLineStringFeatureOnFeatureLayer = (featureLayer: google.maps.Data, feature: any) => {
  if (!featureLayer || !feature) return
  let features = feature

  const featureData = createFeatureData(feature)
  features = featureLayer.addGeoJson(featureData)
    
  if (features) {
    featureLayer.overrideStyle(features[0], {
      strokeColor: DEFAULT_STROKE_COLOR,
      strokeWeight: STRONG_STROKE_WEIGHT,
    })
  }

  return features as google.maps.Data.Feature
}

export const removeAllLineStringFeaturesFromFeatureLayer = (featureLayer: google.maps.Data) => {
  if (!featureLayer) return

  featureLayer.forEach((feature: google.maps.Data.Feature) => {
    if (SUPPORTED_LINE_STRINGS.includes(feature.getGeometry().getType())) {
      featureLayer.remove(feature)
    }
  })
}

export const setMapBoundsByAllLineStringFeatures = (featureLayer: google.maps.Data, mapRef: google.maps.Map) => {
  if (!featureLayer) return

  const bounds = new google.maps.LatLngBounds()
  featureLayer.forEach((feature: google.maps.Data.Feature) => {
    if (SUPPORTED_LINE_STRINGS.includes(feature.getGeometry().getType())) {
      feature.getGeometry().forEachLatLng((latLng) => {
        bounds.extend(latLng)
      })
    }
  })

  mapRef.fitBounds(bounds)
}
