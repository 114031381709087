



























import ListLoader from "@/components/shared/loaders/list-loader.vue";
import contactComputedMixin from '@/components/internal-contact/internal-contact-computed';
import breadcrumbsMixin from '@/mixins/breadcrumbs-mixin';
import PersonalDetails from './internal-contact-personal-details.vue';
import BusinessDetails from './internal-contact-business-details.vue';
import fieldDetails from './internal-contact-field-details.vue';
import farmsDetails from './internal-contact-farms-list.vue';
import onGoingJobsView from './internal-contact-ongoing-jobs.vue';
import { mapMutations } from 'vuex';


export default {
    mixins: [contactComputedMixin, breadcrumbsMixin],
    components: {
        PersonalDetails,
        BusinessDetails,
        fieldDetails,
        ListLoader,
        onGoingJobsView,
        farmsDetails
    },
    computed: {
        resId() {
            return window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
        }
    },
    mounted() {
        this.resetContactProfile()
        this.contactFieldList = []
        Promise.all([
            this.$store.dispatch("getGlobalCountriesList"),
            this.$store.dispatch('getContactDetailsByAdmin', this.resId),
            this.$store.dispatch('getContactFarmsDetailsByAdmin', this.resId),
            this.$store.dispatch('getContactOnGoingJobsByAdmin', this.resId),
        ]).then(() => {
            this.$store.dispatch('getContactFieldsDetailsByAdmin', this.resId)
            this.setBreadcrumbs('CustomerDetails')
        });
    },
    methods: {
        ...mapMutations({
            resetContactProfile: 'resetContactProfile',
            clearFilterOptions: "setClearFilterOptions",
        }),
    }
}
