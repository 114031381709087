import { Coordinate } from "./Coordinate";
import { Address } from "./Address";
import { Crop } from "./Crop";
import { OwnershipType } from "@/enum/ownershipType";
import { User } from "./User";

export class Field {
    id?: string;
    title: string;
    polygon: any;
    areaInSquareMeter: number;
    archivedOn: string;
    isArchived: boolean;
    address: Address;
    crop: Crop;
    ownership: OwnershipType;
    colour: string;
    note: string;
    owner: User;
    cropHistory: Array<Crop>
    
    constructor(field?: Field) {
        if(field) {
            this.id = field?.id || null;
            this.title = field.title;
            this.polygon = field.polygon;
            this.areaInSquareMeter = field.areaInSquareMeter;
            this.archivedOn= field.archivedOn;
            this.isArchived= field.isArchived;
            this.address = field.address;
            this.crop = field.crop;
            this.ownership = field.ownership;
            this.colour = field.colour;
            this.note = field.note;
            this.owner = field.owner;
            this.cropHistory = field.cropHistory;
        }
    }

    public getGeoJSON(field: Field) {
        this.id = field?.id || null;
        this.title = field.title;
        this.polygon = field.polygon.map(({latitude, longitude}) => [longitude, latitude]);
        this.areaInSquareMeter = field.areaInSquareMeter;
        this.archivedOn= field.archivedOn;
        this.isArchived= field.isArchived;
        this.address = field.address;
        this.crop = field.crop;
        this.ownership = field.ownership;
        this.colour = field.colour;
        this.note = field.note;
        this.owner = field.owner;
        this.cropHistory = field.cropHistory;
        return this;
    }
}