/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
// @ts-nocheck
import { devLogger } from '@/utils/helpers';
import Dexie from 'dexie';

const HAS_CACHED_FIELDS_KEY = 'hasCachedFields';
const FIELD_ID_INDEX = 'fieldId';

// FieldsCacheDB scheme
interface FieldsCacheDBSchema extends Dexie {
	// table for field data
	fields: Dexie.Table<{ id?: number; data: any; ownerId: number; fieldTitle: string; isArchived: boolean }, number>;
	// table for meta data
	meta: Dexie.Table<{ key: string; value: any }, string>;
}

// Filter options to query fields
interface FilterOptions {
	searchText?: string;
	ownerId?: number;
	isArchived?: boolean;
}

// Service class
class FieldsCacheService {
	private db: FieldsCacheDBSchema;

	constructor() {
		// FieldsCacheDB initialization
		this.db = new Dexie('FieldsCacheDB') as FieldsCacheDBSchema;

		// DB Schema
		this.db.version(1).stores({
			fields: `++id, ${FIELD_ID_INDEX}, ownerId, fieldTitle, isArchived`, // Indexed columns for fields table
			meta: 'key', // Key-value pairs for meta table
		});
	}

	/**
	 * Transform field data to match the database schema.
	 * @param field - The field data to be transformed.
	 * @returns An object matching the database schema for fields.
	 */
	private transformFieldsForDB(field: any) {
		return {
			data: field,
			fieldId: field.id,
			ownerId: field.owner?.id ?? null,
			fieldTitle: field.title ?? null,
			isArchived: field.isArchived ?? false,
		};
	}

	/**
	 * Register a service worker for the application.
	 */
	private async registerServiceWorker() {
		if ('serviceWorker' in navigator) {
			try {
				const registration = await navigator.serviceWorker.register('/fields-cache-service-worker');
				devLogger().log(`Service Worker registered with scope: ${registration.scope}`);
			} catch (error) {
				devLogger().logError(`Service Worker registration failed: ${error}`);
			}
		} else {
			devLogger().log('Service Worker is not supported in this browser.');
		}
	}

	/**
	 * Unregister the service worker for the application.
	 */
	private async unregisterServiceWorker() {
		if ('serviceWorker' in navigator) {
			try {
				const registration = await navigator.serviceWorker.getRegistration('/fields-cache-service-worker');
				if (registration) {
					registration.unregister();
					devLogger().log('Service Worker unregistered.');
				} else {
					devLogger().log('Service Worker is not registered.');
				}
			} catch (error) {
				devLogger().logError(`Service Worker unregistration failed: ${error}`);
			}
		} else {
			devLogger().log('Service Worker is not supported in this browser.');
		}
	}

	/**
	 * Check if fields have been cached.
	 * @returns A promise that resolves to a boolean indicating if fields are cached.
	 */
	async hasCachedFields(): Promise<boolean> {
		const cached = await this.db.meta.get(HAS_CACHED_FIELDS_KEY);
		return !!cached;
	}

	/**
	 * Retrieve all cached fields.
	 * @returns A promise that resolves to an array of cached field data.
	 */
	async getAllCachedFields(): Promise<any[]> {
		const cachedFields = await this.db.fields.toArray();
		return cachedFields.map(entry => entry.data).flat();
	}

	/**
	 * Retrieve fields based on filter options.
	 * @param filterOptions - Options to filter the fields.
	 * @returns A promise that resolves to an array of filtered field data.
	 */
	async getFieldsWithFilterOptions(filterOptions: FilterOptions): Promise<any[]> {
		let fields = await this.db.fields.toArray();
		fields = fields.map(entry => entry.data).flat();

		// Apply search text filter
		if (filterOptions.searchText) {
			fields = fields.filter((field: any) =>
				field.title.toLowerCase().includes(filterOptions.searchText.toLowerCase())
			);
		}

		// Apply owner ID filter
		if (filterOptions.ownerId) {
			fields = fields.filter((field: any) => field.owner?.id === filterOptions.ownerId);
		}

		// Apply archive status filter
		if (filterOptions.isArchived !== undefined) {
			fields = fields.filter((field: any) => field.isArchived === filterOptions.isArchived);
		}

		return fields;
	}

	/**
	 * Set the last cached date in metadata.
	 */
	async setLastCachedAt(): Promise<void> {
		await this.db.meta.put({ key: 'lastCachedAt', value: new Date() });
	}

	/**
	 * Get the last cached date from metadata.
	 * @returns A promise that resolves to the last cached date.
	 */
	async getLastCachedAt(): Promise<Date> {
		const lastCachedAt = await this.db.meta.get('lastCachedAt');
		return lastCachedAt?.value;
	}

	/**
	 * Cache all fields into the database.
	 * @param fields - An array of field data to be cached.
	 */
	async cacheAllFields(fields: any[]): Promise<void> {
		devLogger().log('Caching all fields');
		await this.db.fields.clear();
		await this.db.fields.bulkAdd(fields.map(this.transformFieldsForDB));
		await this.db.meta.put({ key: HAS_CACHED_FIELDS_KEY, value: true });
		await this.setLastCachedAt();
	}

	/**
	 * Add new fields to the cache.
	 * @param fields - An array of new field data to be added.
	 */
	async addNewFieldsToCache(fields: any[]): Promise<void> {
		devLogger().log('Adding new fields to cache');
		await this.db.fields.bulkAdd(fields.map(this.transformFieldsForDB));
		devLogger().log('New fields added to cache: ' + fields.length);
	}

	/**
	 * Update existing fields in the cache by field IDs.
	 * @param fields - An array of field data to update.
	 */
	async updateFieldsInCacheByFieldIds(fields: any[]): Promise<void> {
		devLogger().log('Updating fields in cache');
		for (const field of fields) {
			const fieldId = field.id;
			const updatedField = this.transformFieldsForDB(field);
			const modifiedCount = await this.db.fields
				.where(FIELD_ID_INDEX)
				.equals(fieldId)
				.modify(updatedField);

			if (modifiedCount === 0) {
				await this.db.fields.add({ fieldId, ...updatedField });
				devLogger().log(`Field added to cache: ${fieldId}`);
			}
		}
		devLogger().log('Fields updated in cache: ' + fields.length);
	}

	/**
	 * Delete fields from the cache by field IDs.
	 * @param fields - An array of field data to delete, identified by their IDs.
	 */
	async deleteFieldsFromCacheByFieldIds(fields: any[]): Promise<void> {
		devLogger().log('Deleting fields from cache');
		const fieldIds = fields.map((field: any) => field.id);
		for (const fieldId of fieldIds) {
			await this.db.fields
				.where(FIELD_ID_INDEX)
				.equals(fieldId)
				.delete();
		}
		devLogger().log('Fields deleted from cache: ' + fields.length);
	}

	async deleteFieldsCacheDatabase(): Promise<void> {
		devLogger().log('Deleting fields cache database');
		await this.db.delete();
		devLogger().log('Fields cache database deleted');
	}
}

export default FieldsCacheService;
