



























































































































































































import { mapMutations } from 'vuex';
import { getCalculatedFieldArea } from '@/utils/helpers/jobs-helpers';
import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import { getLoggedInUserRole } from '@/utils/helpers/user-role-helpers';
import TeamJobsCommon from '@/components/team-jobs/team-jobs-details/team-jobs-details-common';
import OwnerAvatar from '@/components/shared/avatars/avatar.vue';
import { getListLengthAbsolute, getShortName } from '@/utils/helpers';
import { TeamJobStatusType } from '@/enum/teamJobStatusType';
import ConstantValues from '@/plugins/constantValues';
import LoadMoreDropdown from "@/components/shared/load-more-jobs-dropdown.vue";
import { TeamJobMapFieldDto } from '@/data/models/TeamJobMapFieldDto';

export default {
    name: "TeamJobsFields",
    mixins: [TeamJobsComputed, TeamJobsCommon],
    components: {
        OwnerAvatar,
        LoadMoreDropdown
    },
    data(){
        return{
            TeamJobStatusType,
            searchTimer: null
        }
    },
    computed: {
        isfieldOwnerFilter() {
            return getLoggedInUserRole().isContractorLoggedIn || getLoggedInUserRole().isContractorsManagerLoggedIn
        }
    },
    methods: {
        ...mapMutations({
            clearFilterOptions: "setClearFilterOptions",
        }),
        removeFromSelectedFieldsTeamJobs(data) {
            this.$store.dispatch('handleFieldFeatureSelection', data.id);
            this.enableTeamJobDiscard();
        },
        getTotalFieldArea(field) {
            return getCalculatedFieldArea(field);

        },
        getShortBusinessName(data){
            return getShortName(data, 60);
        },

        filterTeamJobFieldsByCustomer() {
            this.$store.dispatch('filterCustomerFieldsTeamJob');
        },
        navigateToSearchField(fieldId) {
            if (fieldId) {
                this.$store.dispatch('navigateToSearchField', fieldId);
            }
        },
        refeatchUserListForFields(){
            this.hasMoreData = true;
            if(this.teamJobFieldsCustomerSearchText != null){
                this.teamJobFieldsCustomerSearchText = null;
                this.searchCustomerForTeamJobField();
            }
        },
        applySearchFieldUserTimeout(e) {
            if (this.searchTimer) {
                clearTimeout(this.searchTimer);
                this.searchTimer = null;
            }
            this.searchTimer = setTimeout(() => {
                this.searchCustomerForTeamJobField();
            }, ConstantValues.defaultSearchTimeout);
        },
        searchCustomerForTeamJobField() {
            this.clearFilterOptions();
            this.$store.dispatch("setTeamJobFieldCustomerSearchText").then(res => {
                this.$store.dispatch('getTeamJobsOwners');
                    if (this.teamJobFieldsCustomerSearchText == '') {
                        this.clearFilterOptions();
                }
            });
        },
        async checkedFieldsForTeamJob(selectedFields) {
            this.enableTeamJobDiscard();
            this.navigateToSearchField(selectedFields.id);
            const dataField = new TeamJobMapFieldDto(selectedFields);
            await this.$store.dispatch('handleFieldFeatureSelection', dataField.id);
        },
        appendMoreCustomersForFields() {
            this.clearFilterOptions();
            this.$store.dispatch('setTeamJobFieldCustomerSearchText', [
                getListLengthAbsolute().getListLengthWithoutOwner(this.teamJobUsersList),
                ConstantValues.defaultPageLimitSize,
            ]).then(() => {
                this.$store.dispatch('getTeamJobsOwners');
            })
        }
    }
}
