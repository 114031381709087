















































































































































































import {mapActions} from 'vuex';
import { jobsMapLoaderColor } from '@/utils/uiconstants';
import FieldsComputedMixin from '@/components/fields/fields-computed';
import StringConstants from '@/plugins/stringConstants';
import FieldsSearchReadonly from "@/components/fields/field-map-search-readonly.vue";
import {BaseButton as Button} from "@/components/shared/buttons";
import Timers from "@/mixins/timers";

export default {
    name: 'FieldsListMap',
    mixins: [FieldsComputedMixin, Timers],
    components: {
        FieldsSearchReadonly,
        Button
    },
    data() {
        return {
            jobsMapLoaderColor,
            searchType: 'Field',
        }
    },
    beforeMount() {
        this.clearFieldListsLabelsAndFields()
    },
    beforeDestroy(){
        this.clearFieldListsLabelsAndFields()
    },
    mounted() {
        this.fieldsListMapLoader = true;
        this.fieldListOwnerId = 'All';
        this.getMoreFields()
    },
    computed: {
        fieldsText() {
            if (this.fieldsTotalCount == 1) {
                return StringConstants.fields.substr(0, StringConstants.fields.length - 1)
            }
            return StringConstants.fields
        },
    },
    watch: {
        editingAreaOnMap(val) {
            if (val) {
                this.editAreaOnMap(this.fieldDetails);
            } else if (typeof val === "boolean") {
                this.resetAreaToInitialState();
            }
        },
    },
    methods: {
        ...mapActions({
            clearFieldListsLabelsAndFields: 'clearFieldListsLabelsAndFields',
            editAreaOnMap: "editAreaOnMap",
            resetAreaToInitialState: "resetAreaToInitialState",
            getGoogleMapToEditFieldFixed: "getGoogleMapToEditFieldFixed",
            cleanUpMapAndFieldForm: "cleanUpMapAndFieldForm",
            resetDrawingManagerAndTempStates: "resetDrawingManagerAndTempStates"
        }),
        async getMoreFields(){
            const hasFieldsPath = (window.location.href.indexOf("fields") > -1);
            
            if(hasFieldsPath) {
                this.fieldsMountedOnMap = Promise.resolve(this.getAllFieldsForMapCaller());
            }
        },
        clearAreaOnMap() {
            this.fieldDetails.areaInSquareMeter = null;
            this.areaInSquareMeter = null;
            this.convertedArea = 0;
            this.fieldCordList = [];
            this.areaInvalid = false;
            this.files = null;
            this.resetDrawingManagerAndTempStates();
        },
        focusOnOwnerSelection() {
            const ownerField = document.getElementById("fieldOwnerList");
            const fieldWrapper = ownerField.closest('.v-input__slot');
            ownerField.focus();
            fieldWrapper.dispatchEvent(new MouseEvent('click'));
        },
        clearSearchIfEmpty() {
            if (this.placesSearchQuery === '' || !this.placesSearchQuery) {
                this.placesSearchResults = [];
                return;
            }
        },
        requestPlacesSearch(autoFocus = false) {
            this.clearSearchIfEmpty();

            this.$store.dispatch('requestGooglePlacesSearchOnFields', autoFocus);
        },
        navigateToSearchResult(searchResultsIndex) {
            this.$store.dispatch('focusOnSearchResultByIndex', searchResultsIndex);
            this.placesSearchResults = [];
        },
        clearPlacesSearch() {
            this.placesSearchQuery = '';
            this.placesSearchResults = [];
        },
    }
}
