
































































































































import Vue from "vue";
import NavMenu from "../src/components/layouts/sidebar.vue";
import { routeSubscriptions, routeAccount, routeSignUp} from "@/utils/endpoints";
import $ from "jquery";
import router from "@/router/index";
import { checkAppVersion } from "./utils/helpers/layout-helpers";
import { UserRoleType } from "@/enum/userRoleType";
import { AccountTabType } from "./enum/accountTabType";
import {defaultSidebarWidth} from "@/utils/uiconstants";
import { facebookPixelId, youtubeTutorialsLink } from '@/utils/constants';
import { getShortName } from "./utils/helpers";
import Notification from "@/components/shared/snackbar/notification.vue";

type FacebookPixelFunction = (command: string, ...args: any[]) => void;
declare const fbq: FacebookPixelFunction;

export default Vue.extend({
  name: "App",
  components: {
    "nav-menu-sidebar": NavMenu,
    Notification
  },
  beforeMount() {
    checkAppVersion(this.$store.state);
  },
  mounted() {
    if (this.$route.path === routeSignUp && process.env.NODE_ENV === 'production') {
      this.loadFacebookPixelScript(() => {
        fbq('init', facebookPixelId);
        fbq('track', 'PageView');
      });    
    }
      
    this.$store.dispatch("subscribeUser", localStorage.getItem("businessId"));
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    dashboardTitle() {
      return this.$store.getters.dashboardTitle;
    },
    loginUserName() {
      return this.$store.getters.loginUserName;
    },
    subscriptionStatus() {
      return localStorage.getItem("subscriptionStatus");
    },
    shouldShowTrial() {
      return localStorage.getItem("showTrialInfo") == "1";
    },
    trialDaysRemaining() {
      return localStorage.getItem("trialDaysRemaining");
    },
    getTrialClass() {
      if (this.trialDaysRemaining) {
        if (this.trialDaysRemaining < 7) return "ds-rmn-dg";
        return "ds-rmn-nr";
      } else {
        return "ds-rmn-nr";
      }
    },
    loginUserRole: {
      get() {
        return this.$store.getters.loginUserRole;
      },
    },
    showAllDashboardViewsLocal() {
      return localStorage.getItem("showAllDashboardViews")
    },
    accountTab: {
      get() {
          return this.$store.getters['accountTab'];
      },
      set(newVal) {
          this.$store.state['accountTab'] = newVal;
      }
    },
    breadcrumbsItems: {
      get() {
          return this.$store.getters['breadcrumbsItems']
      },
      set(newVal) {
          this.$store.state['breadcrumbsItems'] = newVal
      }
    },
    collapsedNavigation: {
      get() {
          return this.$store.state.collapsedNavigation;
      },
      set(newVal) {
        void(0)
      }
    },
    drawerIsOpen() {
      return this.$store.state.drawerOpen;
    },
    uniqueRouteKey() {
      const uniqueRoute = this.$route.fullPath.split("/")[1];
      return uniqueRoute;
    },
  },
  data: () => ({
    routeAccount,
    routeSubscriptions,
    UserRoleType,
    sidebarWidth: defaultSidebarWidth,
    youtubeTutorialsLink,
    items: [],
  }),
  methods: {
    closeModal() {
      $("#notiModal").fadeOut("fast");
      const message = document.getElementsByClassName("message")[0];
      message.innerHTML = "";
      $(".modal").css({
        "background-color": "unset",
      });
    },
    logoutClick() {
      this.$store.dispatch("logout");
    },
    routeToSubscription() {
      router.push(routeSubscriptions);
    },
    routeToAccount() {
      this.accountTab = AccountTabType.Profile
      router.push(routeAccount)
    },
    closeDrawer() {
      this.$store.dispatch('revertTempPolygon')
      history.back();
    },
    openTutorialsLink() {
      window.open(youtubeTutorialsLink, '_blank');
    },
    getShortName(username) {
      return getShortName(username, 10)
    },
    loadFacebookPixelScript(callback) {
      const loadPixelScript = function(f, b, e, v) {
        if (f.fbq) return;
        let n;
        n = f.fbq = function() {
          n.callMethod ?
          n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        let t = b.createElement(e);
        t.async = !0;
        t.src = v;
        let s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
        
        if (typeof callback === 'function') {
          t.onload = callback;
        }
      };
      
      loadPixelScript(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    }
  },
});
