













































import { apiVehicles, apiImplements, apiProducts, apiFields, apiTimeSheets, apiFarmers, apiTeamMembers, apiContractors } from '@/utils/endpoints';
import { BaseButton as SecondaryButton } from '@/components/shared/buttons/index';
import { MachineType } from '@/enum/machineType';
import { listAction } from '@/enum/listAction';
import { UserRoleType } from '@/enum/userRoleType';
import { mapMutations } from 'vuex';
import ConstantValues from '@/plugins/constantValues';
import {getLoggedInUserRole} from '@/utils/helpers/user-role-helpers';

export default {
    name: 'ActionListingDialog',
    props: [
        'itemName',
        'onClickEvent',
        'actionType',
        'itemsPreference'
    ],
    data() {
        return {
             listAction,
        }
    },
    components: {
        SecondaryButton
    },
    computed: {
        allItemsDeleted: {
            get() {
                return this.$store.getters.allItemsDeleted;
            },
            set(newVal) {
                this.$store.state.allItemsDeleted = newVal;
            }
        },
        allItemsRestored: {
            get() {
                return this.$store.getters.allItemsRestored;
            },
            set(newVal) {
                this.$store.state.allItemsRestored = newVal;
            }
        },
        allItemsArchived: {
            get() {
                return this.$store.getters.allItemsArchived;
            },
            set(newVal) {
                this.$store.state.allItemsArchived = newVal;
            }
        },
        selectedItems: {
            get() {
                return this.$store.getters.selectedItems;
            },
            set(newVal) {
                this.$store.state.selectedItems = newVal;
            }
        }, 
        listActionType: {
            get() {
                return this.$store.getters.listActionType;
            },
            set(newVal) {
                this.$store.state.listActionType = newVal;
            }
        },
        totalRowCount: {
            get() {
                return this.$store.getters.totalRowCount;
            },
            set(newVal) {
                this.$store.state.totalRowCount = newVal;
            }
        },
        dialogSelectedRows: {
            get() {
                return this.$store.getters.dialogSelectedRows;
            },
            set(val) {
                this.$store.state.dialogSelectedRows = val;
            },
        },
        loginUserRole: {
            get() {
                return this.$store.getters.loginUserRole;
            }
        },
        fieldsListMapData: {
            get() {
                return this.$store.getters.fieldsListMapData;
            },
            set(val) {
                this.$store.state.fieldsModule.fieldsListMapData = val;
            },
        },
        fieldListOwnerId() {
            return this.$store.state.fieldsModule.fieldListOwnerId
        }
    },
    methods: {
        ...mapMutations({
            clearFilterOption: 'setClearFilterOptions',
            resetPaging: 'resetPaging',
            resetSnackbarDetails: 'clearSnackbarDetails',
        }),

        clearAndResetPagingStates() {
            this.clearFilterOption();
            this.resetPaging();
            this.resetSnackbarDetails();
        },

        fnAgreeTite(actionType: listAction) {
                switch (actionType) {
                case listAction.Delete:
                    return this.$stringConstants('deletePromtAgree');

                case listAction.Restore:
                    return this.$stringConstants('restorePromptAgree');

                case listAction.Archive:
                    return this.$stringConstants('archivePromptAgree');
                }
        },

        fnDisagreeTite(actionType: listAction) {

            switch (actionType) {
                case listAction.Delete:
                    return this.$stringConstants('deletePromtDisagree');

                case listAction.Restore:
                    return this.$stringConstants('restorePromptDisagree');

                case listAction.Archive:
                    return this.$stringConstants('archivePromptDisagree');

            }
        },

        proccedToAction(listaction) {
            this.dialogSelectedRows = false;
            this.clearFilterOption();
            const state = this.$store;
            this.selectedItems.map((item) => {
                state.commit("setFilterOptions", [ConstantValues.queryIdentifiers, item]);
            });
            state.commit("setFilterOptions", ['Preference', this.itemsPreference]);
            let path = localStorage.getItem(ConstantValues.ownerId);
            switch (this.onClickEvent) {
                case 'proccedToActionOnMachines':
                    {
                        if (this.$store.state.machineType == MachineType.Implement) {
                            path = path + apiImplements;
                        }
                        else if (this.$store.state.machineType == MachineType.Vehicle) {
                            path = path + apiVehicles;
                        }
                        this.$store.dispatch("actionOnMultilselectItems", [listaction, path, this.itemsPreference]).then(() => {
                            this.$store.state.machinesModule.machineListFiltered = [];
                            this.clearAndResetPagingStates();
                            this.$store.dispatch("fnSetMachineFilter", [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(() => {
                                this.$store.dispatch("getMachineListFiltered");
                            });
                        });
                        break;
                    }
                case 'proccedToActionOnProducts':
                     {
                        this.$store.dispatch("actionOnMultilselectItems", [listaction, path + apiProducts, this.itemsPreference]).then(() => {
                            this.$store.state.productsModule.productsList = [];
                            this.clearAndResetPagingStates();
                            this.$store.dispatch("setProductsListFilter", [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(() => {
                                this.$store.dispatch("getProductsListFiltered");
                            });
                        });
                        break;
                    }
                case 'proccedToActionOnFields':
                    {
                        let ownerId;
                        if (this.checkIfUserIsFarmerOrFarmerManager()) {
                            path = localStorage.getItem(ConstantValues.ownerId);
                            ownerId = localStorage.getItem(ConstantValues.ownerId);
                        } else {
                            path = localStorage.getItem('fieldOwnerId');
                            ownerId = localStorage.getItem('fieldOwnerId');
                        }
                        
                        if(this.allItemsDeleted || this.allItemsRestored || this.allItemsArchived) {
                            this.$store.dispatch('removeFieldsFromMapByIds', [this.fieldsListMapData.filter(field => {
                                if (this.checkIfUserIsFarmerOrFarmerManager()) {
                                    return field.owner.id === ownerId
                                } else {
                                    return field.owner.id === this.fieldListOwnerId
                                }
                            }).map(field => field.id), true]);
                        } else {
                            if(this.selectedItems.length) {
                                this.$store.dispatch('removeFieldsFromMapByIds', [this.selectedItems], false);
                            }
                        }
                        this.$store.dispatch("actionOnMultilselectItems", [listaction, path + apiFields, this.itemsPreference]).then(async () => {

                            this.$store.state.fieldsModule.fieldFilterList = [];
                            this.clearAndResetPagingStates();
                            await this.$store.dispatch('checkAndHandleUnsyncedFields');

                            this.$store.dispatch("setFieldFilter", [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(() => {
                                this.$store.dispatch("getFieldsListFiltered");
                            });
                        });
                        break;
                    }
                case 'proccedToActionOnTimesheets':
                    {

                        this.$store.dispatch("actionOnMultilselectItems", [listaction, path + apiTimeSheets, this.itemsPreference]).then(() => {
                            this.$store.state.timeSheetsModule.timesheetsList = [];
                            this.clearAndResetPagingStates();
                            this.$store.dispatch("setTimesheetsFilter", [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(() => {
                                this.$store.dispatch("getTimesheetsList").then(() => {
                                    this.$store.dispatch('getTimesheetsStatistics')
                                });
                            });
                        });
                        break;
                    }
                case 'proceedToActionOnTeamMembers':
                    {
                        this.$store.dispatch("actionOnMultilselectItems", [listaction, 'users/' + path + '/' + apiTeamMembers, this.itemsPreference]).then(() => {
                            this.$store.state.teamMembersModule.teamMembersList = [];
                            this.clearAndResetPagingStates();
                            this.$store.dispatch("fnSetTeamMembersFilter", [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(() => {
                                this.$store.dispatch("getTeamMembers");
                            });
                        });
                        break;
                    }
                case 'proceedToActionOnFarmers':
                    {
                        if(listaction == listAction.Delete) {
                            listaction = listAction.Archive
                        }
                        this.$store.dispatch("actionOnMultilselectItems", [listaction, 'users/' + path + apiFarmers, this.itemsPreference]).then(() => {
                            this.$store.state.farmersModule.farmersFilterList = [];
                            this.clearAndResetPagingStates();
                            this.$store.dispatch("setFarmersFilter", [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(() => {
                                this.$store.dispatch("getFarmersFiltered");
                            });
                        });
                        break;
                    }
                case 'proceedToActionOnContractors': 
                {
                        listaction = listAction.Delete
                        this.$store.dispatch("actionOnMultilselectItems", [listaction, 'users/' + path + apiContractors, this.itemsPreference]).then(() => {
                            this.$store.state.contractorsModule.contractorFilterList = [];
                            this.clearAndResetPagingStates();
                            this.$store.dispatch("setContractorFilter", [ConstantValues.defaultPageOffsetSize, ConstantValues.defaultPageLimitSize]).then(() => {
                                this.$store.dispatch("getContractorFiltered");
                            });
                        });
                        break;
                }
                default:
                    break;
            }
            return;
        },

        checkIfUserIsFarmerOrFarmerManager(): boolean {
            return getLoggedInUserRole().isFarmerLoggedIn || getLoggedInUserRole().isFarmersManagerLoggedIn
        },
    }
}
