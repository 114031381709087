import { getComputedProperties } from "@/utils/helpers/computed-generator";

const computedProperties: Record<string, Array<string>> = {
    'teamJobsModule': [
        'teamJobFieldsTempList',
        'teamJobFieldsList',
        'teamJobFieldsCustomerSearchText',
        'teamJobFieldsLoaded',
        'teamJobFieldsTotal',
        'teamJobFieldsOffset',
        'teamJobSelectedFields',
        'teamJobSelectedFieldsForList',
        'teamJobPolygons',
        'teamJobSelectedPolygon',
        'teamJobFieldLabels',
        'teamJobFieldCustomerId',
        'fieldRequesterId',
        'teamJobFieldsLoading',
    ]
}

export default {
    name: 'TeamJobsFieldsComputedMixin',
    computed: {
        ...getComputedProperties(computedProperties)
    }
}