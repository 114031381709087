
































import TeamJobsComputed from '@/components/team-jobs/team-jobs-computed/_team-jobs-computed';
import TeamJobsComponents from '@/components/team-jobs/common/team-jobs-all.vue';
import TeamJobsDetailsButtons from '@/components/team-jobs/team-jobs-details/team-jobs-details-save.vue';
import TeamJobsCommon from '@/components/team-jobs/team-jobs-details/team-jobs-details-common';
import signalR from '@/services/signalr';
import { brandColor } from '@/utils/uiconstants';
import requestsCancellationMixin from '@/mixins/requests-cancellation';

export default {
    name: "TeamJobsDetails",
    mixins: [TeamJobsComputed, TeamJobsCommon, requestsCancellationMixin],
    data() {
        return {
            brandColor
        }
    },
    beforeMount() {
        this.$store.commit('resetTeamJobs');
    },
    beforeDestroy() {
        signalR.fnUnSubscripbe(this.jobResourceId);
        this.unsubscribeOperators();
        this.$store.commit('resetTeamJobs');
        this.$store.dispatch("destroyMapInstance");
    },
    watch: {
        async teamJobDetailsUpdatedRealTime(val) {
            if (val) {
                if(this.hasTeamJobChangesOnDetails) {
                    this.teamJobDetailsUpdatedSnackbarVisible = true;
                } else {
                    await this.updateTeamJobDetailsPage();
                }
            }
            this.teamJobDetailsUpdatedRealTime = false;
        },
    },
    mounted() {
        signalR.fnSubscribeGroup(this.jobResourceId);
        this.teamJobDetailsUpdatedSnackbarVisible = false;
        this.mountTeamJobsDetails();
        this.$store.dispatch("getGlobalCountriesList");
    },
    components: {
        TeamJobsComponents,
        TeamJobsDetailsButtons
    },
    methods: {
       async updateJobsDetailsAndCloseSnackbar() {
            this.teamJobDetailsUpdatedSnackbarVisible = false;
            await this.updateTeamJobDetailsPage();
            this.hasTeamJobChangesOnDetails = false; 
        },
        async updateTeamJobDetailsPage() {
            if(this.checkIsUpdatedFieldsEqual()) {
                this.getTeamJobDetailsRealtime();
            } else {
                await this.mountTeamJobsDetails();
            }
        },
        checkIsUpdatedFieldsEqual() {
            const existingFieldsLength = this.teamJobSelectedFields.length;
            const updatedFieldsLength = this.teamJobDetailsRealtime?.fields?.length || 0;

            if(existingFieldsLength != updatedFieldsLength) {
                return false;
            } else {
                const existingJobDataFields = JSON.stringify(this.teamJobSelectedFields.map(f => f.id).sort());
                const updatedJobDataFields = JSON.stringify(this.teamJobDetailsRealtime.fields.map(f => f.id).sort());

                return existingJobDataFields == updatedJobDataFields;
            }
        }
    }
}
