import { TeamJobMapFieldDto } from '@/data/models/TeamJobMapFieldDto';
import ConstantValues from '@/plugins/constantValues';
import $ from 'jquery';
import Vue from 'vue';
import FieldLabelComponent from '@/components/shared/labels/fields-label-stroke.vue';
import FieldLabelComponentLight from '@/components/shared/labels/fields-label-stroke-light.vue';
import FieldInfoWindow from '@/components/fields/field-info-window.vue';

const fieldLabelConstructor = Vue.extend(FieldLabelComponent);
const fieldLabelLightConstructor = Vue.extend(FieldLabelComponentLight);
const fieldInfoWindowConstructor = Vue.extend(FieldInfoWindow);

export const setLabelsOnMap = (labelsArray: Array<any>, mapInstance: any) => {
    if (labelsArray.length > 0) {
        labelsArray.map(label => {
            label.setMap(mapInstance);
        })
    }
}

export const setLabelsVisible = (labelsArray: Array<any>) => {
    if (labelsArray.length > 0) {
        labelsArray.map(label => {
            if (!label.getVisible()) {
                label.setVisible(true);
            }
        })
    }
}

export const setLabelsInvisible = (labelsArray: Array<any>) => {
    if (labelsArray.length > 0) {
        labelsArray.map(label => {
            label.setVisible(false);
        })
    }
}

export const isValidLatitude = (latitude) => {
    return parseFloat(latitude) >= -90 && parseFloat(latitude) <= 90
}

export const isValidLongitude = (longitude) => {
    return parseFloat(longitude) >= -180 && parseFloat(longitude) <= 180
}

export const changeInfoWindowTheme = (mapIdentifier: string, themeType: any) => {
    $(`${mapIdentifier} .gm-style-iw`).css({
        transform: 'translate(-65px, -60px)',
        opacity: '0'
    })
    if (themeType) {
        switch (themeType) {
            case 'dark':
                $(`${mapIdentifier} .gm-style-iw`).css({
                    backgroundColor: 'rgba(0,0,0,0.8)',
                    color: '#fff',
                    border: '1px solid #fff',
                })
                break
            case 'light':
                $(`${mapIdentifier} .gm-style-iw`).css({
                    backgroundColor: 'rgba(255,255,255,0.8)',
                    color: '#000',
                    border: '1px solid #000',
                })
                break
            default:
                $(`${mapIdentifier} .gm-style-iw`).css({
                    backgroundColor: 'rgba(0,0,0,0.8)',
                    color: '#fff',
                    border: '1px solid #fff',
                })
        }
    }

    $(`${mapIdentifier} .gm-style-iw-d`).css({
        overflow: 'hidden',
        width: '115px',
        height: '27px'
    })
    $(`${mapIdentifier} .gm-style .gm-style-iw-t::after`).css({
        display: 'none'
    })
    $(`${mapIdentifier} .gm-style-iw button`).css({
        display: 'none'
    })
    $(`${mapIdentifier} .gm-style-iw-t`).removeClass().addClass('new-marker')
}

export const changePolygonOpacity = (polygonShape: any, opacityLevel: number) => {
    polygonShape.setOptions({
        fillOpacity: opacityLevel
    })
}

export const createPolygonShapes = (mapState, data) => {
    const polygonLatLng = [];
    let cords = null;
    let cordColor = null;
    cords = data.polygon;
    for (let i = 0; i < cords.length; i++) {
        const x = {
            lat: cords[i].latitude,
            lng: cords[i].longitude
        }
        polygonLatLng.push(x);
    }
    cordColor = data.colour ? data.colour : "#1d34ad";
    

    const PolygonShape = new google.maps.Polygon({
        paths: polygonLatLng,
        //paths: GDouglasPeucker(polygonLatLng, 100),
        strokeColor: cordColor,
        map: mapState,
        strokeWeight: 3,
        fillColor: cordColor,
        fillOpacity: 0.1,
        zIndex: 99999
    });
    return PolygonShape;
}

export const createHighlightedPolyShape = (mapState, data) => {
    const polygonLatLng = [];
    let cords = null;
    let cordColor = null;
    cords = data.polygon;
    for (let i = 0; i < cords.length; i++) {
        const x = {
            lat: cords[i].latitude,
            lng: cords[i].longitude
        }
        polygonLatLng.push(x);
    }
    cordColor = '#ffffff';
    const PolygonShape = new google.maps.Polygon({
        paths: polygonLatLng,
        strokeColor: cordColor,
        map: mapState,
        strokeWeight: 5,
        strokeOpacity: 1,
        fillColor: cordColor,
        fillOpacity: 0.8,
        zIndex: 999
    });
    return PolygonShape;
}

export const createPolyline = (polyData) => {
    const lineSymbol = {
        path: 'M 0,-1 0,1',
        strokeOpacity: 1.0,
        scale: 4
    };
    const polygonLineLatLng = [];

    polyData.map((coord, idx) => {
        const x = {
            lat: coord.latitude,
            lng: coord.longitude
        }
        polygonLineLatLng.push(x);
    });
    const polyLine = new google.maps.Polyline({
        path: polygonLineLatLng,
        geodesic: false,
        strokeColor: '#ffffff',
        strokeOpacity: 0,
        icons: [{
            icon: lineSymbol,
            offset: '0',
            repeat: "5px"
        }]
    });
    return polyLine;
}

export const polygonMouseHoverEvent = (PolygonShape, rootState) => {
    google.maps.event.addListener(PolygonShape, 'mouseover', () => {
        if(!rootState.drawerOpen) {
            changePolygonOpacity(PolygonShape, 0.6)
        }
    });

    google.maps.event.addListener(PolygonShape, 'mouseout', () => {
        if(!rootState.drawerOpen) {
            changePolygonOpacity(PolygonShape, 0.2)
        }
    });
}

export const boundMapOnCordinates = (mapState, data) => {
    const boundCords = [];
    for (let i = 0; i < data.length; i++) {
        const dataField = new TeamJobMapFieldDto(data[i]);
        if (dataField.polygon.length > 0) {
            for (let j = 0; j < dataField.polygon.length; j++) {
                const x = {
                    lat: dataField.polygon[j].latitude,
                    lng: dataField.polygon[j].longitude
                }
                boundCords.push(x);
            }
        }
    }
    const bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < boundCords.length; i++) {
        const LatLng = new google.maps.LatLng({ "lat": parseFloat(boundCords[i].lat), "lng": parseFloat(boundCords[i].lng) });
        bounds.extend(LatLng);
    }
    mapState.fitBounds(bounds);
    const listener = google.maps.event.addListener(mapState, "idle", function () {
        mapState.fitBounds(bounds, ConstantValues.defaultMapPadding);
        google.maps.event.removeListener(listener);
    });
}

export const extendBoundsByLocations = (locations, fields, mapState) => {
    const boundCords = [];
    if (locations.length != 0) {
        for (let i = 0; i < locations.length; i++) {
            const x = {
                lat: locations[i].latitude,
                lng: locations[i].longitude
            }
            boundCords.push(x);
        }
    }
    if (fields.length != 0) {
        for (let i = 0; i < fields.length; i++) {
            if (fields[i].polygon.length > 0) {
                for (let j = 0; j < fields[i].polygon.length; j++) {
                    const x = {
                        lat: fields[i].polygon[j].latitude,
                        lng: fields[i].polygon[j].longitude
                    }
                    boundCords.push(x);
                }
            }
        }
    }
    if (locations.length == 0 && fields.length == 0) {
        const newLat = localStorage.getItem('defaultStartingLat') ? parseFloat(localStorage.getItem('defaultStartingLat')) : ConstantValues.defaultBusinessLatitude;
        const newLng = localStorage.getItem('defaultStartingLng') ? parseFloat(localStorage.getItem('defaultStartingLng')) : ConstantValues.defaultBusinessLongitude;
        const tempBounds = new google.maps.LatLngBounds({ "lat": newLat, "lng": newLng });
        mapState.fitBounds(tempBounds, ConstantValues.defaultMapPadding);
        mapState.setZoom(ConstantValues.defaultMapZoom);
        return
    }
    const bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < boundCords.length; i++) {
        const LatLng = new google.maps.LatLng({ "lat": parseFloat(boundCords[i].lat), "lng": parseFloat(boundCords[i].lng) });
        bounds.extend(LatLng);
    }
    mapState.fitBounds(bounds);
}

export const hideMapControlButtons = () => {
    const controlSet = document.querySelectorAll<HTMLElement>(".gmnoprint");
    controlSet.forEach((element) => {
        element.style.opacity = "0";
    });

    const controlAttributesSet =
        document.querySelectorAll<HTMLElement>(".gm-style-cc");
    controlAttributesSet.forEach((element) => {
        element.style.opacity = "0";
    });

    const activeControlSet: any =
        document.querySelectorAll<HTMLElement>(".gm-control-active");
    activeControlSet[0].style.opacity = 0;
}

export const hideScrollBars = (scrollerClass) => {
    toggleScrollView(scrollerClass, false)
}

export const showScrollBars = (scrollerClass) => {
    toggleScrollView(scrollerClass, true)
}

export const toggleScrollView = (scrollerClass, enableScrollView) => {
    if(scrollerClass) {
        const targetElements = Array.from(document.getElementsByClassName(scrollerClass) as HTMLCollectionOf<HTMLElement>)
        
        if(targetElements.length > 0) {
            for(let i = 0; i < targetElements.length; i++) {
                targetElements[i].style.overflow = enableScrollView ? 'auto' : 'hidden';
            }
        }
    } else {
        document.documentElement.style.overflow = enableScrollView ? 'auto' : 'hidden';
    }
}

export const toggleScrollBarsOnScrollView = (showScrollBars: boolean) => {
    const scrollableElements: any = document.getElementsByClassName('hide-scroll');
    if(scrollableElements && scrollableElements.length) {
        for (let i=0; i<scrollableElements.length; i++) {
            scrollableElements[i].style.overflow = showScrollBars ? 'auto' : 'hidden';
        }
    }
}

export const formatFieldObjectForLabel = (field: any) => {
    return {
        field: field,
        customer: (field.owner.businessProfile ? field.owner.businessProfile.title : ((field.owner.firstName ? field.owner.firstName : '') + ' ' + (field.owner.lastName ? field.owner.lastName : ''))), 
        ownerId: field.owner.id ? field.owner.id : null,
        fieldId: field.id,
        fieldColor: field.colour ? field.colour : '#ffffff',
    }
}

export const GDouglasPeucker = (source, kink) => {
    //G Douglas Peucker
    let	n_source = 0, n_stack, n_dest, start, end, i, sig;    
    let dev_sqr, max_dev_sqr, band_sqr;
    let x12, y12, d12, x13, y13, d13, x23, y23, d23;
    const F = ((Math.PI / 180.0) * 0.5 );
    const index = []; 
	const sig_start = []; 
    const sig_end = [];	

    if ( source.length < 3 ) 
        return(source);
		
	n_source = source.length;
    band_sqr = kink * 360.0 / (2.0 * Math.PI * 6378137.0);
    band_sqr *= band_sqr;
    n_dest = 0;
    sig_start[0] = 0;
    sig_end[0] = n_source-1;
    n_stack = 1;

    while ( n_stack > 0 ){
        start = sig_start[n_stack-1];
        end = sig_end[n_stack-1];
        n_stack--;

        if ( (end - start) > 1 ) { 
            x12 = (source[end].lng - source[start].lng);
            y12 = (source[end].lat - source[start].lat);
            if (Math.abs(x12) > 180.0) 
                x12 = 360.0 - Math.abs(x12);
            x12 *= Math.cos(F * (source[end].lat + source[start].lat));
            d12 = (x12*x12) + (y12*y12);

            for ( i = start + 1, sig = start, max_dev_sqr = -1.0; i < end; i++ ){                                    

                x13 = (source[i].lng - source[start].lng);
                y13 = (source[i].lat - source[start].lat);
                if (Math.abs(x13) > 180.0) 
                    x13 = 360.0 - Math.abs(x13);
                x13 *= Math.cos (F * (source[i].lat + source[start].lat));
                d13 = (x13*x13) + (y13*y13);

                x23 = (source[i].lng - source[end].lng);
                y23 = (source[i].lat - source[end].lat);
                if (Math.abs(x23) > 180.0) 
                    x23 = 360.0 - Math.abs(x23);
                x23 *= Math.cos(F * (source[i].lat + source[end].lat));
                d23 = (x23*x23) + (y23*y23);
                                
                if ( d13 >= ( d12 + d23 ) )
                    dev_sqr = d23;
                else if ( d23 >= ( d12 + d13 ) )
                    dev_sqr = d13;
                else
                    dev_sqr = (x13 * y12 - y13 * x12) * (x13 * y12 - y13 * x12) / d12;

                if ( dev_sqr > max_dev_sqr  ){
                    sig = i;
                    max_dev_sqr = dev_sqr;
                }
            }

            if ( max_dev_sqr < band_sqr ) {
                index[n_dest] = start;
                n_dest++;
            }
            else{
                n_stack++;
                sig_start[n_stack-1] = sig;
                sig_end[n_stack-1] = end;
                n_stack++;
                sig_start[n_stack-1] = start;
                sig_end[n_stack-1] = sig;
            }
        }
        else{
                index[n_dest] = start;
                n_dest++;
        }
    }

    index[n_dest] = n_source-1;
    n_dest++;

    const r = [];
    for(let i=0; i < n_dest; i++)
        r.push(source[index[i]]);
    return r;
    
}

export const toGeoJSONCompatible = (coordinates) => {
    return coordinates.map(({latitude, longitude}) => [longitude, latitude]);
}

export const getPolygonCenter = (geometry) => {
    const bounds = new google.maps.LatLngBounds();
    geometry.forEach((latLng) => bounds.extend(latLng));

    return bounds.getCenter();
}

export const focusMapOnPolygon = (map, polygon) => {
    const bounds = new google.maps.LatLngBounds();
    polygon.getPath().forEach((latLng) => bounds.extend(latLng));

    map.fitBounds(bounds);
}
