import ConstantValues from '@/plugins/constantValues';

//page titles
export const signUpComponentTitle = 'SignUp';
export const resendEmailVerificationComponentTitle = 'Resend Email';
export const jobsTitle = 'Jobs';
export const jobsAddTitle = 'Add job';
export const fieldsTitle = 'Fields';
export const operationsTitle = 'Operations';
export const timesheetsTitle = 'Timesheets';
export const teamMembersTitle = 'Team members';
export const contractorsTitle = 'Contractors';
export const subContractorsTitle = 'Sub-contractors';
export const vehiclesTitle = 'Vehicles';
export const customersTitle = 'Customers';
export const implementsTitle = 'Implements';
export const productsTitle = 'Products';
export const jobsDetailsTitle = 'Job details';
export const integrationsTitle = 'Integrations';
export const invoicesTitle = 'Invoices';
export const invoicesAddTitle = 'Add invoice';
export const invoicesCreateTitle = 'Create invoice';
export const invoicesDetailsTitle = 'Invoice';
export const subscriptionsTitle = 'Subscription';
export const addContactTitle = 'Create customer';
export const customerDetailsTitle = 'Customer details';
export const customerDetailsUpdateTitle = 'Edit customer';
export const overviewTitle = 'Overview';
export const usersTitle = 'Users';
export const businessesTitle = 'Businesses'; 
export const businessesDetailsTitle = 'Business details'; 

//component names
export const homeComponentTitle = 'Home';
export const loginComponentTitle = 'Login';
export const jobsComponentTitle = 'Jobs';
export const fieldsComponentTitle = 'Fields';
export const fieldsAddComponentTitle = 'Add Fields';
export const fieldsEditComponentTitle = 'Edit Fields';
export const fieldsRecalculateArea = 'Reset';
export const fieldsRecalculateAreaTooltip = 'Click to reset field area';
export const operationsComponentTitle = 'Operations';
export const timesheetsComponentTitle = 'Timesheets';
export const teamMembersComponentTitle = 'TeamMembers';
export const contractorsComponentTitle = 'Contractors';
export const vehiclesComponentTitle = 'Vehicles';
export const farmersComponentTitle = 'Farmers';
export const implementsComponentTitle = 'Implements';
export const productsComponentTitle = 'Products';
export const forgetPasswordComponentTitle = 'Forget Password';
export const resetPasswordComponentTitle = 'Reset Password';
export const teamJobDetailsComponentTitle = 'Team Job Details';

//products
export const productCreated = 'New product added successfully!';
export const productNotCreated = 'Failed to add new product!';
export const productUpdated = 'Product updated successfully!';
export const productDeleted = 'Product deleted successfully!';
export const productNotDeleted = 'Failed to delete product!';
export const productZeroStateInactive = 'You do not have any inactive products.';
export const productZeroStateHeading = 'You do not have any products.';
export const productZeroState = 'Click on the "Add" button to start creating new products.';
export const productListDisabled = "You're not allowed to view the product list.";
export const productDeletePromptTitle = 'Delete product?';
export const productDeletePromptSubtitle = 'Are you sure you want to delete this product?';
export const productArchivePromptTitle = 'Archive product?';
export const productArchivePromptSubtitle = 'Are you sure you want to archive this product?';
export const productArchiveSuccessMessage = 'Product archived successfully.';
export const productRestorePromptTitle = 'Restore product?';
export const productRestorePromptSubtitle = 'Are you sure you want to restore this product?';
export const productRestoredSuccessMessage = 'Product restored successfully.';

//su-users
export const suUsersZeroStateHeading = 'No users found!';
export const suBusinessesZeroStateHeading = 'No businesses found!';

export const suUsersLoaderDialogTitle = 'Getting users list ready to export';
export const suUsersLoaderDialogContent = 'Please wait, while the users list is being loaded:';
export const suBusinessesLoaderDialogTitle = 'Getting businesses list ready to export';
export const suBusinssesLoaderDialogContent = 'Please wait, while the businesses list is being loaded:';

//products: add
export const productAddTitle = 'Add product';
export const productAddSubtitle = 'Product information';
export const productAddDescription = 'Add details of the product.';

//products: edit
export const productEditTitle = 'Edit product';
export const productEditSubtitle = 'Product information';
export const productEditDescription = 'Update details of the product.';

//fields 
export const fieldZeroState = 'Click on the "Add" button to start creating new fields.';
export const fieldZeroStateHeading = "You don't have any fields";
export const fieldCreated = 'New field added successfully!';
export const fieldNotCreated = 'Failed to add new field!';
export const fieldNotUpdated = 'Failed to update the field!';
export const fieldUpdated = 'Field updated successfully!';
export const deleteFieldWaring = 'Are you sure you want to delete this field?';
export const fieldNotDeleted = 'Failed to delete field!';
export const fieldDeleted = 'Field deleted successfully';
export const fieldDeletePromtTitle = 'Delete field?';
export const fieldListDisabled = "You're not allowed to view the field list.";
export const fieldsBulkUploadSuccess = 'Fields added successfully!';
export const fieldsUploadFailed = 'Failed to upload';
export const fieldsUploadDisclaimerShapeFile = 'Shape files of World Geodetic System 1984 (WGS1984, EPSG:4326) and British National Grid (Ellipsoid : Airy1830, OGSB36) projections are supported. Shape files that do not contain a projection (.prj file) will be considered as WGS1984.';
export const fieldsUploadDisclaimerKML = 'KML files of World Geodetic System 1984 (WGS1984, EPSG:4326) are supported.';
export const fieldAreaCalculationWarning = 'Please do not interrupt this process by refreshing or closing the tab.'
export const fieldAreaValidationSuccess = 'All fields updated!';
export const fieldsUploadFootnote = 'Unsupported files will be ignored.';
export const fieldsListCheckboxMultiSelectAlert = 'Please filter by a customer to enable multiple select';
export const fieldArchivePromptTitle = 'Archive field?';
export const fieldArchivePromptSubtitle = 'Are you sure you want to archive this field';
export const fieldArchiveSuccessMessage = 'Field archived successfully';
export const fieldRestorePromptTitle = 'Restore field?';
export const fieldRestorePromptSubtitle = 'Are you sure you want to restore this field';
export const fieldRestoreSuccessMessage = 'Field restored successfully';
export const fieldZeroStateInactive = 'You do not have any inactive fields.';
export const fieldAddNewText = 'Add New Field';
export const fieldAreaText = 'Field area';
export const fieldDrawingOnMapText = 'Click on the map to start drawing';
export const fieldAreaCannotZeroText = "Area can't be 0";
export const fieldNameText = 'Field name';
export const fieldGetAreaBYDrawText = 'Draw on the area on the map to get the area';
export const fieldEditMapText = 'Edit map';
export const fieldClearMapText = 'Clear map';
export const fieldCropHistoryText = 'Crop history';
export const fieldAddCropHistoryText = 'Add crop history';
export const fieldCropNameText = 'Enter crop name';
export const fieldSelectYearText = 'Select year';
export const fieldManageCropHistoryText = 'Manage crop history for this field.';
export const fieldNoCropAddedText = 'No crops added.';
export const fieldManageJobForFieldText = 'Manage jobs for this field.';
export const fieldNoJobFoundText = 'No jobs found.';
export const fieldUploadText = 'Upload fields';
export const fieldInfoWindowMessage = 'You can edit this shape by dragging the points';
export const fieldAddSelectCustomerAlertMessage = 'Select owner to draw field.';

//time sheets
export const timeSheetUpdated = 'Timesheet updated successfully!';
export const timeSheetCreated = 'New timesheet added successfully!';
export const timeSheetDeletePromtTitle = 'Delete timesheet?';
export const timeSheetDeletePromtSubtitle = 'Are you sure you want to delete this timesheet?';
export const timeSheetNotDeleted = 'Failed to delete the timesheet!';
export const timeSheetDeleted = 'Timesheet deleted successfully!';
export const timeSheetZeroStateHeading = 'No timesheets';
export const timeSheetZeroState = 'You do not have any timesheets. Click on the "Add" button to start creating new timesheets.';
export const timeSheetExportTitle = 'Getting timesheets ready to export';
export const timeSheetExportMessage = 'Please wait, while the timesheets are being loaded:';
export const timeSheetAddText = 'Add timesheet';

//farmers 
export const farmersListZeroStateTitle = "You don't have any customers";
export const farmersListZeroStateSubtitle = 'Click on the "Add" button to start creating new customers.';
export const farmersDeletePromtTitle = 'Delete customer?';
export const farmersDeletePromtSubtitle = 'Are you sure you want to delete this customer?';
export const farmersArchivePromtTitle = 'Archive customer?';
export const farmersRestorePromtTitle = 'Restore customer?';
export const farmersArchivePromtSubtitle = 'Are you sure you want to archive this customer?';
export const farmersRestorePromtSubtitle = 'Are you sure you want to restore this customer?';
export const farmerDeleted = 'Customer removed successfully!';
export const farmerNotDeleted = 'Failed to delete customer';
export const farmerUpdated = 'Customer details updated successfully!';
export const farmersUpdatedFromInvoicingService = 'Customers updated successfully!';
export const farmersImportedFromInvoicingService = 'Customers imported successfully!';
export const farmersUpdateCustomerPromptTitle = 'Update customers?';
export const farmersUpdateCustomerPromptText1 = 'All the profile data from ';
export const farmersUpdateCustomerPromptText2 = ' will override profile information present within ag-drive.';
export const farmersUpdateCustomerPromptText3 = 'Are you sure to update information from ';
export const farmersUpdateCustomerPromptText4 = ' for all the selected customers?';
export const farmerConnectionRequestText = 'Connection request';
export const farmerCompleteInfoText = 'Complete missing info';
export const farmerNotFoundText = 'No customer found';
export const farmerInviteText = 'Invite customer';
export const farmerImportQuickbooksText = 'Import from Quickbooks';
export const farmerImportXeroText = 'Import from Xero';
export const farmerImportSageText = 'Import from Sage';
export const farmerImportIntegrationsText = 'Import from Integrations';
export const farmerImportFileText = 'Import from file';
export const farmerAddCustomerText = 'Add Customer';
export const farmerIntegrationsTitle = 'Integrations';
export const farmerIntegrationsSubTitle = 'Integrate invoices service like Quickbooks, Xero, Sage etc. and directly import your existing customers.';
export const farmerGoToIntegration = 'Go to integrations.';
export const farmerManageCustomer = 'Manage customers';
export const contractorsListZeroStateTitle = "You don't have any contractors!";
export const contractorsListZeroStateSubtitle = 'Click on the "Add" button to start adding';
export const contractorsDeletePromtTitle = 'Delete contractor?';
export const contractorsDeletePromtSubtitle = 'Are you sure you want to delete this contractor?';
export const contractorDeleted = 'Contractor removed successfully!';
export const contractorNotDeleted = 'Failed to remove the contractor!';
export const contractorNotUpdated = "Failed to update contractor's details!";
export const contractorUpdated = "Contractor's details updated successfully!";
export const contractorInviteText = 'Invite new contractor';
export const contractorEditText = 'Edit contractor';
export const contractorManageText = 'Manage contractors';
export const connectionRequestDeleted = 'Connection request deleted';
export const invitationRequestDeleted = 'Invitation request deleted';
export const connectionRequestFailedToDelete = 'Failed to delete connection request';
export const connectionRequestSent = 'Connection request sent';

//vehicles
export const vehiclesListZeroStateTitle = "You don't have any vehicles";
export const vehiclesListZeroStateSubtitle = 'Click on the "Add" button to start creating new vehicles.';
export const vehicleDeletePromtTitle = 'Delete vehicle?';
export const vehicleDeletePromtSubtitle = 'Are you sure you want to delete this vehicle?';
export const vehicleArchivePromptTitle = 'Archive Vehicle?';
export const vehicleArchivePromptSubtitle = 'Are you sure you want to archive this vehicle?';
export const vehicleRestorePromptTitle = 'Restore Vehicle?';
export const vehicleRestorePromptSubtitle = 'Are you sure you want to restore this vehicle?';
export const vehicleListDisabled = "You're not allowed to view the vehicles list.";
export const vehicleSafetyChecklistTitle = 'Vehicle checklist';
export const vehicleSafetyChecklistDescription = 'Add a pre-start checklist for the vehicle to ensure health and safety.';

//vehicles: add
export const vehicleAddTitle = 'Add vehicle';
export const vehicleNameLabel = 'Name *';
export const vehicleBrandLabel = 'Brand *';
export const vehicleModelLabel = 'Model *';
export const vehicleRegistrationNumberLabel = 'Registration number *';
export const vehicleWidthLabel = 'Width';
export const vehicleLengthLabel = 'Length';
export const vehicleWeightLabel = 'Weight';
export const vehicleOwnershipLabel = 'Ownership';
export const vehicleAddSuccessMessage = 'Vehicle added successfully';
export const vehicleZeroStateInactive = 'You do not have any inactive vehicle';

//vehicles: edit
export const vehicleUpdateSuccessMessage = 'Vehicle updated successfully';
export const vehicleDeleteSuccessMessage = 'Vehicle deleted successfully';
export const vehicleEditTitle = 'Edit vehicle';
export const vehicleEditSubtitle = 'Vehicle information';
export const vehicleEditDescription = 'Add details of the vehicle.';

//vehicles: archive/restore
export const vehicleArchiveSuccessMessage = 'Vehicle Archived Successfully';
export const vehicleRestoreSuccessMessage = 'Vehicle Restored Successfully';

//team members
export const teamMembersListZeroStateTitle = 'You do not have any team members';
export const teamMembersListZeroStateSubtitle = 'Click on the "Add" button to start creating new team members.';
export const teamMemberArchivePromtTitle = 'Archive team member?';
export const teamMemberArchivePromtSubtitle = "Are you sure you want to archive this team member? Team members with active jobs won't be archived.";
export const teamMemberDeletePromtTitle = 'Archive team member?';
export const teamMemberRestorePromtTitle = 'Restore team member?';
export const teamMemberDeletePromtSubtitle = 'Are you sure you want to archive this team member?';
export const teamMemberRestorePromtSubtitle = 'Are you sure you want to restore this team member?';
export const teamMemberRestoreBilling = 'This will affect your subscription billing.';
export const teamMemberListDisabled = "You're not allowed to view the team members list.";
export const teamMemberUpdateSuccessMessage = 'Team member updated successfully!';
export const teamMemberDeleteSuccessMessage = 'Team member deleted successfully!';
export const teamMemberArchiveSuccessMessage = 'Team member archive successfully!';
export const teamMemberRestoreSuccessMessage = 'Team member restored successfully!';
export const teamMemberNotArchiveText = 'No archived team members.';
export const teamMemberInviteTitle = 'Invite new member';
export const teamMemberUpdateTitle = 'Update team member';
export const teamMemberRoleBillingPriceLabel = '*without showing billing price';
export const teamMemberRoleRecordJobsPriceLabel = "**can't edit, but record jobs";
export const teamMembersRoleChangeSuccessMessage = 'Roles changed successfully!';
export const teamMembersRoleChangeTableLabelFeatures = 'Feature access';
export const teamMembersRoleChangeTableLabelManager = 'Manager';
export const teamMembersRoleChangeTableLabelTeamLead = 'Team Lead';
export const teamMembersRoleChangeTableLabelOperator = 'Operator';

//team members: edit
export const teamMemberRoleLabel = 'Role';
export const teamMemberWorkingStartTimeLabel = 'Start time';
export const teamMemberWorkingEndTimeLabel = 'End time';

//implements
export const implementsListZeroStateTitle = "You don't have any implements";
export const implementsListZeroStateSubtitle = 'Click on the "Add" button to start creating new implement.';
export const implementDeletePromtTitle = 'Delete implement?';
export const implementDeletePromtSubtitle = 'Are you sure you want to delete this implement?';
export const implementListDisabled = "You're not allowed to view the implements list.";
export const implementZeroStateInactive = 'You do not have any inactive implement';

//implements: add
export const implementAddTitle = 'Add implement';
export const implementAddSuccessMessage = 'Implement added successfully!';
export const implementUpdateSuccessMessage = 'Implement updated successfully!';
export const implementDeleteSuccessMessage = 'Implement deleted successfully!';
export const implementArchivePromptTitle = 'Archive Implement?';
export const implementArchivePromptSubtitle = 'Are you sure you want to archive this implement?';
export const implementRestorePromptTitle = 'Restore Implement?';
export const implementRestorePromptSubtitle = 'Are you sure you want to restore this implement?';
export const implementArchiveSuccessMessage = 'Implement archived successfully.';
export const implementRestoreSuccessMessage = 'Implement restored successfully.';
export const implementSafetyChecklistTitle = 'Implement checklist';
export const implementSafetyChecklistDescription = 'Add a pre-start checklist for the implement to ensure health and safety.';

//implements: edit
export const implementEditTitle = 'Edit implement';
export const implementEditSubtitle = 'Implement information';
export const implementEditDescription = 'Add information about the implement.';

//users
export const usersCompanyJobCode = 'Company job code';
export const usersCompanyJobCodeRequired = usersCompanyJobCode + '*';
export const shortTitleWarning = "Only 2 or 3 letters are allowed and this can't be changed later. This will be used uniquely to identify your jobs.";
export const userInvitationDescription = 'You can see all the connection requests sent and recieved.';
export const usersSignUpTitle1 = 'Start your 14-day free trial';
export const userRequiredFullName = 'Name is required.';
export const userRequiredPassword = 'Password is required.';
export const userRequiredConfirmPassword = 'Confirmation password is required.';
export const userRequiredEmailAddress = 'Email address is required.';
export const userRequiredPhoneNumber = 'Phone number is required.';
export const userRequiredCompanyName = 'Company name is required.';
export const userRequiredTermsAndPrivacyCheckbox = 'Please agree to our terms and conditions to proceed';
export const userMinlengthFullName = `Name should be at least ${ConstantValues.minlengthFullName} characters long.`;
export const userMinlengthPassword = `Password should be at least ${ConstantValues.minlengthPassword} characters long.`;
export const userMaxlengthPassword = `Password should not be more than ${ConstantValues.maxlengthPassword} characters long.`;
export const userMinlengthPhoneNumber = `Phone number should be at least ${ConstantValues.minlengthPhoneNumber} digits long.`;
export const userMaxLengthPhoneNumber = `Phone number cannot be more than ${ConstantValues.maxlengthPhoneNumber} digits long.`;
export const userPasswordCustomValidationError = `Password must contain at least one uppercase, one lowercase character and one number.`;
export const userSameAsPassword = 'Passwords entered are not the same.';
export const userFormatEmailAddress = 'Invalid email address.';
export const userFormatFullName = 'Name cannot contain numbers or special charaters.';
export const userFormatPhoneNumber = 'Phone number cannot contain letters or special characters.'
export const userSignUpSuccessMessage = 'Signed up successfully!';
export const userOperatorSignUpSuccessMessage = `${userSignUpSuccessMessage} Please log in using the mobile application.`;
export const userResendEmailVerificationMessage = 'Verification email sent successfully';
export const userVerifyEmailMessage = 'Please verify your email before continuing';
export const userAddressAddSuccessMessage = 'Address updated successfully!';
export const userAddressDeletedSuccessMessage = 'Address Deleted successfully!';
export const userAcceptInvitationPromptTitle = 'Accept invitation?';
export const userAcceptInvitationPromptSubtitle = 'Are you sure you want to accept this invitation?';
export const userDeleteInvitationPromptTitle = 'Delete invitation?';
export const userDeleteInvitationPromptSubtitle = 'Are you sure you want to delete this invitation?';
export const userInviteSendSuccessMessage = 'Invitation sent successfully!';
export const userInviteAcceptSuccessMessage = 'Invitation accepted successfully!';
export const userInviteDeclineSuccessMessage = 'Invitation declined successfully!';
export const userInviteDeclineFailSuccessMessage = 'Failed to decline the invitation!';
export const userInviteAcceptFailMessage = 'Failed to accept invitation.';
export const userEmailVerifySuccessMessage = 'Email verified successfully. You can now login.';
export const userLoginWithXeroText = 'Sign up with Xero';
export const userEmailVerifyFailMessage = 'Failed to verify email.';
export const userWelcomeBackText = 'Welcome back! Please login to continue.';
export const userInvitationExpiredMessage = 'The invitation has been expired!';
export const userShortCompanyNameWarning = shortTitleWarning;
export const userCustomerBusinessProfileMissingWarning = 'Business profile of the selected customer is not complete. Please complete it and then proceed.';
export const userEmailAddressDownloadWaitText = 'Please wait while the file is being downloaded.';
export const userEmailAddressDownloadFailText = 'Failed to download email addresses';
export const uppercaseConstraintErrorMessage = 'Password should contain atleast one uppercase letter';
export const lowercaseConstraintErrorMessage = 'Password should contain atleast one lowercase letter';
export const numericConstraintErrorMessage = 'Password should contain atleast one number';
export const homepageMainTitle = 'The all in one app for Farmers and Contractors.';
export const homepageSubtitle = 'Make your business more accountable and traceable. Become more efficient with paperless records of all farm inputs and operations';
export const userProfileAddressUpdateSuccess = 'Address updated successfully';
export const userProfileAddressAddSuccess = 'Address added successfully';
export const userProfileAddressUpdateFailure = 'Failed to update address';
export const userProfileAddressAddFailure = 'Failed to add address';
export const userAccountBusinessDetailsUpdateSuccess = 'Business details update successfully';
export const createPasswordInputLabel = 'Create password *';
export const confirmPasswordInputLabel = 'Confirm Password *';
export const passwordValidationText = 'Password should contain';
export const agreeText = 'I agree to the';
export const serviceTermsText = 'Terms of Service';
export const privacyPolicyText = 'Privacy Policy';
export const alreadySignedUpText = 'Already have an account?';
export const customerAddedSuccessfully = 'Customer added successfully';
export const customerUpdatedSuccessfully = 'Customer updated successfully';
export const customerAddressAddedSuccessfully = 'Customer address update succesfully';
export const customerAddressUpdatedSuccessfully = 'Address updated succesfully';
export const customerAddressDeleted = 'Address Deleted';
export const customerPersonalDetailsSubTitle = 'Manage their personal details';
export const customerSecondaryContactSubTitle = 'Manage there secondary contact information.';
export const customerBusinessInfoSubTitle = 'Manage their company details';
export const customerBusinessAddressSubTitle = 'Manage there business addresses.';
export const customerColorCodeUpdateSuccess = 'Customer color code updated successfully.';
export const userDeletePromtTitle = 'Delete user?';
export const userDeletePromtSubtitle = 'Are you sure you want to delete user ';
export const userDeleteSuccess = 'User deleted successfully!';

//resend-email-verification
export const verifyEmailText = 'Verify your Email';
export const emailCheckText = 'Check your email';
export const confirmAccountText = ' to confirm your account.';

//change/forget/reset password
export const createPasswordTitle = 'Create new Password';
export const forgetPwdTitle = 'Check Your Mail';
export const forgetPwdText1 = 'Click the link sent to';
export const forgetPwdText2 = ' to reset your password.';
export const resetPwdText = 'Enter a new password for ';

//login
export const noAccountText = "Don't have an account?";
export const firstNameField = 'firstName';
export const emailAddressField = 'emailAddress';
export const emailRequiredField = 'email';
export const phoneNumberField = 'phoneNumber';
export const passWordField = 'passWord';
export const confirmPassWordField = 'confirmPassWord';
export const requiredField = 'required';
export const minLengthField = 'minLength';
export const maxLengthField = 'maxLength';
export const uppercaseConstraint = 'uppercaseConstraint';
export const lowercaseConstraint = 'lowercaseConstraint';
export const numericConstraint = 'numericConstraint';
export const sameAsPasswordField = 'sameAsPassword';

//operations 
export const operationsListZeroStateTitle = "You don't have any operations";
export const operationsListZeroStateSubtitle = 'Click on the "Manage operations" button to start creating.';
export const operationDeletePromtTitle = 'Delete sub-operation?';
export const operationDeletePromtSubtitle = 'Are you sure you want to delete this sub-operation?';
export const operationListDisabled = "You're not allowed to view the operations list.";
export const subOperationManageDeletePromtTitle = 'Delete sub-operation?';
export const subOperationManageDeletePromtSubtitle = 'Are you sure you want to delete this sub-operation?';
//operations: manage
export const operationManageTitle = 'Manage operations';
export const operationManageSubtitle = 'Mark active operations';
export const operationManageMainDescription = 'Select the operation(s) to mark it as active.';
export const operationManageAddButtonDescription1 = "You can't find any item which you want?";
export const operationManageAddButtonDescription2 = 'Click on the add button to create a new one.';
export const operationToAddText = 'Operations to be added:';

//operations: add
export const subOperationAddTitle = 'Add sub-operation';
export const subOperationAddSubtitle = 'Sub-operation information';
export const subOperationAddDescription = 'Add details of the sub-operation.';
export const subOperationSafetyChecklistTitle = 'Health safety checklist';
export const subOperationSafetyChecklistDescription = 'Add a pre-start checklist for the sub-operation to ensure health and safety.';
export const subOperationTaxText = 'Taxable';
export const operationTypeAddSuccessMessage = 'Operation types updated successfully!';
export const subOperationDeletedSuccessMessage = 'Sub-operation deleted successfully!';
export const subOperationAddedSuccessMessage = 'Sub-operation added successfully!';
export const subOperationUpdatedSuccessMessage = 'Sub-operation updated successfully!';
export const subOperationAddBtnText = 'Add sub-operation';
export const subOperationChecklistText = 'Operation pre-start checklist is compulsory';
export const subOperationTaxRatesWarning = 'Please set up tax rates in your account to make it a taxable operation';
export const operationsListColumnTitles = { columnName: 'Operation', columnAction: 'Action' };
export const subOperationTaxMissingWarningTitle = 'Unable to make operation taxable';
export const subOperationTaxMissingWarningContent = `You don't have any tax rates set up. Set it up now?`;
export const subOperationTaxMissingWarningContentForManager = `Tax rates are not set up on your business account. Please contact your Admin to set it up.`;

//operations: edit
export const subOperationEditTitle = 'Edit sub-operation';
export const subOperationSaveTitle = 'Save sub-operation';
export const subOperationEditSubtitle = 'Edit sub-operation';
export const subOperationEditDescription = 'Details of the sub-operation.';

//invoices
export const invoiceAddBusinessInfo = 'Business info';
export const invoiceAddJobsSelection = 'Select completed jobs for a customer';
export const invoiceContactNotLinkedWarningText = "You don't have any linked contact for this customer";
export const invoiceContactStatusCheckText = 'Checking contact link status, please wait';
export const invoiceZeroStateTitleText = 'No invoices found!';
export const invoiceZeroStateSubtitleText = 'Click on "Add" button to create a new invoice';
export const invoiceCreateSuccess = 'Invoice created successfully!';
export const invoiceCreateFail = 'Failed to create invoice';
export const invoiceGenerationWarning = 'Please wait while jobs summary report is being generated and added as an attachment.';
export const invoiceDraftUpdatedText = 'Invoice draft updated successfully!';
export const invoiceCreatedAndSent = 'Invoice created and sent successfully!';
export const invoiceCreatedSuccessMessage = 'Invoice created successfully!';
export const invoiceCustomerInvalidEmailWarning = "Please update customer's email address to create invoice.";
export const invoiceApproveNoJobText = "You don't have any approved completed jobs.";
export const invoiceUpdateSuccessfully = 'Invoice updated successfully!';
export const invoiceDeletedSuccessfully = 'Invoice deleted successfully!';
export const invoiceFailedToDelete = 'Failed to delete invoice.';
export const invoiceSentSuccessfully = 'Invoice sent successfully!';
export const invoiceFailedToSend = 'Failed to send invoice';
export const invoiceUnableToDownload = 'Unable to download invoice';
export const invoiceNoTaxRatesWarning = 'Please set up tax on your account!';
export const invoiceTaxTypeNumberText = 'No.';

//invoices: add-business-info
export const invoiceAddBusinessInfoTitle = 'Enter your business details';

//invoices: draft
export const invoiceDraftDueDate = 'Due date & instructions';
export const invoiceDraftDueDateLabel = 'Due Date*';
export const invoiceDraftBilling = 'Billing';
export const invoiceDraftBillingSubtotal = 'Subtotal';
export const invoiceDraftBillingTotalTax = 'Total';
export const invoiceDraftBillingTotalPayable = 'Total payable amount';
export const invoiceDraftAttachments = 'Attachments';
export const invoiceDeleteTitle = 'Delete invoice?';
export const invoiceDeleteSubTitle = 'Are you sure you want to delete this invoice?';

//invoices: jobs-flyout
export const invoiceFlyoutTitle = 'Jobs';

//invoices-external: add
export const invoiceExternalSelectCustomerAndJob = 'Select a customer and then select jobs from the completed jobs.';
export const invoiceExternalGenerateAndAttachJobReports = 'Attach jobs summary';
export const invoiceExternalGeneratingReports = 'Generating reports, please wait';
export const invoiceExternalRegenerateJobReports = 'Re-generate job reports?';
export const invoiceExternalRegenerateJobReportsMessage = 'Are you sure you want to regenerate the job reports? Existing job reports will be replaced.';
export const invoiceExternalNoJobsZeroStateText = 'You don\'t have any approved completed jobs.';
export const invoiceExternalLinkWarningTitle = 'Link contact?';
export const invoiceExternalAlreadyLinkedWarningText1 = 'is already linked to a customer';
export const invoiceExternalAlreadyLinkedWarningText2 = 'Would you like to replace it with';

//invoicse-external: draft
export const invoiceExternalProductSearchTitle = 'Select or search product';
export const invoiceExternalDraftMissingInfo = 'Missing info';
export const invoiceExternalDraftTaxRateNotSet = 'Tax Rate is not set for';
export const invoiceExternalDraftChartAccountNotSet = 'Chart of Account is not set for';
export const invoiceExternalDraftDueDate = 'Due date & instructions';
export const invoiceExternalDraftDueDateLabel = 'Due Date*';
export const invoiceExternalDraftAttachments = 'Attachments';

//invoices-external: contacts
export const invoiceExternalSelectContactLink = 'Select a contact to link';

//invoices-external
export const invoiceExternalQuickbooksTitle = 'Go To QuickBooks';
export const invoiceExternalSageTitle = 'Go To Sage';
export const invoiceExternalXeroTitle = 'Go To Xero';

//integration
export const integrationNotifyStartText = 'To import customers from your integration, go to';
export const integrationNotifyEndText = 'section, and click Import.';
export const integrationConnectionDiscription = 'Connect the accounting software you use, import or link customers and create invoices for one or multiple jobs. You can also update customers while importing as well.';
export const integrationDisconnectWarningTitle = 'Disconnect?';
export const integrationDisconnectWarningDescription1 = 'You will lose all customer invoicing links and will have to re-link them again. Are you sure you want to disconnect';
export const integrationDisconnectWarningDescription2 = 'integration?';
export const integrationDisconnectText = 'Disconnect';
export const integrationQuickBooksText = 'QuickBooks';
export const integrationXeroText = 'Xero';
export const integrationSageText = 'Sage';
export const integrationXeroIntegrating = 'Integrating with Xero, please wait';

// subscriptions
// subscriptions: card
export const subscriptionCardAddTitle = 'Add card';
export const subscriptionCardDeleteTitle = 'Delete card';
export const subscriptionCardDeleteConfirm = 'Are you sure you want to delete the saved card?';
export const subscriptionCardEndingWith = 'Ending with';
export const subscriptionCardEnter = 'Enter card number:';
export const subscriptionCardSave = 'Save';
export const subscriptionCardCancel = 'Cancel';
export const subscriptionCardDefaultSelection = 'Select as default card?';
export const subscriptionCardDefaultSelectionTitle1 = 'Are you sure you want to select your';
export const subscriptionCardDefaultSelectionTitle2 = 'card ending with';
export const subscriptionCardDefaultSelectionTitle3 = 'as your default card?';
export const subscriptionCardDefaultSelectionAgree = 'Yes';
export const subscriptionCardDefaultSelectionDisagree = 'No';
export const subscriptionStartWithoutTrialOverTitle = 'Start subscription?';
export const subscriptionStartWithoutTrialOverDescription1 = "Your free trial will end on ";
export const subscriptionStartWithoutTrialOverDescription2 = " and your card will be charged post trial.";
export const subscriptionStartWithoutTrialOverQuery = "Do you wish to continue?";

// subscriptions: plan
export const subscriptionAvailablePlansTitle = 'Available plans and pricing';
export const subscriptionPlanCancelTitle = 'Cancel subscription?';
export const subscriptionPlanCancelDesc = 'Your active subscription will be cancelled at the end of the billing cycle.';
export const subscriptionPlanFetchInProgress = {
    title: 'Subscription successful!',
    message: 'Please wait a moment while the plan details are being fetched.'
};
export const subscriptionPlanCancelDisagree = 'No';
export const subscriptionPlanCancelAgree = 'Yes';
export const subscriptionPlanDetailsTitle = 'Your plan';
export const subscriptionPlanFreeTrial1 = 'day free trial';
export const subscriptionPlanFreeTrial2 = 'days remaining';
export const subscriptionPlanTeamSize = 'Team size';
export const subscriptionPlanMembers = 'members';
export const subscriptionPlanActivatedTitle = 'Monthly subscription activated';
export const subscriptionPlanDefaultTeamSize = 'members (small)';
export const subscriptionPlanPaymentCardTitle = 'Payment card';

export const subscriptionPlanCancelBtn = 'Cancel subscription';
export const subscriptionPlanPendingAmount = 'Pending amount';
export const subscriptionPlanPayTitle = 'Pay on stripe';
export const subscriptionPlanDownloadInvoice = 'Download latest invoice / receipt';
export const subscriptionPlanDownload = 'Download';
export const subscriptionPlanNoCardWarning = 'Please enter your card details to start your 14 days free trial';
export const subscriptionPlanNoSubscriptionWarning = 'Click on Start trial to get started';
export const subscriptionPlanStartTrialFootnote = 'Your card will be charged after exhaustion of your free usage quota. Cancellation requests will be processed at the end of current billing cycle or free quota.';
export const subscriptionPlanActivateBtn = 'Start trial';
export const subscriptionPlanPromocodeLabel = 'Redeem promotional code';
export const subscriptionPlanPromocodeInputLabel = 'Enter promo code here';
export const subscriptionPromoCodeActivateBtn = 'Apply';
export const subscriptionPromoCodeActivateSuccessText = 'Promo code applied successfully!';
export const subscriptionCurrentPlanTitle = 'Current subscription plan';

//subscriptions: miscellaneous
export const subscriptionCanclePromptText1 = 'You will loose access to Ag-drive account once you cancel your subscription.';
export const subscriptionCanclePromptText2 = 'Subscription can be renewed anytime.';
export const subscriptionCancelReasonLabel = 'Tell us why you are cancelling'; 
export const subscriptionTrialText = "You're currently on 14-day free trial plan. After it ends, monthly billing charges will be applied.";
export const subscriptionTrialExpiredText = 'Your 14-day free trial has expired.';
export const subscriptionDetailsText1 = 'Your plan automatically updates when your team size increases. Admin user charges ';
export const subscriptionDetailsText2 = '/month.';
export const subscriptionPlanCancelDateText = ' Your current subsciption plan is scheduled to be cancelled on ';
export const subscriptionCardAddedText = 'You will be billed every month using the added card.';
export const subscriptionCardEndingText = 'Card ending with';
export const subscriptionInvoiceHistoryHeading = 'Invoice History';
export const subscriptionInvoiceHistorySubHeading = 'Check all your past billing invoices.';
export const subscriptionCancelSuccessMessage = 'Susbscription cancelled successfully. Please wait a moment.';
export const subsciptionWithdrawlMessage = 'Susbscription cancellation withdrawn. Please wait a moment.';
export const archiveOrDeleteText = 'Archive / Delete';
export const roleChangeText = 'Change role';

// onboard
export const onboardUserMissingInfoWarning = 'Please update your account details to continue';

//onoard: business-profile
export const onboardBusinessTitle1 = 'Hi ';
export const onboardBusinessTitle2 = ', tell us about your business';
export const onboardBusinessSubtitle = 'This is an initial information of your business. You can change it later.';

//onboard: preferences
export const onboardPreferenceTitle = 'Setup default business preferences';
export const onboardPreferenceSubtitle = 'You can change these settings later.'; 
export const onboardPreferenceWorkStart = 'Start time *';
export const onboardPreferenceWorkEnd = 'End time *';
export const onboardPreferenceFieldArea = 'Field measurement unit *';
export const onboardPreferenceFieldColor = 'Default Field Color';
export const onboardInvoicingTitle = 'Setup invoicing and taxes';
export const onboardInvoicingSubTitle = 'You can change these settings later.';

//onboard
export const onboardUserPayInfoText = 'User payment info filled.';
export const onboardInvalidCoordinate = 'Invalid coordinates entered!';
export const onboardDefaultInvoiceTitle = 'Default invoice settings';

// zero invitations
export const emptyInvitaionList = 'No pending invitations available!';

// search errors
export const errorForWrongSearch = 'No data available for this request!';

//reset password
export const forgotPassword = 'Forgot password?';
export const resetPassword = 'Reset password';
export const resetPasswordInformation = "Enter the email associated with your account and we'll send you a link to reset your password.";
export const requestSendToEmail = 'A reset password link has been sent to your registered email address. Please check!';
export const passwordResetSuccess = 'Password Reset Successfully';

//search enter
export const enterToSearch = 'Press enter to search';

//search placeholder
export const searchCustomerPlaceholder = 'Search by name, email or phone number';
export const searchFieldPlaceholder = 'Search field';
export const accountMapSearchPlaceholder= 'Type location or enter cordinates (Lat, Long)';
export const fieldUnitPlaceholder= 'Select field area unit';
export const addressLineOnePlaceholder= 'e.g. Town house Agricultural';
export const addressLineTwoPlaceholder= 'e.g. Street number 4';
export const cityPlaceholder= 'e.g. Brentwood';
export const countryPlaceholder= 'e.g. United Kingdom';
export const countyPlaceholder= 'e.g. East Lothian';
export const postalCodePlaceholder= 'e.g. 12KL523';
export const businessTitlePlaceholder= 'e.g. Agricultural pvt ltd';
export const businessPrefixPlaceholder= 'e.g. ABC';
export const vatNumberPlaceholder= 'e.g. GHS43434HDS';
export const registrationNumberPlaceholder= 'e.g. GHS43434HDS';
export const namePlaceholder= 'e.g. Alex';
export const emailPlaceholder= 'e.g. alex@example.com';
export const phoneNumberPlaceholder= 'e.g. 07856633431';
export const contractorSearchPlaceholder= 'Search contractor by name';
export const statusPlaceholder= 'Status';
export const companyNamePlaceholder= 'eg. Zdix Farms Co Ltd.';
export const searchCustomerTextPlaceholder= 'Search customers';
export const fieldNamePlaceholder= 'e.g. Pasture field';
export const selectOwnerPlaceholder= 'Select owner';
export const selectOwnershipPlaceholder= 'Select ownership';
export const cropPlaceholder= 'e.g. Paddy';
export const yearPlaceholder= 'Select year';
export const farmPlaceholder= 'Select farm';
export const notePlaceholder= 'Write something';
export const areaPlaceholder= 'Area';
export const selectCustomerPlaceholder= 'Select customer';
export const selectFieldPlaceholder= 'Select field';
export const selectShapeFilePlaceholder= 'Select shape files (.zip)';
export const unitPlaceholder= 'Select unit';
export const enterPasswordPlaceholder= 'Enter password';
export const confirmPasswordPlaceholder= 'Confirm Password';
export const machineTitlePlaceholder= 'eg. Tractor';
export const machineMakePlaceholder= 'eg. kawasaki';
export const machineModelPlaceholder= 'eg. 2021';
export const machineRegistrationPlaceholder= 'eg. LX54 5345';
export const selectVehiclePlaceholder= 'Search vehicles';
export const selectImplementPlaceholder= 'Search Implements';
export const selectProductPlaceholder= 'Search products';
export const searchOperationPlaceholder= 'Search operations';
export const subOperationPlaceholder= 'e.g. Bailing round';
export const productTitlePlaceholder= 'eg. Fuel';
export const productSearchPlaceholder= 'Search product by name';
export const usersSearchPlaceholder= 'Search name, business name, email';
export const businessesSearchPlaceholder= 'Search name, business name, email';
export const createPasswordPlaceholder= 'Create a strong password';
export const enterPasswordAgainPlaceholder= 'Enter the password again';
export const selectOperatorPlaceholder= 'Select operator';
export const selectOperationPlaceholder= 'Select operation';
export const searchTextPlaceholder= 'Search';
export const enterAmountPlaceholder= 'Enter amount';
export const selectFarmsAddressPlaceholder= 'Select farm address';
export const teamMemberSearchPlaceholder= 'Search team member by name';
export const teamMemberSelectPlaceholder= 'Select team member';
export const zeroQuantityPlaceholder = '0';

//checklists
export const checkListItemLabel = 'Checklist item';
export const checkListItemEnterPromtLabel = 'Enter checklist item';
export const healthSafetyChecklistMandatoryText = 'Health & safety checklist is compulsory';
export const vehicleSafetyChecklistMandatoryText = 'Vehicle pre-start checklist is compulsory';
export const implementSafetyChecklistMandatoryText = 'Implement pre-start checklist is compulsory';

//flyout
export const addBtnText = 'Add';
export const updateBtnText = 'Save changes';

//others
export const deletePromtAgree = 'Yes';
export const deletePromtDisagree = 'No';
export const addItemBtnText = 'Add';
export const listLoadMoreText = 'See more';
export const downloadText = 'Download';
export const refreshText = 'Refresh';
export const addNewText = 'Add';
export const addNewContractor = 'Add contractor';
export const addNewField = 'Add field';
export const updateField = 'Save';
export const archivePromptAgree = 'Yes';
export const archivePromptDisagree = 'No';
export const restorePromptAgree = 'Yes';
export const restorePromptDisagree = 'No';
export const jobPrefixText = shortTitleWarning;
export const personalDetailsTitle1 = 'Personal Details';
export const personalDetailsTitle2 = 'Manage your personal details';
export const makeContractorText = 'Make contractor';

//account
export const accountTitle = 'Account';

//super-admin
export const superAdminAccessCodePrompt = 'Please enter the access code received on your email';
export const cancellationScheduledOnText = 'Cancellation scheduled on ';
export const cancelledOnText = 'Cancelled on ';
export const businessesDeleteTitle = 'Delete business?';
export const businessesDeleteSubtitle = 'Are you sure you want to delete this business?';
export const businessesRoleChangeTitle = 'Change role to contractor?';
export const businessesRoleChangeSubtitle = 'Are you sure you want to change role to contractor?';
export const businessDeleteSuccess = 'Business deleted successfully!';
export const businessRoleChangeSuccess = 'Role changed to contractor successfully!';

//account:profile
export const accountDetailsNameLabel = 'Name';
export const accountDetailsEmailLabel = 'Email Address';
export const accountDetailsPhoneLabel = 'Phone Number';
export const accountDetailsSecondaryNameLabel = 'Secondary Name';
export const accountDetailsSecondaryContactLabel = 'Secondary Contact';
export const accountDetailsContactInfoHeading1 = 'Contact Information';
export const accountDetailsContactInfoHeading2 = "Mange your company's phone number and address.";
export const accountDetailsPersonalPreferencesHeading1 = 'Personal Preferences';
export const accountDetailsPersonalPreferencesHeading2 = 'These preferences will be applied to your account.';

//account:business
export const accountCompanyDetailsHeading1 = 'Company Details';
export const accountCompanyDetailsHeading2 = 'Manage your company details';
export const accountCompanyAddressesHeading2 = 'Manage your company address';
export const accountCompanyAddressesHeading1 = 'Company address';
export const accountCompanyGeneralAndDefaultHeading1 = 'General default settings';
export const accountCompanyLocationHeading2 = 'This location will be set as default to help you map your fields.';
export const accountCompanyPaymentHeading1 = 'Invoice and General Settings';
export const accountCompanyPaymentHeading2 = 'Manage your invoicing and taxes';
export const accountCompanyTeamHeading1 = 'Team Settings';
export const accountCompanyTeamHeading2 = 'These settings will be applied to your team.';
export const accountGeneralSettingsHeading = 'General Settings';
export const accountGeneralSettingsSubHeading = 'Manage your default currency';

//account:Farms
export const accountCompanyFarmsHeading1 = 'Your Farms';
export const accountCompanyFarmsHeading2 = 'Add your farms here. You can map your fields for each farm in the fields section.';
export const accountInvalidCoordinates = 'Invalid coordinates entered!';
export const createNewTaxText = 'Press enter to create';
export const taxUsedOnText = 'This will be used only for in-built invoicing.';

//fields Label String/Constant.
export const fieldLabelName = 'Name *';
export const fieldLabelBillingCost = 'Billing Rate *';
export const fieldLabelUnit = 'Unit *';
export const fieldLabelVehicles = 'Vehicles';
export const fieldLabelImplements = 'Implements';
export const fieldLabelProducts = 'Products';
export const fieldLabelDescription = 'Description';
export const buttonNameSaveChanges = 'Save changes';

//customers: strings
export const secondaryContactInfoHeading1 = 'Secondary Contact';
export const secondaryContactInfoHeading2 = 'Manage your secondary contact information.';
export const companyDetailsHeading1 = 'Business Info';
export const companyDetailsHeading2 = 'Manage company details';
export const companyAddressesHeading1 = 'Business Addresses';
export const companyAddressesHeading2 = 'Manage your business addresses.';
export const farmsHeading1 = 'Farms';
export const farmsExternalContactHeading = 'View all farm addresses.';
export const farmsHeading2 = 'Add customer farms here, you can map customer fields with these farms to manage here.';
export const fieldDetailsHeading1 = 'Fields(';
export const fieldDetailsHeading2 = 'Manage fields details';
export const editContactManatoryFieldsText = 'Mandatory fields are marked with *';

//menu list-item text
export const listItemView = 'View';
export const listItemEdit = 'Edit';
export const listItemDelete = 'Delete';

//jobs
export const teamJobZeroStateDescription = 'You do not have any jobs right now. All your assigned jobs will appear here. You can click on the "Add" button to create a self job.';
export const teamJobZeroStateTitle = 'No jobs';
export const teamJobLoaderDialogTitle = 'Getting jobs ready to export';
export const teamJobLoaderDialogContent = 'Please wait, while the jobs are being loaded:';
export const teamJobDeletePromtTitle = 'Delete job?';
export const teamJobDeletePromtSubtitle = 'Are you sure you want to delete this job?';
export const teamJobForceFinishPromtTitle = 'Are you sure you want to force finish this job?';
export const teamJobForceFinishPromtSubtitle = 'Please note that this will finish or skip work for all the assigned operators.';
export const teamJobCancelPromtTitle = 'Cancel Job?';
export const teamJobDeletePromptTitle = 'Delete job?';
export const teamJobCancelPromtSubtitle = 'Are you sure you want to cancel job?';
export const teamJobDeletePromptSubtitle = 'Are you sure you want to delete this job?';
export const teamJobCancelReasonText = 'Please submit the reason to cancel job';
export const teamJobDeclinePromtTitle = 'Decline job?';
export const teamJobDeclineReasonText='Please submit the reason to decline job';
export const teamJobLocationTypeTitle='Select location type';
export const teamJobLocationInstructionTitle='Instructions for this location';
export const teamJobGeneralInformationTitle='General Information';
export const teamJobGeneralInformationSubTitle='Fill in mandatory information to create the job';
export const teamJobScheduleDateTitle='Scheduled date & time';
export const teamJobLocationTitle='Locations';
export const teamJobLocationSubTitle='Direct operators with instructions, via google maps, to an address or pin drop';
export const teamJobLocationAlreadyAdded='Address already added!';
export const teamJobLocationInvalid='Invalid address!';
export const teamJobLocationInvalidCoordinates='Invalid coordinates selected!';
export const teamJobLocationAddText='+ Add location';
export const teamJobLocationDropPinText='Dropped pin at';
export const teamJobBillingTitle='Billing information';
export const teamJobBillingSubTitle='Provide billing quantity for the operation performed on the job';
export const teamJobBillingInformation='Billing quantity will be auto-calculated based on changes to work hours, break time or down time';
export const teamJobTimeRecordsTravelTime='Travel time will not be auto-calculated for team jobs where multiple operators work together';
export const teamJobTimeRecordsBreakTime='Break time will not be auto-calculated for team jobs where multiple operators work together';
export const teamJobNoteAndPhotosTitle='Notes & photos';
export const teamJobNoteAndPhotosSubTitle='Provide any notes and photos that are relevant to the job';
export const teamJobFieldsTitle='Fields';
export const teamJobFieldsSubTitle='Select fields from the map or drop-down list';
export const teamJobFieldsLoadingText='Please wait, loading fields:';
export const teamJobFieldsTotalArea='Total area:';
export const teamJobInstructionsTitle='Instructions';
export const teamJobInstructionsSubTitle='Provide instructions and photos for the operators on the job';
export const teamJobExtraInformationTitle='Extra information';
export const teamJobExtraInformationSubTitle='Add any extra information required for the job';
export const teamJobExtraInformationWarning='Load already added! Please update the quantity instead.';
export const teamJobAddExtraInformationText='+ Add extra info.';
export const teamJobOperatorsTitle='Operators';
export const teamJobOperatorsSubTitle='Select multiple operators and team leads for the job. Leave blank to keep it unassigned';
export const teamJobAddOperatorText='+ Add operator';
export const teamJobAddAnotherText='+ Add another';
export const teamJobProductsTitle='Products';
export const teamJobProductsSubTitle='Select products to be added on the job';
export const teamJobAddProductsText='+ Add product';
export const teamJobTimeRecordsTitle='Time Records';
export const teamJobTimeRecordsSubTitle='Time records are automatically added whenever a job is part completed and resumed';
export const teamJobDeclinedSuccessfully='Job declined succesfully';
export const teamJobCancelledSuccessfully='Job cancelled succesfully';
export const teamJobDetailsUpdatedWarning = 'It seems like this job has been updated by someone else. Job will have to be reloaded.';
export const teamJobOperationNotFoundWarning = 'The selected operation has been deleted!';
export const teamJobTotalBillableTime = 'Total billable time';
export const teamJobZeroProductsText = 'No products were selected.';
export const teamJobZeroMetaDataText = 'No extra information was added.';
export const teamJobZeroFieldText = 'No fields were added.';
export const teamJobZeroAddressText = 'No addresses were added.';
export const teamJobCancelBtnText = 'Cancel job';
export const teamJobDeleteBtnText = 'Delete job';
export const teamJobEnterAddressText = 'Enter new address';
export const teamJobDropPinText = 'Drop pin';
export const jobsReportComponentTitle = 'Job Report';
export const jobReportGenerationMessage = 'Report generated successfully.';
export const jobDuplicationInProgress = 'Please wait a moment, while we duplicate data';

export const teamJobsFilterCustomersPaginationModule = 'teamJobsFilterCustomers';
export const teamJobsFilterTeamMembersPaginationModule = 'teamJobsFilterTeamMembers';
export const teamJobsFilterFieldsPaginationModule = 'teamJobsFilterFields';
export const teamJobsFilterVehiclesPaginationModule = 'teamJobsFilterVehicles';
export const teamJobsFilterImplementsPaginationModule = 'teamJobsFilterImplements';
export const teamJobsFilterProductsPaginationModule = 'teamJobsFilterProducts';
export const fieldsFilterCustomersPaginationModule = 'fieldsFilterCustomers';
export const fieldsMappingFilterCustomersPaginationModule = 'fieldsMappingFilterCustomers';
export const fieldsAddFilterCustomersPaginationModule = 'fieldsAddFilterCustomers';
export const fieldsEditFilterCustomersPaginationModule = 'fieldsEditFilterCustomers';
export const fieldsBulkUploadFilterCustomersPaginationModule = 'fieldsBulkUploadFilterCustomers';
export const subOperationVehiclesDropdownModule = 'vehiclesDropdown';
export const subOperationImplementsDropdownModule = 'implementsDropdown';
export const subOperationProductsDropdownModule = 'productsDropdown';
export const invoicesListFilterCustomersPaginationModule = 'invoicesFilterCustomers';
export const invoicesProductListFilter = 'invoicesProductListFilter';

//excel import
export const uploadNotesDeleteColumnWarning = 'Do not delete any column from the sample file. If you do not wish to provide information in an optional column, leave the data blank.';
export const uploadNotesCommaWarning = 'Avoid using comma in any of the row data. This might corrupt the file.';

//bulk action response title
export const bulkActionResponseTitleCustomersImport = 'Customers import result';
export const bulkActionResponseTitleCustomersUpdate = 'Customers update result';
export const bulkActionResponseTitleFiledsImport = 'Fields import result';

//address
export const addresses = 'Addresses';
export const addressesLine1 = 'Address line 1';
export const addressesLine2 = 'Address line 2';
export const city = 'City';
export const townAndCity = 'Town/City';
export const country = 'Country';
export const stateAndCounty = 'State/County';
export const postalCode = 'Postal code';

//button text
export const cancel = 'Cancel';
export const doneText = 'Done';
export const update = 'Update';
export const back = 'Back';
export const remove = 'Remove';
export const restore = 'Restore';
export const viewDetailsText = 'View details';
export const acceptText = 'Accept';
export const noThanksText = 'No thanks';
export const sendInvitationText = 'Send invitation';
export const manageText = 'Manage';

//input titles
export const title = 'Title';
export const name = 'Name';
export const value = 'Value';
export const unit = 'Unit';
export const usage = 'Usage';
export const loadName = 'Load name';
export const operation = 'Operation';
export const teamLead = 'Team Lead';
export const vehicle = 'Vehicle';
export const implement = 'Implement';
export const product = 'Product';
export const checkList = 'Checklist';
export const timeDuration = 'Time duration';
export const contractor = 'Contractor';
export const customer = 'Customer';
export const startDateAndTime = 'Start date & time';
export const endDateAndTime = 'End date & time';
export const travelTime = 'Travel time';
export const breakTime = 'Break time';
export const downTime = 'Down time';
export const workTime = 'Timings';
export const workHours = 'Work time';
export const totalTime = 'Total time';
export const startTime = 'Start time';
export const endTime = 'End time';
export const latitude = 'Latitude';
export const longitude = 'Longitude';
export const jobNo = 'Job No';
export const jobsText = 'Jobs';
export const scheduledFor = 'Scheduled For';
export const fields = 'Fields';
export const operators = 'Operators';
export const billableQuantity = 'Billable quantity';
export const status = 'Status';
export const emailStatus = 'Email status'
export const invoice = 'Invoice';
export const fieldAreaUnit = 'Fields area default unit';
export const breakTimeMinutes = 'Break time (minutes)';
export const profile = 'Profile';
export const business = 'Business';
export const farms = 'Farms';
export const defaultFieldColor = 'Field default color';
export const companyName = 'Company name';
export const companyAddress = 'Company address';
export const companyJobCode = 'Company job code';
export const vatNumber = 'VAT number';
export const companyRegistrationNumber = 'Company registration number';
export const editFarm = 'Edit Farm';
export const addFarm = 'Add Farm';
export const paymentInstruction = 'Payment instructions';
export const phoneNumber = 'Phone number';
export const secondaryContact = 'Secondary contact';
export const emailAddress = 'Email address';
export const personalAddress = 'Personal address';
export const personalInfo = 'Personal Info';
export const seeOnMap = 'See on map';
export const seeAll = 'See All';
export const businessName = 'Business name';
export const contact = 'Contact';
export const address = 'Address';
export const inviteeName = 'Invitee Name';
export const inviteeEmail = 'Invitee Email';
export const inviteeOn = 'Invited On';
export const expiredOn = 'Expired On';
export const action = 'Action';
export const moreRequest = 'more requests';
export const selectAll = 'Select all';
export const customerName = 'Customer name';
export const emailText = 'Email';
export const phoneText = 'Phone';
export const ownerText = 'Owner';
export const ownershipText = 'Ownership';
export const noteText = 'Note';
export const hideDetailsText = 'Hide details';
export const yearText = 'Year';
export const cropText = 'Crop';
export const areaText = 'Area';
export const jobHistory = 'Job history';
export const farmText = 'Farm';
export const addContact = 'Add contact';
export const invoicingText = 'Invoicing';
export const completedOn = 'Completed on';
export const completedBy = 'Completed by';
export const businessProfileText = 'Business Profile';
export const businessDetailsText = 'General details';
export const previewInvoiceText = 'Preview invoice';
export const totalAmountText = 'Total Amount';
export const taxAmountText = 'Amount';
export const amountText = 'Amount';
export const quantityText = 'Quantity';
export const rateText = 'Rate';
export const particularsText = 'Particulars';
export const numberText = 'Number';
export const itemsText = 'Items';
export const costText = 'Cost';
export const dueDateText = 'Due Date';
export const dateText = 'Date';
export const internalText = 'Internal';
export const invoiceDetailsText = 'Invoice details';
export const invoiceNumberText = 'Invoice number';
export const productAndServicesText = 'Product & Services';
export const descriptionText = 'Description';
export const unitPriceText = 'Unit Price';
export const taxText = 'tax';
export const accountText = 'Account';
export const logInText = 'Log in';
export const passWordText = 'Password';
export const registrationText = 'Registration';
export const brandText = 'Brand';
export const modelText = 'Model';
export const logoutText = 'Logout';
export const loadInformaitionText = 'EXTRA INFORMATION';
export const chargeableUnitText = 'Chargeable unit';
export const pricePerUnitText = 'Price per unit';
export const accountSignUpText = 'account sign up';
export const myCardText = 'My cards';
export const setAsDefaultText = 'Set as default';
export const cardText = 'Card';
export const billText = 'Bill';
export const draftText = 'Draft';
export const openText = 'Open';
export const paidText = 'Paid';
export const applyPromoCodeText = 'Apply promo code';
export const amountDueText = 'Amount due';
export const manageCradText = 'Manage cards';
export const paymentInformationText = 'Payment Information';
export const promoCodeApplyText = 'Promo code applied';
export const totalCostText = 'Total cost';
export const nextPaymentOnText = 'Your card will be charged on';
export const trialingEndsOnText = 'Your card will start charging after ';
export const EndedOnText = 'Ended on';
export const EndsOnText = 'Ends on';
export const monthlyBillingText = 'Monthly billing';
export const freeTrialText = 'Free trial';
export const roleText = 'Role';
export const teamMemberText = 'Team member';
export const timeSheetTypeText = 'Timesheet type';
export const timeDetailsText = 'Time Details';
export const memberNameText = 'Member name';
export const memberText = 'Member';
export const totalTimesheetsText = 'Total timesheets';
export const totalHoursText = 'Total hours';
export const workingHoursText = 'Working hours';
export const breakTimeHoursText = 'Breaktime hours';
export const travelTimeHoursText = 'Travel time hours';
export const holidayText = 'All day (Holiday)';
export const sickText = 'All day (Sick)';
export const showJobsText = 'Show Jobs';
export const invitedByText = 'You have been invited by';
export const invitationExpiresOnText = 'Invitation expires on';
export const invitationAlreadyExpiredText = 'Your invitation has been expired.';
export const invalidInvitationText = 'Invalid invitation';
export const goToJobsText = 'Go to jobs';
export const defaultColorText = 'Default color';
export const deletingText = 'Deleting';
export const permanentlyRemoveText = 'will permanently remove them.';
export const confirmationToDeleteText = 'Are you sure you want to delete?';
export const archiveText = 'Archive';
export const archivingText = 'Archiving';
export const archivingTextTeamMembers = "Team members with active jobs won't be archived.";
export const willRemoveThemText = 'will remove them.';
export const confirmationToArchiveText = 'Are you sure you want to archive?';
export const restoreText = 'Restore';
export const willRestoredText = 'will be restored.';
export const confirmationToRestoreText = 'Are you sure you want to restore?';
export const importText = 'Import';
export const downloadSampleCsvText = 'Download a sample CSV';
export const csvRequiredFormatText = 'file to use it to fill data in the required format.';
export const csvRequiredFieldText = 'The following fields are required:';
export const successfullyImportedText = 'imported successfully.';
export const errorsImportingStartingText = 'There were errors importing';
export const errorsImportingEndingText = 'from your CSV file.';
export const fixErrorAndUploadAgainText = 'file to fix errors, and upload again.';
export const rowNoText = 'Row No.';
export const errorText = 'Error';
export const addOrDropFileText = 'Add or drop file here (CSV format)';
export const disconnectOtherServicesStartingText = 'Disconnect other services to';
export const disconnectOtherServicesEndingText = 'connect with this service.';
export const connectedOnText = 'Connected on';
export const itemsSelectedOnPageText = 'Items selected on this page.';
export const allText = 'All';
export const itemsAreSelectedText = 'Items are selected.';
export const clearAllText = 'Clear all';
export const noSearchResultFoundText = 'No search results found';
export const notArchivedText = 'Not archived';
export const itemDeletedSuccessfully = 'Items deleted successfully';
export const itemArchivedSuccessfully = 'Items archived successfully';
export const someErrorOccuredText = 'Some error occured';
export const failedToArchivedItems = 'Failed to archive items';
export const itemRestoreSuccessfully = 'Items restored successfully';
export const failedToRestoreItems = 'Failed to restore items';
export const noConnectionReceived = 'No connection requests received';
export const noConnectionSent = 'No connection requests sent';
export const connectionRequestTitle = 'Connection requests';
export const okayText = 'Okay';
export const informationText = 'Information';
export const showMiniLoadMoreButton = false;
export const currencyPlaceholderText = "Select currency";
export const timeZonePlaceholderText = "Select time zone";
export const currencyTitle = "Currency";
export const timeZoneTitle = "Time zone";
export const taxesText = "Taxes";
export const taxeTypeText = "Tax name";
export const RegNumText = "number";
export const RegNumTextCaps = "Tax number";
export const taxRateTitle = "TAX RATES";
export const taxDetailsTitle = "TAX Details";
export const rateNameTitle = "Rate name";
export const ratePercentageTitle = "Rate percentage (%)";
export const rateNamePlaceholder = "eg. Standard";
export const ratePercentagePlaceholder = "eg. 3.5";
export const defaultSettingsTitle = "Default settings";
export const defaultLocationTitle = "Default location";
export const teamWorkTimingTitle = "TEAM WORK TIMINGS";
export const percentageSymbol = '%';
export const invoiceTermsHeading = 'Invoice terms (in days)';
export const createNewTaxPlaceholder = 'Select or create new tax';
export const selectDatePlaceholder = 'Select date';
export const declinedText = 'Declined';
export const approveInvoiceText = 'Approved for invoicing';
export const invoiceText = 'Invoiced';
export const approvedText = 'Approved';
export const addNewProductText = 'Add new product';
export const productInListText = 'This product is already added *';

export const editJob = 'Edit job';
export const deleteJob = 'Delete job';
export const cancelJob = 'Cancel job';
export const searchJobs = 'Search jobs';
export const searchJobsLabel = 'Search job by ID, operation or customer';
export const scheduleJobs = 'Schedule';
export const archiveJobs = 'Archive';
export const fileSizeWarning = "File size exceeds 10 MB";
export const scheduleDateAndTime = 'Schedule date & time';

export const addJobMakeTeamLeadLabel = "Make team lead";
export const jobUpdateSuccess = "Job updated successfully";
export const submitUnscheduledJobs = "Save";
export const unscheduledJobsListModule = "unscheduledJobsList";
export const menuViewJob = 'View';
export const menuEditJob = 'Edit';
export const Duplicate = 'Duplicate';
export const menuCancelJob = 'Cancel';
