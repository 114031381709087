import { apiUsers, apiFarmers, apiAddresses, apiFields, apiJobs, apiColourCode } from '@/utils/endpoints';
import { notify, getCommaSeparatedQuery } from '@/utils/helpers';
import requests from '@/utils/requests';
import buildUrl from 'build-url';
import loadGoogleMapsApi from 'load-google-maps-api';
import $ from 'jquery';
import StringConstants from '@/plugins/stringConstants';
import ConstantValues from '@/plugins/constantValues';
import { FeatureLayerHelper } from '@/utils/helpers/index';

export const actions = {

    async addNewContact({ state, rootState, commit }, data) {
        state.contactLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + apiFarmers
        });
        const result = await requests.postData(url, data);

        if (result[0] != null) {
            state.contactLoader = false;
            notify(StringConstants.customerAddedSuccessfully, 'success');
            commit('clearFarmersList');
            commit('resetPaging');
            return true;
        }
        state.contactLoader = false;
    },

    async getContactDetailsByAdmin({ state, rootState, commit }, id) {
        state.contactDetailsLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + id
        });
        const result = await requests.getData(url);
        if (result != null) {
            if (result.data != null) {
                commit('setContactProfile', result.data);
                state.contactDetailsLoader = false;
                return result.data
            }
        } else {
            state.contactDetailsLoader = false;
        }
    },

    async updateContactProfile({ state, rootState, commit }, data) {
        state.contactLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + data[1].owner + apiFarmers + '/' + data[1].user
        });
        const result = await requests.putData(url, data[0]);
        if (result[0] != null) {
            state.contactLoader = false;
            notify(StringConstants.customerUpdatedSuccessfully, 'success');
            commit('updateProfileInFarmersList', result[0]);
            return true;
        }
        state.contactLoader = false;
    },

    async getContactFarmsDetailsByAdmin({ rootState, commit }, id) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + apiFarmers + '/' + id + apiAddresses
        });
        const result = await requests.getData(url);
        if (result != null) {
            if (result.data != null) {
                commit('setContactFarmsList', result.data.value);
            }
        }
    },

    async addContactAddresses({ rootState }, data) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + apiFarmers + '/' + data[1] + apiAddresses
        });
        const result = await requests.postData(url, data[0]);

        if (result[0] != null) {
            notify(StringConstants.customerAddressAddedSuccessfully, 'success');
            return result[0];
        }
    },

    async updateContactAddresses({ rootState }, data) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + apiFarmers + '/' + data[1] + apiAddresses + '/' + data[0].id
        });
        const result = await requests.putData(url, data[0]);
        if (result[0] != null) {
            notify(StringConstants.customerAddressUpdatedSuccessfully, 'success');
        }
    },

    async deleteContactAddresses({ rootState }, data) {
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + apiFarmers + '/' + data[0] + apiAddresses + '/' + data[1]
        });
        const result = await requests.deleteData(url);

        if (result.status == 200) {
            notify(StringConstants.customerAddressDeleted, 'success');
            return true;
        }
    },

    async getContactFieldsDetailsByAdmin({ rootState, commit }, id) {
        let path = null;
        path = id + apiFields;
        const url = buildUrl(rootState.baseUrl, {
            path: path
        });
        const result: any = await requests.getData(url, false, true);
        if (result?.data != null) {
            if (result.data != null) {
                commit('setContactFieldList', result.data.value);
            }
        }
    },

    async getAllContactFieldOnMap({ state, dispatch }, data) {
        state.map = null;
        $("#contactFieldsOnMap").html('');
        loadGoogleMapsApi({
            key: ConstantValues.gMapsApiKey,
            libraries: ['places', 'drawing', 'geometry']
        }).then(() => {
            const mapZoom = 12;
            const { google } = window;
            const mapOptions = {
                zoom: mapZoom,
                mapTypeId: google.maps.MapTypeId.HYBRID,
                center: { lat: -34.397, lng: 150.644 },
                mapTypeControl: true,
                streetViewControl: false,
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.BOTTOM_LEFT
                }
            };
            state.map = new google.maps.Map(document.getElementById('contactFieldsOnMap'), mapOptions);
            dispatch('drawPolygonOnContactFieldMap', data);
            dispatch('setMapBoundContactFieldMap', data);
        });
    },

    async drawPolygonOnContactFieldMap({ state, dispatch }, data) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].polygon) {
                FeatureLayerHelper.addGeoJsonFeatureOnMap(data[i].polygon, state.map.data);
            }
        }
    },
    
    async setMapBoundContactFieldMap({ state }) {
        const bounds = FeatureLayerHelper.getBoundsOfFeatures(state.map.data)
        state.map.fitBounds(bounds);
    },

    async getContactOnGoingJobsByAdmin({ state, rootState, commit }, id) {
        state.invoiceJobsPreviewLoader = true;
        const url = buildUrl(rootState.baseUrl, {
            path: apiJobs + '?CustomerIds=' + id + '&' + getCommaSeparatedQuery('2,3,4', "Statuses")
        });
        const result = await requests.getData(url);
        if (result != null && 'data' in result) {
            if (result.data != null) {
                commit('setContactOnGoingJobsList', result.data.value);
            }
        }
    },

    async updateExternalCustomerColor({ rootState }, data){
        const url = buildUrl(rootState.baseUrl, {
            path: apiUsers + '/' + localStorage.getItem(ConstantValues.ownerId) + apiFarmers + '/' + data[0] + apiColourCode
        });
        const result = await requests.putData(url, data[1]);
        if (result[0] != null) {
            notify(StringConstants.customerColorCodeUpdateSuccess, 'success');
        }
    }
}