<template>
    <div>
        <div class="border-radius-3 bg-white ">
            <div class="pL-6 pR-24 mT-none mB-none md-size-100 field-search-container"
                 style="transform: translateX(-2px) translateY(-1px);">
                    <v-icon
                        color="black"
                        class="pt-1 pr-2"
                        style="padding-top: 6px !important;"
                    >
                        mdi-magnify
                    </v-icon>
                <input type="text" 
                        v-model="fieldSearchTextReadOnly" 
                        :placeholder="$stringConstants('searchFieldPlaceholder')"
                        class="input-search fm-light fc-grey fs-16" 
                        id="searchFields" 
                        autocomplete="off"
                        v-on:keyup="searchFieldNames" 
                        v-on:keyup.enter="searchFields" />
            </div>
        </div>
        <div v-if="fieldSearchResultsReadOnly && fieldSearchResultsReadOnly.length > 0" class="hs-drp">
            <div v-for="(result, rindex) in fieldSearchResultsReadOnly.slice(0,4)" :key="rindex" class="hs-drp-item">
                <div @click="navigateToField(result.field.id)">
                    {{result.title}}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import FieldsComputedMixin from '@/components/fields/fields-computed';
    import { mapActions } from 'vuex';

    export default {
        mixins: [FieldsComputedMixin],
        methods: {
            ...mapActions({
                locateFieldAsync: 'locateFieldReadOnly'
            }),
            searchFieldNames() {
                this.fieldSearchResultsReadOnly = []
                if (this.fieldSearchTextReadOnly == null || (this.fieldSearchTextReadOnly != null && this.fieldSearchTextReadOnly.length == 0)) {
                    this.fieldSearchResultsReadOnly = []
                } else {
                    if (this.fieldsListForSearchReadOnly && this.fieldsListForSearchReadOnly.length > 0) {
                        this.fieldsListForSearchReadOnly.map((field) => {
                            if (field.title && field.title.toLowerCase().includes(this.fieldSearchTextReadOnly.toLowerCase())) {
                                this.fieldSearchResultsReadOnly.push(field)
                            }
                        })
                    }
                }
            },
            searchFields() {
                if (this.fieldSearchTextReadOnly) {
                    this.locateFieldAsync()
                }
            },
            navigateToField(fieldId) {
                if (fieldId) {
                    this.$store.dispatch('navigateToField', fieldId)
                }
            }
        }
    }
</script>